import React, {useEffect, useRef, useState} from 'react';
import {Modal, Button} from "react-bootstrap";
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
    Input,
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import Button2 from "@material-ui/core/Button";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useCreateCoursesMutation, useGetCategoryQuery} from "redux/api/Courses/coursesApi";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "_metronic/_helpers";
import ReactSelect from "../../UI/ReactMultiSelect";
import Preview from "../components/Preview/Preview";
import ReactQuill from "../../../components/ReactQuill";

const initialValues = {
    title: "",
    slug: "",
    shortDescription: "",
    description: "",
    categoryId: "",
    price: "",
    discount: "",
    coverFile: "",
    itemSpecifications: [],
    expireYear: "",
    expireMonth: "6",
    expireDay: "",
    isFree: false,
}

const validationSchema = Yup.object({
    title: Yup.string().required("Please enter a title."),
    slug: Yup.string().required("Please enter a slug."),
    shortDescription: Yup.string().required("Please enter a short description."),
    description: Yup.string().required("Please enter a description."),
    categoryId: Yup.string().required("Please enter a category."),
    price: Yup.string().required(),
    coverFile: Yup.string().required("Please enter a cover file"),
    expireYear: Yup.number().min(0).max(255).nullable(true),
    expireMonth: Yup.number().min(0).max(255).nullable(true),
    expireDay: Yup.number().min(0).max(255).nullable(true),
})

export default function Create({show, onHide, specifications, refetch}) {
    const courseRef = useRef();
    const [createCourses, {isLoading, error, isSuccess}] = useCreateCoursesMutation();
    const [imageSrc, setImageSrc] = useState("");
    const [file, setFile] = useState("");
    const [blob, setBlob] = useState();
    const [specificationsData, setSpecificationsData] = useState([]);
    const {data} = useGetCategoryQuery();

    useEffect(() => {

        if (specifications) {
            let specificationsOptions = specifications.map(item => ({
                value: item.id,
                label: item.name
            }));
            setSpecificationsData(specificationsOptions)
        }
    }, [specifications]);

    useEffect(() => {
        if (isSuccess) {
            refetch()
        }
    }, [isLoading])

    const handleSubmit = (values, resetForm) => {
        const asset = new FormData();
        const {
            title, slug, shortDescription,
            description,
            categoryId,
            price,
            discount,
            expireYear,
            expireMonth,
            expireDay,
            applyExpireToAll,
            isFree
        } = values;

        asset.append("title", title);
        asset.append("slug", slug);
        asset.append("shortDescription", shortDescription);
        asset.append("description", description);
        asset.append("categoryId", categoryId);
        asset.append("price", price);
        !isFree && asset.append("discount", discount);
        asset.append("isFree", isFree);
        expireYear && !isFree && asset.append("expireYear", expireYear);
        expireMonth && !isFree && asset.append("expireMonth", expireMonth);
        expireDay && !isFree && asset.append("expireDay", expireDay);
        // asset.append("applyExpireToAll", applyExpireToAll);

        blob && asset.append("coverFile", blob);

        formik.values.itemSpecifications.forEach((spec, index) => asset.append(`itemSpecifications[${index}].specificationId`, spec.specificationId))
        createCourses(asset);
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, {resetForm, setErrors}) => {
            if(!values.isFree && values.price <= 0 && !(values.expireDay || values.expireYear || values.expireMonth)) {
                setErrors({
                    price: 'Price has to be greater than 0',
                    expireDay: "At least one of date field has to be filled",
                    expireMonth: "At least one of date field has to be filled",
                    expireYear: "At least one of date field has to be filled",
                })
                return
            }
            handleSubmit(values, resetForm);
        }
    });

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    const handleCoverImage = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setBlob(file)
        setFile(e.target.value);

        reader.onloadend = () => {
            setImageSrc(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const changeSpecifications = (e) => {
        let tempSpecifications = e.map(item => ({specificationId: item.value}))
        formik.setFieldValue("itemSpecifications", tempSpecifications);
    };

    const handleChangeDate = (e) => {
        e.preventDefault();
        const field = e.target.name
        const {value} = e.target;
        const regex = /^[0-9]*$/;
        if (regex.test(value.toString())) {
            formik.setFieldValue(field, value)
        }

    }

    useEffect(() => {
        if (imageSrc) {
            formik.setFieldValue("coverFile", imageSrc)
        }
    }, [imageSrc])

    useEffect(() => {
        if (isSuccess) {
            onHide(false);
        }
    }, [isSuccess])

    useEffect(() => {
        if (formik.values.price === '0') {
            formik.setFieldValue("discount", '0');
        }
    }, [formik.values.price])

    useEffect(() => {
        if(formik.values.isFree) {
            formik.setFieldValue("expireYear", "");
            formik.setFieldValue("expireMonth", "");
            formik.setFieldValue("expireDay", "");
            formik.setFieldValue("price", "0");
            formik.setFieldValue("discount", "");
        }
    }, [formik.values.isFree])

    return (
        <Modal size="lg" show={show} onHide={onHide}>
            <form onSubmit={formik.handleSubmit} ref={courseRef}>
                <Modal.Header closeButton>
                    <Modal.Title id="create">Create a course</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="title"
                                        name="title"
                                        label="Title"
                                        placeholder="Title"
                                        className="w-100"
                                        error={getInputClasses("title")}
                                        {...formik.getFieldProps("title")}
                                    />
                                    {formik.touched.title && formik.errors.title ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.title}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="slug"
                                        name="slug"
                                        label="Slug"
                                        placeholder="Slug"
                                        className="w-100"
                                        error={getInputClasses("slug")}
                                        {...formik.getFieldProps("slug")}
                                    />
                                    {formik.touched.slug && formik.errors.slug ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.slug}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="shortDescription"
                                        name="shortDescription"
                                        label="Short Description"
                                        placeholder="Short Description"
                                        className="w-100"
                                        error={getInputClasses("shortDescription")}
                                        {...formik.getFieldProps("shortDescription")}
                                    />
                                    {formik.touched.shortDescription && formik.errors.shortDescription ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.shortDescription}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <InputLabel className="w-100" error={getInputClasses("description")}
                                                title="Description">Description</InputLabel>
                                    <div className="w-100 mb-2">
                                        <ReactQuill
                                            id="description"
                                            name="description"
                                            value={formik.values.description}
                                            onChange={(e) => formik.setFieldValue("description", e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <FormControl
                                        error={getInputClasses("categoryId")}
                                        className="w-100"
                                        placeholder="categoryId"
                                    >
                                        <InputLabel id="categoryId">Category</InputLabel>
                                        <Select
                                            {...formik.getFieldProps("categoryId")}
                                            labelId="categoryId"
                                            id="categoryId"
                                        >
                                            <MenuItem value="">
                                                <em>Not selected</em>
                                            </MenuItem>
                                            {data?.map((category, index) => <MenuItem key={index}
                                                                                      value={category.id}>{category.name}</MenuItem>)}
                                        </Select>
                                        {formik.touched.categoryId && formik.errors.categoryId ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.categoryId}
                                                </div>
                                            </div>
                                        ) : null}
                                    </FormControl>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <ReactSelect suggestions={specificationsData} label="Course specification"
                                                 handleChangeSingle={changeSpecifications}/>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <FormControlLabel
                                    label="Course is free"
                                    control={<Checkbox/>}
                                    {...formik.getFieldProps("isFree")}
                                />
                            </div>
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="price"
                                        name="price"
                                        label="Price"
                                        placeholder="Price"
                                        className="w-100"
                                        error={getInputClasses("price")}
                                        disabled={formik.values.isFree}
                                        {...formik.getFieldProps("price")}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="discount"
                                        name="discount"
                                        label="Discount"
                                        placeholder="Discount"
                                        className="w-100"
                                        error={getInputClasses("discount")}
                                        disabled={formik.values.isFree}
                                        {...formik.getFieldProps("discount")}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <InputLabel id="categoryId">Expiration date</InputLabel>

                            </div>
                            <div className="col-12 col-md-12 col-lg-4">
                                <div className="w-100 pt-2 pb-3">
                                    <TextField
                                        type="number"
                                        id="expireYear"
                                        name="expireYear"
                                        label="Year"
                                        placeholder="Year"
                                        className="w-100"
                                        error={getInputClasses("expireYear")}
                                        value={formik.values.expireYear}
                                        onChange={handleChangeDate}
                                        disabled={formik.values.isFree}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4">
                                <div className="w-100 pt-2 pb-3">
                                    <TextField
                                        type="number"
                                        id="expireMonth"
                                        name="expireMonth"
                                        label="Month"
                                        placeholder="Month"
                                        className="w-100"
                                        error={getInputClasses("expireMonth")}
                                        value={formik.values.expireMonth}
                                        onChange={handleChangeDate}
                                        disabled={formik.values.isFree}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4">
                                <div className="w-100 pt-2 pb-3">
                                    <TextField
                                        type="number"
                                        id="expireDay"
                                        name="expireDay"
                                        label="Day"
                                        placeholder="Day"
                                        className="w-100"
                                        error={getInputClasses("expireDay")}
                                        value={formik.values.expireDay}
                                        onChange={handleChangeDate}
                                        disabled={formik.values.isFree}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="w-100 pt-2 pb-3">
                                    <InputLabel error={getInputClasses("coverFile")} id="coverImage">Cover
                                        image</InputLabel>
                                    <label htmlFor="contained-button-file">
                                        <Input
                                            accept="image/*"
                                            id="contained-button-file"
                                            type="file"
                                            name="coverFile"
                                            label="File {Path}"
                                            placeholder="File Path"
                                            className="w-100"
                                            onChange={handleCoverImage}
                                            value={file}
                                            hidden
                                        />
                                        <Button2 variant="contained" component="span">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Devices/Camera.svg"
                                                )}
                                                className="mr-2"
                                            />
                                            Upload image
                                        </Button2>
                                    </label>
                                    <Preview imageSrc={imageSrc}/>
                                </div>
                            </div>

                        </div>
                    </div>
                    {error ? error?.data?.errors?.map((error, index) => (
                        <div key={index} className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {error.errorMessage}
                            </div>
                        </div>
                    )) : ""}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                            formik.resetForm();
                        }}
                    >
                        Close
                    </Button>
                    <Button disabled={isLoading} variant="info" type="submit" className={`px-9`}>
                        Create
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}