import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import {useLoginUserMutation} from '../../../../redux/api/Auth/authApi'

const initialValues = {
  email: "",
  password: ""
};

function Login() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loginUser, { isLoading, isError, error, isSuccess }] = useLoginUserMutation();

  const [values, setValues] = useState({
    showPassword: false
  });

  const handleClickShowPassword = (key) => {
    setValues({ ...values, [key]: !values[key] });
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter valid email address.")
      // .min(3, "Ən az 3 simvol olmalıdır")
      // .max(50, "Ən çox 50 simvol ola bilər")
      .required("You have to fill the email field."),
    password: Yup.string()
      .min(3, "Ən az 3 simvol olmalıdır")
      .max(50, "Ən çox 50 simvol ola bilər")
      .required("You have to fill the password field.")
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      loginUser(values)

    }
  });
  const today = new Date().getFullYear();

  useEffect(() => {
    if(isError) {
      disableLoading();
      setErrorMessage(error?.data?.message)
    }
  }, [isError])

  useEffect(() => {
    if(isSuccess) {

    }
  }, [isSuccess])

  return (
    <div
      className="login-form login-signin d-flex flex-column h-100"
      id="kt_login_signin_form"
    >
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20 mt-auto">
        <img
          alt="Topaz logo"
          className="max-h-70px"
          src={toAbsoluteUrl("/media/logos/mathyoucan.png")}
        />
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {/* {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            <div className="alert-text ">
              Use account <strong>{initialValues.email}</strong> and password{" "}
              <strong>{initialValues.password}</strong> to continue.
            </div>
          </div>
        )} */}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="E-mail"
            // type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <TextField
            placeholder="Password"
            type={values.showPassword ? "text" : "password"}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    style={{ marginRight: "5px" }}
                    aria-label="Toggle password visibility"
                    onClick={() => handleClickShowPassword("showPassword")}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          {/* <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link> */}
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={isLoading}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Daxil ol</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
        {errorMessage && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block MuiFormLabel-root Mui-error">
              {errorMessage}
            </div>
          </div>
        )}
      </form>
      {/*end::Form*/}
      <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-auto">
        <div className="opacity-70 font-weight-bold">
          &copy;{" "}
          <span className="text-muted font-weight-bold mr-2">
            {today.toString()}
          </span>{" "}
          <a
            href="https://crocusoft.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-hover-primary"
          >
            Developed by Crocusoft LLC
          </a>
        </div>
      </div>
    </div>
  );
}

export default (Login);
