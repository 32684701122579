import React, {useEffect, useState} from 'react';
import {Modal, Button} from "react-bootstrap";
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Input,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";
import {useFormik} from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import {useCreateUserMutation, useGetRolesQuery} from "redux/api/Users/usersApi";
import Loading from "app/components/Loading";
import ReactQuill from "app/components/ReactQuill";
import Button2 from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import Preview from "../../Courses/components/Preview/Preview";
import RenderIf from "app/components/RenderIf";

const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    roleId: "",
    description: ""
}

const validationSchema = Yup.object({
    firstName: Yup.string().required("Please enter a name."),
    lastName: Yup.string().required("Please enter a last name."),
    email: Yup.string().email("Please enter a valid email.").required("Please enter a email."),
    phone: Yup.string().required("Please enter a phone."),
    password: Yup.string().required("Please enter a password."),
})

export default function Create({show, onHide, refetch}) {
    const {data: roles, isLoading} = useGetRolesQuery();
    const [createUser, {isSuccess, error}] = useCreateUserMutation();
    const [imageSrc, setImageSrc] = useState("");
    const [file, setFile] = useState("");
    const [blob, setBlob] = useState();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            onSubmitForm(values);
        }
    });

    const onSubmitForm = (values) => {
        const {firstName, lastName, phone, password, roleId, email, showInAboutUs, description} = values;
        const asset = new FormData();

        const isTeacher = roleId === 2;

        asset.append("firstName", firstName);
        asset.append("lastName", lastName);
        asset.append("phone", phone);
        asset.append("password", password);
        asset.append("email", email)
        asset.append("roles", roleId);
        isTeacher && asset.append("showInAboutUs", showInAboutUs);
        isTeacher && asset.append("description", description);
        blob && asset.append("profileFile", blob);
        createUser(asset)
    }

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    const handleCoverImage = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setBlob(file)
        setFile(e.target.value);

        reader.onloadend = () => {
            setImageSrc(reader.result);
        };

        reader.readAsDataURL(file);
    };

    useEffect(() => {
        if (isSuccess) {
            refetch();
            onHide(false);
        }
    }, [isSuccess])

    useEffect(() => {
        if (roles) {
            formik.setFieldValue("roleId", roles?.[0]?.id)
        }
    }, [roles])

    useEffect(() => {
        if (imageSrc) {
            formik.setFieldValue("profileFile", imageSrc)
        }
    }, [imageSrc])

    return (
        <Modal size="lg" show={show} onHide={onHide}>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="create">Create an admin(teacher)</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        isLoading
                            ? <Loading/>
                            : <div className="col-12 pl-4 pr-4">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-6">
                                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                            <TextField
                                                id="firstName"
                                                name="firstName"
                                                label="First name"
                                                placeholder="First name"
                                                className="w-100"
                                                error={getInputClasses("firstName")}
                                                {...formik.getFieldProps("firstName")}
                                            />
                                        </div>
                                        {formik.touched.firstName && formik.errors.firstName ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{formik.errors.firstName}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6">
                                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                            <TextField
                                                id="lastName"
                                                name="lastName"
                                                label="Last name"
                                                placeholder="Last name"
                                                className="w-100"
                                                error={getInputClasses("lastName")}
                                                {...formik.getFieldProps("lastName")}
                                            />
                                        </div>
                                        {formik.touched.lastName && formik.errors.lastName ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{formik.errors.lastName}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6">
                                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                            <TextField
                                                id="email"
                                                name="email"
                                                label="Email"
                                                placeholder="Email"
                                                className="w-100"
                                                error={getInputClasses("email")}
                                                {...formik.getFieldProps("email")}
                                            />
                                        </div>
                                        {formik.touched.email && formik.errors.email ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{formik.errors.email}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6">
                                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                            <InputMask
                                                mask="+\9\94999999999"
                                                {...formik.getFieldProps("phone")}
                                                disabled={false}
                                                maskChar=""
                                                id="phone"
                                                type="number"
                                            >
                                                {() =>
                                                    <TextField
                                                        label="Phone "
                                                        placeholder="Phone "
                                                        className="w-100"
                                                        name="phone"
                                                        // error={getInputClasses("cityPhoneNumber")}
                                                    />}
                                            </InputMask>
                                        </div>
                                        {formik.touched.phone && formik.errors.phone ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{formik.errors.phone}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6">
                                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                            <TextField
                                                id="password"
                                                name="password"
                                                label="Password"
                                                placeholder="Password"
                                                className="w-100"
                                                error={getInputClasses("password")}
                                                {...formik.getFieldProps("password")}
                                            />
                                        </div>
                                        {formik.touched.password && formik.errors.password ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{formik.errors.password}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6">
                                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                            <FormControl className="w-100">
                                                <InputLabel id="roleId">Role</InputLabel>
                                                <Select
                                                    labelId="roleId"
                                                    name="roleId"
                                                    id="roleId"
                                                    value={formik.values.roleId}
                                                    onChange={formik.handleChange}
                                                    className="w-100"
                                                >
                                                    {roles?.map(role => (
                                                        <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                  <RenderIf condition={formik.values.roleId === 2}>
                                      <div className="col-12 col-md-12 col-lg-12">
                                          <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                              <InputLabel className="w-100" error={getInputClasses("description")}
                                                          title="Description">Description</InputLabel>
                                              <div className="w-100 mb-2">
                                                  <ReactQuill
                                                      id="description"
                                                      name="description"
                                                      value={formik.values.description}
                                                      onChange={(e) => formik.setFieldValue("description", e)}
                                                  />
                                              </div>

                                          </div>
                                      </div>
                                      <div className="col-12 col-md-12 col-lg-12">
                                          <FormControlLabel
                                              label="Show in about use"
                                              control={<Checkbox/>}
                                              {...formik.getFieldProps("showInAboutUs")}
                                          />
                                      </div>
                                  </RenderIf>
                                    <div className="col-12 col-md-12 col-lg-6">
                                        <div className="w-100 pt-2 pb-3">
                                            <InputLabel error={getInputClasses("profileFile")} id="profileFile">Profile
                                                photo</InputLabel>
                                            <label htmlFor="contained-button-file">
                                                <Input
                                                    accept="image/*"
                                                    id="contained-button-file"
                                                    type="file"
                                                    name="profileFile"
                                                    label="File {Path}"
                                                    placeholder="File Path"
                                                    className="w-100"
                                                    onChange={handleCoverImage}
                                                    value={file}
                                                    hidden
                                                />
                                                <Button2 variant="contained" component="span">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/Devices/Camera.svg"
                                                        )}
                                                        className="mr-2"
                                                    />
                                                    Upload image
                                                </Button2>
                                            </label>
                                            <Preview imageSrc={imageSrc}/>
                                        </div>
                                    </div>
                                   <RenderIf condition={error}>
                                       <div className="col-12">
                                           <div className="fv-plugins-message-container">
                                               <div className="fv-help-block">{error?.data?.message}</div>
                                           </div>
                                       </div>
                                   </RenderIf>
                                </div>
                            </div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button variant="info" type="submit" className={`px-9`}>
                        Create
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}