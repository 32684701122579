import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    tableBodyCell: {
        width: "170px",
        padding: "20px 12px",
        borderRight: "2px solid #42446033",
        position: "relative",
        "&:last-child": {
            border: "unset"
        }
    },
})

export default function ExamTableCell({children, ...rest}) {
    const classes = useStyles();

    return (
        <td className={classes.tableBodyCell} {...rest}>
            {children}
        </td>
    )
}