import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {useGetLessonGroupQuizByIdQuery, useUpdateLessonGroupQuizMutation} from "redux/api/Groups/groupsApi";
import * as Yup from 'yup';
import {useFormik} from "formik";
import Loading from "app/components/Loading";
import TextField from "@material-ui/core/TextField";


const validationSchema = Yup.object({
    name: Yup.string().required("Please enter a name."),
    date: Yup.date().required("Please enter a date"),
    questionCount: Yup.number().required("Please enter a question count").typeError("Please enter only number")
})

export default function EditExam({onHide, show, quizId, toggleDelete}) {
    const [initialValues] = useState({
        id: quizId,
        name: "",
        date: "",
        questionCount: 0
    })
    const {data, isLoading} = useGetLessonGroupQuizByIdQuery(quizId);
    const [updateLessonGroupQuiz, {isSuccess, isLoading: isMutating, error}] = useUpdateLessonGroupQuizMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            updateLessonGroupQuiz(values)
        }
    });


    useEffect(() => {
        if (isSuccess) {
            onHide(false);
        }
    }, [isSuccess])

    useEffect(() => {
        if (data) {
            formik.setFieldValue('name', data.name);
            formik.setFieldValue('date', data.date);
            formik.setFieldValue('questionCount', data.questionCount)
        }
    }, [data])

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="changeGroup" centered>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Edit lesson group quiz</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? (
                        <Loading/>
                    ) : (
                        <div className="col-12 pl-4 pr-4">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="name"
                                            name="name"
                                            label="Name"
                                            placeholder="Name"
                                            className="w-100"
                                            {...formik.getFieldProps("name")}
                                        />
                                    </div>
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.name}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="questionCount"
                                            name="questionCount"
                                            label="Question count"
                                            placeholder="question count"
                                            className="w-100"
                                            {...formik.getFieldProps("questionCount")}
                                        />
                                    </div>
                                    {formik.touched.questionCount && formik.errors.questionCount ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.questionCount}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            type="date"
                                            id="date"
                                            name="date"
                                            label="Date"
                                            placeholder="Date"
                                            className="w-100"
                                            InputLabelProps={{shrink: true}}
                                            {...formik.getFieldProps("date")}
                                        />
                                    </div>
                                    {formik.touched.date && formik.errors.date ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.date}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="mr-auto" disabled={isMutating} variant="danger" onClick={toggleDelete}>
                        Delete
                    </Button>
                    <Button disabled={isMutating} variant="light" onClick={() => onHide(false)}>
                        Cancel
                    </Button>
                    <Button disabled={isMutating} variant="primary" type="submit">Edit</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );

}
