import React, {useState} from "react";
import BooksComponent from "./components/Books";
import Contents from "./components/Contents";
import Exercises from "./components/Exercises";
import Order from "./components/Order";
import {useSelector} from "react-redux"


const renderMethods = {
    delivery: "delivery",
    books: "books",
    contents: "contents",
    exercises: "exercises"
}

export default function Books() {

    const {step} = useSelector(state => state.booksState);


    const [render, setRender] = useState(renderMethods.books);
    const [bookId, setBookId] = useState("");
    const [bookName, setBookName] = useState("");
    const [contentId, setContentId] = useState("");
    const [contentName, setContentName] = useState("");
    const [questionId, setQuestionId] = useState("");
    const [questionName, setQuestionName] = useState("");
    const [contentPage, setContentPage] = useState(1)

    return (
        <div className="row bg-white rounded h-100">
            {step === renderMethods.books
                ? <BooksComponent
                    renderMethods={renderMethods}
                    setRender={setRender}
                    bookId={bookId}
                    bookName={bookName}
                    setBookId={setBookId}
                    setBookName={setBookName}
                />
                : step === renderMethods.contents ?
                    <Contents
                        itemId={bookId}
                        renderMethods={renderMethods}
                        setRender={setRender}
                        bookId={bookId}
                        bookName={bookName}
                        contentId={contentId}
                        setContentId={setContentId}
                        contentName={contentName}
                        setContentName={setContentName}
                        setContentPage={setContentPage}
                        contentPage={contentPage}
                    />
                    : step === renderMethods.exercises ?
                    <Exercises
                        questionId={questionId}
                        setQuestionId={setQuestionId}
                        questionName={questionName}
                        setQuestionName={setQuestionName}
                        contentId={contentId}
                        contentName={contentName}
                        renderMethods={renderMethods}
                        setRender={setRender}
                    />
                        : step === renderMethods.delivery &&
                        <Order
                            renderMethods={renderMethods}
                            bookName={bookName}
                            bookId={bookId}
                            />
            }

        </div>
    )
}