import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    testBundlesState: [],
}

export const testBundlesSlice = createSlice({
    initialState,
    name: "testBundlesSlice",

    reducers: {
        setTestBundles: (state, action) => {
            state.testBundlesState = action.payload
        },
    }
});

export default testBundlesSlice.reducer;
export const {setTestBundles} = testBundlesSlice.actions