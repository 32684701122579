import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import ViewIcon from "app/components/icons/ViewIcon";
import EditIcon from "app/components/icons/EditIcon";
import DeleteIcon from "app/components/icons/DeleteIcon";
import RenderIf from "app/components/RenderIf";
import {toAbsoluteUrl} from "_metronic/_helpers";
import Status from "../Status"
import "./Card.css";

export default function Card({
                                 course,
                                 setCourseId,
                                 setShowEdit,
                                 setShowRemove,
                                 setRender,
                                 renderMethods,
                                 setShowContents,
                                 setCourseName
                             }) {

    return (
        <div className="courses-card">
            <div className="courses-card__img">
                <img src={course.coverImage} alt="course"/>
            </div>
            <div className="courses-card__caption">
                <div className="courses-card__title">
                    <h1>{course.title}</h1>
                    <RenderIf condition={course?.itemProject}>
                        <img src={toAbsoluteUrl('/media/image/dyntubeLogo.png')} alt="DynTube"/>
                    </RenderIf>
                </div>
                <div className="courses-card__operations">
                    <Status status={course.status} id={course.id} url={"courses"}/>
                    <Tooltip title="Show contents">
                        <button onClick={() => {
                            setCourseId(course.id);
                            setRender(renderMethods.content);
                            setShowContents(true);
                            setCourseName(course.title)
                        }}><ViewIcon/> Show contents
                        </button>
                    </Tooltip>
                    <Tooltip title="Edit the course">
                        <button onClick={() => {
                            setCourseId(course.id);
                            setShowEdit(true)
                        }}><EditIcon/> Edit
                        </button>
                    </Tooltip>
                    <Tooltip title="Delete the course">
                        <button onClick={() => {
                            setCourseId(course.id);
                            setShowRemove(true);
                            setCourseName(course.title)
                        }}><DeleteIcon/> Delete
                        </button>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}