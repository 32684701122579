import React, {useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {TextField} from "@material-ui/core";
import {useCreateContentMutation} from "../../../../../../redux/api/Books/booksApi"
import {useFormik} from "formik";
import * as Yup from "yup";

export default function Create({show, onHide, bookId, order}) {
    const [createContent, {isLoading, isSuccess}] = useCreateContentMutation();

    function handleSubmit(values){
        const {title} = values
        const body = {itemId: bookId, title, order}
        createContent(body)
    };

    const initialValues = {
        title: ""
    };

    const validationSchema = Yup.object({
        title: Yup.string().required("Please enter a title.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            handleSubmit(values)
        }
    });

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    useEffect(() => {
        if(isSuccess) {
            onHide(false)
        }
    })


    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Create a content</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        name="title"
                                        id="title"
                                        label="Title"
                                        placeholder="Title"
                                        className="w-100"
                                        error={getInputClasses("title")}
                                        {...formik.getFieldProps("title")}
                                    />
                                    {formik.touched.title && formik.errors.title ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.title}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button  onClick={() => {
                        onHide(false);
                    }} variant="light">Close</Button>
                    <Button type="submit" variant="info">Create</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}