import {createApi} from "@reduxjs/toolkit/query/react";
import {APIBaseQuery} from "../AxiosBase";

export const ordersApi = createApi({
    reducerPath: 'ordersApi',
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    endpoints: (build) => ({
        getOrders: build.query({
            query(filter) {
                return {
                    method: "GET",
                    url: 'orders',
                    params: filter
                }
            }
        }),
        getExamOrders: build.query({
            query(filter) {
                return {
                    method: "GET",
                    url: 'orders/trial-exams',
                    params: filter
                }
            }
        })
    })
})

export const {
    useGetOrdersQuery,
    useGetExamOrdersQuery
} = ordersApi