import React, {useState} from "react";
import {useGetOurStudentsQuery} from "../../../redux/api/OurStudents/ourStudentsApi"
import Card from "./components/Card";
import Create from "./modals/Create";
import Edit from "./modals/Edit";
import Remove from "./modals/Remove";
import "./styles/OurStudents.css";
import Pagination from "@material-ui/lab/Pagination";

export default function OurStudents(){

    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [studentId, setStudentId] = useState("");
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(12);

    const {data, refetch, isLoading, isSuccess} = useGetOurStudentsQuery({
        skip: skip,
        take: take
    });

    const handleChangePage = (e, page) => {
        const tempSkip = (page - 1) * take
        setSkip(tempSkip);
        window.scrollTo({top: 0, behavior: "smooth"})
    }

    return(
        <div className="row bg-white rounded h-100">
            <div className="col-12 d-flex align-items-center py-4 height-65">
                <h1 className="display-4 mb-0">Our students</h1>
                <div className="ml-auto">
                    <button onClick={() => setShowCreate(true)} className="button-primary">Add a new showcase
                    </button>
                </div>
            </div>
            <div className="our-students-wrapper">
                {data?.data?.map((student, index) => (
                    <Card key={index} img={student.studentImage} id={student.id} setStudentId={setStudentId} setShowEdit={setShowEdit} setShowRemove={setShowRemove}/>
                ))}
            </div>
            {data?.count > take && (
                <Pagination onChange={(e, page) => handleChangePage(e, page)} count={Math.ceil(data?.count / take)}/>
            )}
            {showCreate && (
                <Create show={showCreate} onHide={setShowCreate} refetch={refetch}/>
            )}
            {showEdit && (
                <Edit show={showEdit} onHide={setShowEdit} studentId={studentId}  refetch={refetch} />
            )}
            {showRemove && (
                <Remove show={showRemove} onHide={setShowRemove} studentId={studentId} refetch={refetch}/>
            )}
        </div>
    )
}