import {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, Modal} from "react-bootstrap";
import {TextField, CircularProgress} from "@material-ui/core";
import Select from "react-select";
import {SketchPicker} from "react-color";
import {
    useGetGroupByIdQuery,
    useGetStudentsQuery,
    useUpdateGroupMutation
} from "../../../../../../../redux/api/Groups/groupsApi";


export default function Edit({show, onHide, id, groupName}) {
    const {data: defaultData, isLoading: isGroupLoading, isSuccess: isGroupLoaded} = useGetGroupByIdQuery(id);
    const {data, isLoading} = useGetStudentsQuery();
    const [updateGroup, {isLoading: isSending, isSuccess}] = useUpdateGroupMutation();
    const [colorState, setColorState] = useState("#ffffff");

    const formik = useFormik({
        initialValues: {
            name: "",
            students: []
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please enter a group name.")
        }),
        onSubmit: (values) => {
            const body = {...values, id, color: colorState}
            updateGroup(body)
        }
    })

    const handleChange = (e) => {
        const students = [];
        e.map(student => {
            students.push(student.value)
        });
        formik.setFieldValue("students", students)
    }

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    useEffect(() => {
        if (isSuccess) {
            onHide(false)
        }
    }, [isSuccess])

    useEffect(() => {
        if (isGroupLoaded) {
            const students = [];
            defaultData?.students?.map(student => {
                students.push(student.value)
            })
            formik.setFieldValue("students", students);
            formik.setFieldValue("name", defaultData?.name);
            setColorState(defaultData?.color ?? "#ffffff")
        }
    }, [isGroupLoaded])


    return (
        <Modal centered size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit the {groupName}
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    {isLoading || isGroupLoading ?
                        <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34a9fc"}}/>
                        </div>
                        : <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="name"
                                            name="name"
                                            label="Group name"
                                            className="w-100"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={getInputClasses("name")}
                                        />
                                        {formik.touched.name && formik.errors.name ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.name}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <Select
                                            className="w-100"
                                            options={data}
                                            defaultValue={defaultData?.students}
                                            isMulti
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <SketchPicker
                                            color={colorState}
                                            onChange={(e) => setColorState(e.hex)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button disabled={isSending} variant="info" type="submit" className={`px-9`}>
                        Update
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}