import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                stroke="#292D32"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M9.794 8.203a4.305 4.305 0 010 6.098c-1.688 1.68-4.418 1.687-6.098 0-1.68-1.688-1.687-4.418 0-6.098"
            ></path>
            <path
                stroke="#292D32"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M7.941 10.058a4.509 4.509 0 010-6.368c1.755-1.762 4.605-1.755 6.368 0 1.762 1.755 1.755 4.605 0 6.368"
            ></path>
        </svg>
    );
}

export default Icon;
