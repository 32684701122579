import React, {useState} from "react";
import {CircularProgress, Divider} from "@material-ui/core";
import AlertComponent from "../../../../../_metronic/_helpers/AlertComponent";
import Status from "../Status";
import Tooltip from "@material-ui/core/Tooltip";
import ViewIcon from "../../../../components/icons/ViewIcon";
import EditIcon from "../../../../components/icons/EditIcon";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import Pagination from "@material-ui/lab/Pagination";
import {useGetTestBundlesQuery} from "../../../../../redux/api/TestBundles/testBundlesApi";
import {makeStyles} from "@material-ui/core/styles";
import Create from "../../modals/Create";
import Edit from "../../modals/Edit";
import Remove from "../../modals/Remove";

export default function TestBundles({setTestBundleId, setRender, renderMethods, setTestBundleName, testBundleId}) {
    const useStyles = makeStyles({
        divider: {
            height: "1px",
            width: "100%"
        },
        wrapper: {
            height: " calc(100% - 65px)",
            paddingTop: "12px"
        },
        circularProgress: {
            color: "#34A9FC"
        },
        card: {
            background: "#FFFFFF",
            boxShadow: "0 2px 18px rgba(62, 74, 97, 0.12)",
            borderRadius: "12px",
            padding: "20px 24px",
            display: "flex",
            marginBottom: "8px"
        },
        cardImg: {
            width: "182px",
            height: "146px",
            background: "gray",
            borderRadius: "16px",
            marginRight: "24px",
            overflow: "hidden",
            "& img": {
                width: "100%"
            }
        },
        cardTitle: {
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "27px",
            color: "#050A46"
        },
        cardCaption: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        },
        cardOperations: {
            display: "flex",
            gap: "32px",
            "& button": {
                background: "unset",
                border: "unset",
                display: "flex",
                alignItems: "center",
                gap: "8px"
            }
        },
        label: {
            width: "100px"
        }
    });

    const classes = useStyles();

    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [page, setPage] = useState(1);

    const {data, isLoading} = useGetTestBundlesQuery({
        skip, take
    });

    const handleChangePage = (e, page) => {
        setPage(page)
        const tempSkip = (page - 1) * take
        setSkip(tempSkip);
        window.scrollTo({top: 0, behavior: "smooth"})
    }
    return (
        <>
            <div className="col-12 d-flex align-items-center py-4 height-65">
                <h1 className="display-4 mb-0">Test bundles</h1>
                <div className="ml-auto">
                    <button onClick={() => setShowCreate(true)} className="button-primary">Add a new practice
                        test
                    </button>
                </div>
            </div>
            <Divider className={classes.divider}/>
            <div className={`col-12 ${classes.wrapper}`}>
                {
                    isLoading
                        ? <div className="d-flex justify-content-center">
                            <CircularProgress className={classes.circularProgress}/>
                        </div>
                        : !isLoading && !data?.data?.length
                            ? <AlertComponent variant="info" message="There is no content"/>
                            : data?.data?.map((testBundle, index) => (
                                <div key={index} className={classes.card}>
                                    <div className={classes.cardImg}>
                                        <img src={testBundle.coverImage} alt="practice-test"/>
                                    </div>
                                    <div className={classes.cardCaption}>
                                        <div className={classes.cardTitle}>
                                            <h1>{testBundle.title}</h1>
                                        </div>
                                        <div className={classes.cardOperations}>
                                            <Status status={testBundle.status} id={testBundle.id} url={"items"} />
                                            <Tooltip title="Show contents">
                                                <button onClick={() => {
                                                    setTestBundleId(testBundle.id);
                                                    setRender(renderMethods.content);
                                                    setTestBundleName(testBundle.title);
                                                }}><ViewIcon/> Show contents
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Edit the practice test">
                                                <button onClick={() => {
                                                    setShowEdit(true);
                                                    setTestBundleId(testBundle.id)
                                                }}><EditIcon/> Edit
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Delete the practice test">
                                                <button onClick={() => {
                                                    setShowRemove(true)
                                                    setTestBundleId(testBundle.id)
                                                    setTestBundleName(testBundle.title);
                                                }}><DeleteIcon/> Delete
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            ))
                }
                {data?.count > take && (
                    <div className="py-2">
                        <Pagination onChange={(e, page) => handleChangePage(e, page)}
                                    count={Math.ceil(data?.count / take)} page={page}/>
                    </div>
                )}
            </div>
            {showCreate && (
                <Create show={showCreate} onHide={setShowCreate}/>
            )}
            {showEdit && (
                <Edit testBundleId={testBundleId} show={showEdit} onHide={setShowEdit}/>
            )}
            {showRemove && (
                <Remove show={showRemove} onHide={setShowRemove} testBundleId={testBundleId}/>
            )}
        </>
    )
}