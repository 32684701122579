import React, {useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {useDeleteUsersMutation} from "../../../../redux/api/Users/usersApi";


export default function Remove(props) {
    const {onHide, show, userId, refetch} = props;

    const [deleteUsers, {isSuccess}] = useDeleteUsersMutation();


    useEffect(() => {
        if (isSuccess) {
            refetch();
            onHide(false);
        }

    }, [isSuccess]);

    const onDeleteUser = (e) => {
        e.preventDefault();
        deleteUsers(userId)
    };


    return (
        <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
            <form onSubmit={onDeleteUser}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">Are you sure to delete ?</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" type="submit">Delete</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );

}
