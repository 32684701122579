import "./Card.css";
import EditIcon from "../../../components/icons/EditIcon";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import {Tooltip} from "@material-ui/core";


export default function Card({img, setStudentId, setShowEdit, setShowRemove, id}){
    return (
        <div className="card__showcase"  >
            <div className="card__showcase-img">
                <img src={img} alt="student"/>
            </div>
            <div className="card__showcase-operations">
              <Tooltip title="Edit">
                  <button onClick={() => {
                      setStudentId(id);
                      setShowEdit(true)
                  }}><EditIcon/></button>
              </Tooltip>
               <Tooltip title="Remove">
                   <button onClick={() => {
                       setStudentId(id);
                       setShowRemove(true)
                   }}><DeleteIcon/></button>
               </Tooltip>
            </div>
        </div>
    )
}