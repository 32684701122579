import {useEffect} from "react";
import {Button, Modal} from "react-bootstrap";
import {TextField, CircularProgress} from "@material-ui/core";
import {useFormik} from "formik";
import * as yup from "yup";
import {useGetPromoCodeByIdQuery, useUpdatePromoCodeMutation} from "redux/api/PromoCode/promoCodeApi";

const style = {
    color: "#34a9fc"
}

const validationSchema = yup.object({
    code: yup.string().required("Please fill this field."),
    discountRate: yup.number().min(1).max(100).required("Please fill this field.").typeError("Please enter number."),
    count: yup.number().min(1).required("Please fill this field.").typeError("Please enter number.")
});

export default function Edit({show, onHide, id}) {
    const {data, isLoading} = useGetPromoCodeByIdQuery(id);
    const [updatePromoCode, {isSuccess}] = useUpdatePromoCodeMutation();

    const formik = useFormik({
        initialValues: {
            code: "",
            discountRate: "",
            count: ""
        },
        validationSchema,
        onSubmit: (values) => {
            updatePromoCode({
                id,
                ...values
            })
        }
    })

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    function handleChangeCode(e) {
        const value = e.target.value.toUpperCase().trim();
        formik.setFieldValue("code", value)
    }


    useEffect(() => {
        if(isSuccess)
            onHide(false)
    }, [isSuccess])

    useEffect(() => {
        if(data) {
            formik.setFieldValue("code", data?.code);
            formik.setFieldValue("discountRate", data?.discountRate);
            formik.setFieldValue("count", data?.count);
        }
    }, [data])

    return(
        <Modal centered size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Create a promo code
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    {isLoading
                        ? <div className="w-100 d-flex justify-content-center"><CircularProgress style={style}/></div>
                        : <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="code"
                                            name="code"
                                            label="Code"
                                            className="w-100"
                                            value={formik.values.code}
                                            onChange={handleChangeCode}
                                            onBlur={formik.handleBlur}
                                            error={getInputClasses("code")}
                                        />
                                        {formik.touched.code && formik.errors.code ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.code}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="discountRate"
                                            name="discountRate"
                                            label="Discount rate"
                                            className="w-100"
                                            value={formik.values.discountRate}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={getInputClasses("discountRate")}
                                        />
                                        {formik.touched.discountRate && formik.errors.discountRate ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.discountRate}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="count"
                                            name="count"
                                            label="Count"
                                            className="w-100"
                                            value={formik.values.count}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={getInputClasses("count")}
                                        />
                                        {formik.touched.count && formik.errors.count ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.count}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button disabled={false} variant="info" type="submit" className={`px-9`}>
                        Update
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}