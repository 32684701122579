import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M13.33 1.666H6.665c-3.333 0-5 1.667-5 5v10.833c0 .459.375.834.833.834h10.834c3.333 0 5-1.667 5-5V6.666c0-3.333-1.667-5-5-5z"
            ></path>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="M5.836 7.916h8.333M5.836 12.082h5.833"
            ></path>
        </svg>
    );
}

export default Icon;
