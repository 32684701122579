import React, {useCallback, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {CircularProgress} from "@material-ui/core";
import Table from "./components/Table";
import RenderIf from "../../../../components/RenderIf";
import RemoveStudent from "./modals/RemoveStudent";
import PracticeTestResults from "./modals/PracticeTestResults";
import ChangeGroup from "./modals/ChangeGroup";
import {useSnackbar} from "../../../../components/Snackbar";
import {useGetGroupStudentsQuery, useCreateLessonsAllMutation} from "redux/api/Groups/groupsApi";
import {Button} from "react-bootstrap";


export default function GroupList({groupId, renderMethods, setRender, groupName}) {
    const [showRemove, setShowRemove] = useState(false);
    const [showChangeGroup, setShowChangeGroup] = useState(false);
    const [studentId, setStudentId] = useState("");
    const [showPracticeTestResults, setShowPracticeTestResults] = useState(false);


    const {isLoading} = useGetGroupStudentsQuery(groupId);

    const [createLessonsAll, {isLoading: isChecking}] = useCreateLessonsAllMutation();

    const {data} = useSelector((state) => ({
        data: state.groupsState.group
    }), shallowEqual);

    const handleRemoveStudent = useCallback((id) => {
        setStudentId(id);
        setShowRemove(true);
    }, []);

    const handleChangeGroup = useCallback((id) => {
        setStudentId(id);
        setShowChangeGroup(true);
    }, [])

    const handleShowPracticeResults = useCallback((id) => {
        setStudentId(id);
        setShowPracticeTestResults(true);
    }, [])

    const handleCheckAllStudents = useCallback(() => {
        const ids = data?.data.map(item => {
            const lessonLines = item.student?.lessonLines ?? [];
            return lessonLines[lessonLines.length - 1]?.id
        });
        createLessonsAll(ids)
    }, [data, createLessonsAll])


    const {SnackbarComponent, showSnackbar} = useSnackbar();

    return (
        <>
            <div className="d-flex justify-content-end">
                <Button onClick={handleCheckAllStudents} disabled={isChecking || isLoading}>Check all students</Button>
            </div>
            {!isLoading
                ? data?.data?.map((groupItem) => (
                    <Table
                        key={groupItem.id}
                        studentId={groupItem.studentId}
                        groupItem={groupItem}
                        id={groupId}
                        showSnackbar={showSnackbar}
                        handleRemoveStudent={handleRemoveStudent}
                        handleChangeGroup={handleChangeGroup}
                        handleShowPracticeResults={handleShowPracticeResults}
                    />
                ))
                : <div className="d-flex justify-content-center">
                    <CircularProgress style={{color: "#34a9fc"}}/>
                </div>
            }
            <RenderIf condition={showRemove}>
                <RemoveStudent
                    show={showRemove}
                    onHide={setShowRemove}
                    studentId={studentId}
                    groupId={groupId}
                />
            </RenderIf>
            <RenderIf condition={showChangeGroup}>
                <ChangeGroup
                    show={showChangeGroup}
                    onHide={setShowChangeGroup}
                    studentId={studentId}
                    groupId={groupId}
                />
            </RenderIf>
            <RenderIf condition={setShowPracticeTestResults}>
                <PracticeTestResults
                    show={showPracticeTestResults}
                    onHide={setShowPracticeTestResults}
                    studentId={studentId}
                />
            </RenderIf>
            <SnackbarComponent/>
        </>
    )
}