import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import {useDeleteShowCaseMutation} from "../../../../redux/api/OurStudents/ourStudentsApi";

const useStyles = makeStyles((theme) => ({
    textarea: {
        width: "100% !important",
        height: "100px !important",
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },
}));

export default function Remove(props) {
    const classes = useStyles();
    const { onHide, show, studentId, refetch, skip, take, sortField, orderBy, onFilter, filterInputs } = props;

    const [deleteShowCase, {isSuccess}] = useDeleteShowCaseMutation();


    useEffect(() => {
        if (isSuccess) {
            refetch();
            onHide();
        }

    }, [isSuccess]);

    const onDeleteUser = (e) => {
        e.preventDefault();
        deleteShowCase(studentId);
    };




    return (
        <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
            <form onSubmit={onDeleteUser}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">Are you sure to delete ?</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" type="submit">Delete</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );

}
