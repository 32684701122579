import {useEffect} from "react";
import {Button, Modal} from "react-bootstrap";
import {TextField} from "@material-ui/core";
import {useFormik} from "formik";
import * as yup from "yup";
import {useUpdateTrialExamMutation, useGetTrialExamByIdQuery} from "redux/api/TrialExams/trialExamsApi";

const validationSchema = yup.object({
    name: yup.string().required("Please enter a name."),
    participationCount: yup.number().required("Please enter participation count.").typeError("Please enter a number"),
    price: yup.number().min(1).required("Please enter a price.").typeError("Please a enter number."),
    examDate: yup.string().required("Please select a date"),
    duration: yup.string().required("Please enter a duration")
})

export default function Edit({show, onHide, id}) {
    const {data} = useGetTrialExamByIdQuery(id);
    const [updateExam, {isSuccess, isLoading}] = useUpdateTrialExamMutation();

    const formik = useFormik({
        initialValues: {
            name: "",
            participationCount: "",
            price: "",
            examDate: "",
            duration: ""
        },
        validationSchema,
        onSubmit: (values) => {
            const body = {
                id, ...values
            }
            updateExam(body);
        }
    })

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    useEffect(() => {
        if(data) {
            const {name, participationCount, price, examDate, duration} = data;
            formik.setFieldValue("name", name);
            formik.setFieldValue("participationCount", participationCount);
            formik.setFieldValue("price", price);
            formik.setFieldValue("examDate", examDate);
            formik.setFieldValue("duration", duration);
        }
    }, [data])

    useEffect(() => {
        if(isSuccess)
            onHide(false)
    }, [isSuccess])

    return(
        <Modal centered size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit the exam
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Name"
                                        className="w-100"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={getInputClasses("name")}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.name}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="participationCount"
                                        name="participationCount"
                                        label="Participation count"
                                        className="w-100"
                                        value={formik.values.participationCount}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={getInputClasses("participationCount")}
                                    />
                                    {formik.touched.participationCount && formik.errors.participationCount ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.participationCount}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="price"
                                        name="price"
                                        label="Price"
                                        className="w-100"
                                        value={formik.values.price}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={getInputClasses("price")}
                                    />
                                    {formik.touched.price && formik.errors.price ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.price}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        type="datetime-local"
                                        id="examDate"
                                        name="examDate"
                                        label="Exam date"
                                        className="w-100"
                                        value={formik.values.examDate}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={getInputClasses("examDate")}
                                        InputLabelProps={{ shrink: true}}
                                    />
                                    {formik.touched.examDate && formik.errors.examDate ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.examDate}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="duration"
                                        name="duration"
                                        label="Duration"
                                        className="w-100"
                                        value={formik.values.duration}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={getInputClasses("duration")}
                                    />
                                    {formik.touched.duration && formik.errors.duration ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.duration}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button disabled={isLoading} variant="info" type="submit" className={`px-9`}>
                        Update
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}