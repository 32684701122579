import {useState} from "react";
import AllGroups from "./modules/Groups";
import Group from "./modules/Group";

const renderMethods = {
    groups: "groups",
    group: "group",
}

export default function Groups() {
    const [render, setRender] = useState(renderMethods.groups)
    const [id, setId] = useState("");
    const [groupName, setGroupName] = useState("");

    return (
        <div className="row bg-white rounded h-100">
            {render === renderMethods.groups
                ? <AllGroups id={id} setId={setId} renderMethods={renderMethods} setRender={setRender} groupName={groupName} setGroupName={setGroupName}/>
                : render === renderMethods.group
                    ? <Group id={id} groupName={groupName} renderMethods={renderMethods} setRender={setRender}/> : ""
            }
        </div>
    )
}