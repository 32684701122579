import React, {useState} from "react";
import {Modal, Button} from "react-bootstrap";
import {TextField, Select, MenuItem, InputLabel} from "@material-ui/core";

export default function Filter({onHide, show, state, setState}) {

    const [value, setValue] = useState(state.orderBy ? "old" : "new");
    const [deliveryStatus, setDeliveryStatus] = useState(state.delivered)

    const onResetFilter = () => {
        setState(prevState => ({
            ...prevState,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            address: "",
            orderBy: false,
            delivered: "notDelivered"
        }));
        setValue("old");
        setDeliveryStatus("notDelivered")
    };

    const filterInputsUpdate = (e) => {
        e.persist()
        setState(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    const setOrder = (e) => {
        setValue(e.target.value)
        if (e.target.value === "new") {
            setState(prevState => ({
                ...prevState, orderBy: false
            }))
        } else {
            setState(prevState => ({
                ...prevState, orderBy: true
            }))
        }
    }

    const handleChangeDeliverStatus = (e) => {
        const value = e.target.value
        setDeliveryStatus(value);
        if(value === "all") {
            setState(prevState => ({
                ...prevState, delivered: ""
            }))
        } else if(value === "delivered") {
            setState(prevState => ({
                ...prevState, delivered: "delivered"
            }))
        } else if(value === "notDelivered") {
            setState(prevState => ({
                ...prevState, delivered: "notDelivered"
            }))
        }
    }

    return (
        <Modal
            size="sm"
            show={show}
            onHide={() => onHide(false)}
            aria-labelledby="create"
            centered
            className="modal-right pr-0"
        >
            <Modal.Header closeButton>
                <Modal.Title id="filter">Orders filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 pl-4 pr-4">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <InputLabel id="order">Order</InputLabel>
                                <div className="w-100">
                                    <Select
                                        className="w-100"
                                        label="Order"
                                        value={value}
                                        onChange={setOrder}
                                    >
                                        <MenuItem value={"old"}> Show oldest first</MenuItem>
                                        <MenuItem value={"new"}>Show newest first</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <InputLabel id="order">Delivery status</InputLabel>
                                <div className="w-100">
                                    <Select
                                        className="w-100"
                                        label="Status"
                                        value={deliveryStatus}
                                        onChange={handleChangeDeliverStatus}
                                    >
                                        <MenuItem value={"all"}>All</MenuItem>
                                        <MenuItem value={"notDelivered"}>Not delivered</MenuItem>
                                        <MenuItem value={"delivered"}>Delivered</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="firstName"
                                    name="firstName"
                                    label="First name"
                                    placeholder="First name"
                                    className="w-100"
                                    value={state.firstName}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="lastName"
                                    name="lastName"
                                    label="Last name"
                                    placeholder="Last name"
                                    className="w-100"
                                    value={state.lastName}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    placeholder="Email"
                                    className="w-100"
                                    value={state.email}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="address"
                                    name="address"
                                    label="Address"
                                    placeholder="Address"
                                    className="w-100"
                                    value={state.address}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={() => {
                        onResetFilter();
                    }}
                >
                    Reset
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

