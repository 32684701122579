import React, {useState} from "react";
import {Modal, Button} from "react-bootstrap";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {useGetUsersListQuery} from "redux/api/Groups/groupsApi";

export default function Filter({onHide, show, teacher, setTeacher}) {
    const {data} = useGetUsersListQuery();

    const onResetFilter = () => {
       setTeacher({value: ""})
    }

    const handleChange = (e) => {
        setTeacher(e.target);
    }

    return (
        <Modal
            size="sm"
            show={show}
            onHide={() => onHide(false)}
            aria-labelledby="create"
            centered
            className="modal-right pr-0"
        >
            <Modal.Header closeButton>
                <Modal.Title id="filter">Admins & Teachers filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 pl-4 pr-4">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <FormControl className="w-100">
                                    <InputLabel id="roleId">Teachers</InputLabel>
                                    <Select
                                        labelId="group"
                                        name="group"
                                        id="group"
                                        value={teacher?.value}
                                        onChange={handleChange}
                                        className="w-100"
                                    >
                                        <MenuItem value="">
                                            <em>Not selected</em>
                                        </MenuItem>
                                        {data?.data?.map(item => (
                                            <MenuItem key={item.id}
                                                      value={item.id}>{`${item.firstName} ${item.lastName}`}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={() => {
                        onResetFilter();
                    }}
                >
                    Reset
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

