import {createApi} from "@reduxjs/toolkit/query/react";
import {APIBaseQuery} from "../AxiosBase";
import {v4 as uuid} from "uuid";

export const aboutApi = createApi({
    reducerPath: 'aboutApi',
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    endpoints: (build) => ({
        getAbout: build.query({
            query(arg) {
                return {
                    url: "abouts"
                }
            }
        }),
        createAbout: build.mutation({
            query(body) {
                return {
                    method: "POST",
                    url: "abouts",
                    headers: {
                        'x-requestid': uuid()
                    },
                    data: body
                }
            }
        })
    })
})

export const {
    useGetAboutQuery,
    useCreateAboutMutation
} = aboutApi;