import userReducer from './features/User/userSlice';
import {authApi} from "./api/Auth/authApi";
import {userApi} from "./api/Auth/userApi";
import {usersApi} from "./api/Users/usersApi";
import usersReducer from "./features/Users/usersSlice"
import {citiesApi} from "./api/Cities/citiesApi";
import {topicsApi} from './api/Topic/topicApi';
import citiesReducer from "./features/Cities/citiesSlice"
import coursesReducer from "./features/Courses/coursesSlice";
import {coursesApi} from "./api/Courses/coursesApi";
import practiceTestsReducer from "./features/PracticeTests/practiceTestsSlice";
import {practiceTestsApi} from "./api/PracticeTests/practiceTestsApi";
import testBundlesReducer from "./features/TestBundles/testBundlesSlice";
import {testBundlesApi} from "./api/TestBundles/testBundlesApi";
import booksReducer from "./features/Books/booksSlice";
import {booksApi} from "./api/Books/booksApi";
import ourStudentsReducer from "./features/OurStudents/ourStudentsSlice";
import {ourStudentsApi} from "./api/OurStudents/ourStudentsApi";
import studentsReducer from "./features/Students/studentsSlice";
import {studentsApi} from "./api/Students/studentsApi";
import groupsReducer from "./features/Groups/groupsSlice";
import {groupsApi} from "./api/Groups/groupsApi";
import {promoCodeApi} from "./api/PromoCode/promoCodeApi";
import {dashboardApi} from "./api/Dashboard/dashboardApi";
import {packagesApi} from "./api/Packages/packagesApi";
import {aboutApi} from "./api/About/aboutApi";
import {trialExamsApi} from "./api/TrialExams/trialExamsApi";
import {ordersApi} from "./api/Orders/ordersApi";
import {specificationsApi} from "./api/Specifications/specificationsApi";
import {categoriesApi} from "./api/Categories/categoriesApi";

export const reducers = {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [topicsApi.reducerPath]: topicsApi.reducer,
    user: userReducer,
    [usersApi.reducerPath]: usersApi.reducer,
    usersState: usersReducer,
    [citiesApi.reducerPath]: citiesApi.reducer,
    citiesState: citiesReducer,
    [coursesApi.reducerPath]: coursesApi.reducer,
    coursesState: coursesReducer,
    [practiceTestsApi.reducerPath]: practiceTestsApi.reducer,
    practiceTestsState: practiceTestsReducer,
    [testBundlesApi.reducerPath]: testBundlesApi.reducer,
    testBundlesState: testBundlesReducer,
    [booksApi.reducerPath]: booksApi.reducer,
    booksState: booksReducer,
    [ourStudentsApi.reducerPath]: ourStudentsApi.reducer,
    ourStudentsState: ourStudentsReducer,
    [studentsApi.reducerPath]: studentsApi.reducer,
    studentsState: studentsReducer,
    [groupsApi.reducerPath]: groupsApi.reducer,
    groupsState: groupsReducer,
    [promoCodeApi.reducerPath]: promoCodeApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [packagesApi.reducerPath]: packagesApi.reducer,
    [aboutApi.reducerPath]: aboutApi.reducer,
    [trialExamsApi.reducerPath]: trialExamsApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [specificationsApi.reducerPath]: specificationsApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer
}

export const middleWares = [
    authApi.middleware,
    userApi.middleware,
    citiesApi.middleware,
    topicsApi.middleware,
    usersApi.middleware,
    coursesApi.middleware,
    practiceTestsApi.middleware,
    testBundlesApi.middleware,
    booksApi.middleware,
    ourStudentsApi.middleware,
    studentsApi.middleware,
    groupsApi.middleware,
    promoCodeApi.middleware,
    dashboardApi.middleware,
    packagesApi.middleware,
    aboutApi.middleware,
    trialExamsApi.middleware,
    ordersApi.middleware,
    specificationsApi.middleware,
    categoriesApi.middleware
]
