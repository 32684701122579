import React, {useState} from "react";
import {Divider, Tabs, Tab} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {makeStyles} from "@material-ui/core/styles";
import {useSnackbar} from "app/components/Snackbar";

import TabPanel from './components/TabPanel';
import GroupList from "./GroupList";
import ExamList from './ExamList';
import NoteList from "./NoteList";

const useStyles = makeStyles({
    wrapper: {
        height: " calc(100% - 65px)",
        padding: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "24px"
    },
    divider: {
        height: "1px",
        width: "100%"
    }
});



export default function Group({id, renderMethods, setRender, groupName}) {
    const [value, setValue] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setIsDisabled(true);
        setTimeout(() => setIsDisabled(false), 1000)
    };

    const classes = useStyles();

    const {showSnackbar, SnackbarComponent} = useSnackbar(1000);

    return(
        <>
            <div className="col-12 d-flex align-items-center py-4 height-65">
                <button onClick={() => {
                    setRender(renderMethods.groups)
                }} className="button-primary mr-2">
                    <ArrowBackIcon/>
                </button>
                <h1 className="display-6 mb-0">{groupName}</h1>
            </div>
            <Divider className={classes.divider}/>
            <div className={`col-12 ${classes.wrapper}`}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab label="Attendance" disabled={isDisabled} />
                    <Tab label="Results" disabled={isDisabled} />
                    <Tab label="Notes" disabled={isDisabled} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <GroupList groupId={id}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ExamList groupId={id} showSnackbar={showSnackbar}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <NoteList groupId={id} showSnackbar={showSnackbar}/>
                </TabPanel>
            </div>
            <SnackbarComponent/>
        </>
    )

}