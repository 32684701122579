import {createApi} from "@reduxjs/toolkit/query/react";
import {APIBaseQuery} from "../AxiosBase";
import {v4 as uuid} from "uuid";
import {COURSE_ID, TEST_BUNDLE_ID, PRACTICE_TEST_ID} from "app/constants";

export const packagesApi = createApi({
    reducerPath: "packagesApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    tagTypes: ["packages"],
    endpoints: (build) => ({
        getItems: build.query({
            query() {
                return {
                    url: `items?itemTypes=1&itemTypes=2&itemTypes=3`
                }
            },
            transformResponse: (res) => {

                const courses = {
                    label: "Courses",
                    options: []
                };
                const practiceTests = {
                    label: "Practice tests",
                    options: []
                };
                const testBundles = {
                    label: "Test bundles",
                    options: []
                };
                res.data.forEach(item => {
                    switch (item.itemType) {
                        case COURSE_ID : {
                            courses.options.push({
                                label: item.title,
                                value: item.id
                            })
                            break
                        }
                        case PRACTICE_TEST_ID : {
                            practiceTests.options.push({
                                label: item.title,
                                value: item.id
                            })
                            break
                        }
                        case TEST_BUNDLE_ID : {
                            testBundles.options.push({
                                label: item.title,
                                value: item.id
                            })
                            break
                        }
                        default: {
                            break
                        }
                    }
                })
                return [courses, practiceTests, testBundles]
            }
        }),
        getPackages: build.query({
            query(filter) {
                const {skip, take} = filter;

                return {
                    url: "packages",
                    params: {
                        skip,
                        take,
                        orderBy: false,
                        sortField: "updated"
                    }
                }
            },
            providesTags: ["packages"]
        }),
        getPackageById: build.query({
            query(id) {
                return {
                    url: `packages/${id}`,
                }
            }
        }),
        createPackage: build.mutation({
            query(body) {
                return {
                    url: "packages",
                    method: "POST",
                    data: body,
                    headers: {
                        "x-requestid": uuid()
                    }
                }
            },
            invalidatesTags: ["packages"]
        }),
        updatePackage: build.mutation({
            query(body) {
                return {
                    url: "packages",
                    method: "PUT",
                    data: body,
                    headers: {
                        "x-requestid": uuid()
                    }
                }
            },
            invalidatesTags: ["packages"]
        }),
        removePackage: build.mutation({
            query(id) {
                return {
                    method: "DELETE",
                    url: `packages/${id}`,
                    headers: {
                        "x-requestid": uuid()
                    }
                }
            },
            invalidatesTags: ["packages"]
        })
    })
});

export const {
    useGetItemsQuery,
    useGetPackagesQuery,
    useGetPackageByIdQuery,
    useCreatePackageMutation,
    useUpdatePackageMutation,
    useRemovePackageMutation
} = packagesApi;