import React, {Suspense} from "react";
import {useSelector, shallowEqual} from "react-redux";
import {Redirect, Switch} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {DashboardPage} from "./pages/DashboardPage";
import CoursesPage from "./pages/CoursesPage";
import PracticeTestsPage from "./pages/PracticeTestsPage";
import TestBundlesPage from "./pages/TestBundlesPage";
import OurStudentsPage from "./pages/OurStudentsPage";
import UsersPage from "./pages/UsersPage";
import BooksPage from "./pages/BooksPage";
import StudentsPage from "./pages/StudentsPage";
import GroupsPage from "./pages/GroupsPage";
import BookOrdersPage from "./pages/BookOrdersPage";
import PromocodePage from "./pages/PromocodePage";
import PackagesPage from "./pages/PackagesPage";
import AboutPage from "./pages/AboutPage";
import TrialExamsPage from "./pages/TrialExamsPage";
import OrdersPage from "./pages/OrdersPage";
import ExamOrdersPage from "./pages/ExamOrdersPage";
import SpecificationsPage from "./pages/SpecificationsPage";
import CategoriesPage from "./pages/CategoriesPage";
import {ADMIN} from "./constants";

export default function BasePage() {
    const isAdmin = useSelector(({user}) => user.user?.userRoles?.[0]?.role === ADMIN, shallowEqual);

    if(isAdmin) {
        return (
            <Suspense fallback={<LayoutSplashScreen/>}>
                <Switch>
                    <Redirect exact from="/" to="/dashboard"/>
                    <ContentRoute path="/about" component={AboutPage}/>
                    <ContentRoute path="/admins-teachers" component={UsersPage}/>
                    <ContentRoute path="/books" component={BooksPage}/>
                    <ContentRoute path="/book-orders" component={BookOrdersPage}/>
                    <ContentRoute path="/categories" component={CategoriesPage}/>
                    <ContentRoute path="/courses" component={CoursesPage}/>
                    <ContentRoute path="/dashboard" component={DashboardPage}/>
                    <ContentRoute path="/exam-orders" component={ExamOrdersPage}/>
                    <ContentRoute path="/groups" component={GroupsPage}/>
                    <ContentRoute path="/our-students" component={OurStudentsPage}/>
                    <ContentRoute path="/orders" component={OrdersPage}/>
                    <ContentRoute path="/practice-tests" component={PracticeTestsPage}/>
                    <ContentRoute path="/promo-codes" component={PromocodePage}/>
                    <ContentRoute path="/packages" component={PackagesPage}/>
                    <ContentRoute path="/students" component={StudentsPage}/>
                    <ContentRoute path="/specifications" component={SpecificationsPage}/>
                    <ContentRoute path="/test-bundles" component={TestBundlesPage}/>
                    <ContentRoute path="/trial-exams" component={TrialExamsPage}/>
                    <Redirect to="error/error-v5"/>
                </Switch>
            </Suspense>
        );
    } else {
        return (
            <Suspense fallback={<LayoutSplashScreen/>}>
                <Switch>
                    <Redirect exact from="/" to="/groups"/>
                    <ContentRoute path="/groups" component={GroupsPage}/>
                    <Redirect from="*" to="/groups"/>
                </Switch>
            </Suspense>
        );
    }
}