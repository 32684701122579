import React from "react";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {BsBook, BsJournals, BsInfoSquare, BsHandbag} from "react-icons/bs";
import {MdOndemandVideo} from "react-icons/md";
import {TbMath} from "react-icons/tb";
import {IoIosPeople} from "react-icons/io";
import {FaLayerGroup, FaPen, FaCheckCircle, FaRegListAlt} from "react-icons/fa";
import {AiOutlineShoppingCart} from "react-icons/ai";
import {TbDiscount2} from "react-icons/tb";
import {RxDashboard} from "react-icons/rx"
import { IoTicketOutline } from "react-icons/io5";
import {BiPackage} from "react-icons/bi";
import {setStep} from "redux/features/Books/booksSlice";
import RenderIf from "app/components/RenderIf";
import {ADMIN} from "app/constants";

export function AsideMenuList({layoutProps}) {
    const dispatch = useDispatch()
    const location = useLocation();

    const {isAdmin} = useSelector(({user}) => ({
        isAdmin: user.user?.userRoles?.[0]?.role === ADMIN
    }), shallowEqual);

    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu &&
            "menu-item-active"} menu-item-open menu-item-not-hightlighted`
            : "";
    };

    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                <RenderIf condition={isAdmin}>
                    <li
                        className={`menu-item ${getMenuItemActive(
                            "/dashboard",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={"/dashboard"}>
                <span className="svg-icon menu-icon">
                    <RxDashboard/>
                </span>
                            <span className="menu-text">
                 Dashboard
                </span>

                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive(
                            "/courses",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={"/courses"}>
                <span className="svg-icon menu-icon">
               <MdOndemandVideo/>
                </span>
                            <span className="menu-text">
                 Courses
                </span>

                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive(
                            "/practice-tests",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={"/practice-tests"}>
                <span className="svg-icon menu-icon">
                 <TbMath/>
                </span>
                            <span className="menu-text">
                 Practice tests
                </span>

                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive(
                            "/test-bundles",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={"/test-bundles"}>
                <span className="svg-icon menu-icon">
                 <BsJournals/>
                </span>
                            <span className="menu-text">
                 Test bundles
                </span>

                        </NavLink>
                    </li>
                    <li
                        onClick={() => dispatch(setStep("books"))}
                        className={`menu-item ${getMenuItemActive(
                            "/books",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={"/books"}>
                <span className="svg-icon menu-icon">
                 <BsBook/>
                </span>
                            <span className="menu-text">
                Books
                </span>

                        </NavLink>
                    </li>
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/",
                            true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/">
                            <span className="svg-icon menu-icon"><BsHandbag/></span>

                            <span className="menu-text">Orders</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                        <div className="menu-submenu ">
                            <i className="menu-arrow"/>
                            <ul className="menu-subnav">
                                <li
                                    className="menu-item  menu-item-parent"
                                    aria-haspopup="true"
                                >
                                <span className="menu-link">
                                    <span className="menu-text">Orders</span>
                                </span>
                                </li>
                                <li
                                    className={`menu-item mb-4 ${getMenuItemActive(
                                        "/",
                                        false
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/orders">
                                        <span className="svg-icon menu-icon"><BsHandbag/></span>
                                        <span className="menu-text">Orders</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item mb-4 ${getMenuItemActive(
                                        "/",
                                        false
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/exam-orders">
                                        <span className="svg-icon menu-icon"><IoTicketOutline/></span>
                                        <span className="menu-text">Exam orders</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item mb-4 ${getMenuItemActive(
                                        "/",
                                        false
                                    )}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/book-orders">
                                        <span className="svg-icon menu-icon"><AiOutlineShoppingCart/></span>
                                        <span className="menu-text">Book orders</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive(
                            "/our-students",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={"/our-students"}>
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Duplicate.svg")}/>
                </span>
                            <span className="menu-text">
                 Our students
                </span>

                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive(
                            "/admins-teachers",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={"/admins-teachers"}>
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
                </span>
                            <span className="menu-text">
                 Admins / Teachers
                </span>

                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive(
                            "/students",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={"/students"}>
                <span className="svg-icon menu-icon">
                    <IoIosPeople/>
                </span>
                            <span className="menu-text">
                 Students
                </span>

                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive(
                            "/promo-codes",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={"/promo-codes"}>
                <span className="svg-icon menu-icon">
                    <TbDiscount2/>
                </span>
                            <span className="menu-text">
                 Promo codes
                </span>

                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive(
                            "/packages",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={"/packages"}>
                <span className="svg-icon menu-icon">
                    <BiPackage/>
                </span>
                            <span className="menu-text">
                 Packages
                </span>

                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive(
                            "/about",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={"/about"}>
                <span className="svg-icon menu-icon">
                    <BsInfoSquare/>
                </span>
                            <span className="menu-text">
                 About
                </span>

                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive(
                            "/trial-exams",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={"/trial-exams"}>
                <span className="svg-icon menu-icon">
                    <FaPen/>
                </span>
                            <span className="menu-text">
                 Trial exams
                </span>

                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive(
                            "/specifications",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={"/specifications"}>
                <span className="svg-icon menu-icon">
                    <FaCheckCircle/>
                </span>
                            <span className="menu-text">
                 Specifiactions
                </span>

                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive(
                            "/categories",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to={"/categories"}>
                <span className="svg-icon menu-icon">
                    <FaRegListAlt/>
                </span>
                            <span className="menu-text">
                 Categories
                </span>

                        </NavLink>
                    </li>
                </RenderIf>
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/groups",
                        false
                    )}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to={"/groups"}>
                <span className="svg-icon menu-icon">
                    <FaLayerGroup/>
                </span>
                        <span className="menu-text">
                 Groups
                </span>

                    </NavLink>
                </li>


            </ul>
            {/* end::Menu Nav */}
        </>
    );
}
