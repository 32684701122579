import {createApi} from '@reduxjs/toolkit/query/react';
import FetchBase from "../FetchBase";



export const topicsApi = createApi({
    reducerPath: "TopicsApi",
    baseQuery: FetchBase,
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        getTopics: builder.query({
            query(filter) {
                const {skip,take,field,orderBy,name} = filter
                return {
                    url: `protocols/subject?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}`
                }
            },
            keepUnusedDataFor: 1,
            transformResponse: (result) => result.data,
            async onQueryStarted(args,{dispatch,queryFulfilled}){
                try {
              
                    await queryFulfilled;
                  
                 

                     
                } catch (error) {
                 
                }
            }
        }),
      
        
      
     
    })
})

export const {useGetTopicsQuery,} = topicsApi
