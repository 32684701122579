import React from "react";
import {Modal, Button} from "react-bootstrap";
import {TextField, Select, MenuItem, InputLabel} from "@material-ui/core";

export default function Filter({onHide, show, state, setState}) {

    const onResetFilter = () => {
        setState(prevState => ({
            ...prevState,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            orderBy: false,
            paymentProcess: "Approved",
            fromDate: "",
            toDate: "",
            name: "",
            slug: ""
        }));
    };

    const filterInputsUpdate = (e) => {
        e.persist()
        setState(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    return (
        <Modal
            size="sm"
            show={show}
            onHide={() => onHide(false)}
            aria-labelledby="create"
            centered
            className="modal-right pr-0"
        >
            <Modal.Header closeButton>
                <Modal.Title id="filter">Orders filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 pl-4 pr-4">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <InputLabel id="order">Payment process</InputLabel>
                                <div className="w-100">
                                    <Select
                                        name="paymentProcess"
                                        className="w-100"
                                        label="Order"
                                        value={state.paymentProcess}
                                        onChange={(e) => filterInputsUpdate(e)}
                                    >
                                        <MenuItem value={"OnPayment"}>On Payment</MenuItem>
                                        <MenuItem value={"Approved"}>Approved</MenuItem>
                                        <MenuItem value={"Canceled"}>Canceled</MenuItem>
                                        <MenuItem value={"Declined"}>Declined</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    type="date"
                                    id="fromDate"
                                    name="fromDate"
                                    label="From date"
                                    placeholder="From date"
                                    className="w-100"
                                    value={state.fromDate}
                                    onChange={(e) => filterInputsUpdate(e)}
                                    InputLabelProps={{shrink: true}}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    type="date"
                                    id="toDate"
                                    name="toDate"
                                    label="To date"
                                    placeholder="To date"
                                    className="w-100"
                                    value={state.toDate}
                                    onChange={(e) => filterInputsUpdate(e)}
                                    InputLabelProps={{shrink: true}}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Name"
                                    placeholder="Name"
                                    className="w-100"
                                    value={state.name}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="firstName"
                                    name="firstName"
                                    label="First name"
                                    placeholder="First name"
                                    className="w-100"
                                    value={state.firstName}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="lastName"
                                    name="lastName"
                                    label="Last name"
                                    placeholder="Last name"
                                    className="w-100"
                                    value={state.lastName}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    placeholder="Email"
                                    className="w-100"
                                    value={state.email}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={() => {
                        onResetFilter();
                    }}
                >
                    Reset
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

