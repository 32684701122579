import {
    fetchBaseQuery,
} from '@reduxjs/toolkit/query';
import {Mutex} from 'async-mutex';

import {v4 as uuid} from 'uuid'

const baseURL = `${process.env.REACT_APP_API_URL}`;


const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, {getState}) => {
        const token = (getState()).user.token
        if (token) headers.set('Authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
        headers.set("x-requestid", uuid());

        return headers
    },
});



const FetchBase = async (args, api, extraOptions) => {

    await mutex.waitForUnlock();

    let result = await baseQuery(args, api, extraOptions);
    if ((result.error?.data)?.message === 'User is not authenticated') {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();

            try {
                const refreshResult = await baseQuery(
                    {credentials: 'include', url: 'auth/refreshToken',method: "POST"},
                    api,
                    extraOptions
                );

                if (refreshResult.data) {
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    window.location.href = '/auth/login';
                }
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }
    return result;
};

export default FetchBase;
