import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    title: {
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "30px",
        letterSpacing: "0.032px",
        marginBottom: "20px"
    },
    items: {
        width: "100%",
        height: " calc(100% - 65px)",
        padding: "12px"
    },
    itemsWrapper: {
        display: "flex",
        gap: "8px",
        marginBottom: "16px",
        flexWrap: "wrap"
    },
    item: {
        position: "relative",
        width: "200px",
        padding: "20px 16px",
        borderRadius: "12px",
        border: "1px solid var(--stroke, #DADCDE)",
        background: "rgba(255, 255, 255, 0.40)",
        boxShadow: "0px 0px 20px 0px rgba(8, 30, 64, 0.15)",
        overflow: "hidden",
    },
    itemSkeleton: {
        borderRadius: "12px"
    },
    itemLine: {
        width: "100%",
        height: "10px",
        position: "absolute",
        left: "0",
        bottom: "0",
        background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(162,216,254,1) 14%, rgba(130,202,253,1) 59%, rgba(52,169,252,1) 100%);"
    },
    itemCount: {
        fontSize: "26px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "normal",
        letterSpacing: "0.39px",
    },
    itemName: {
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
    },
    divider: {
        height: "1px",
        width: "100%"
    }
});

export default useStyles;