import React, {useEffect, useState} from "react";
import {Modal, Button} from "react-bootstrap";
import {CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {
    useGetExerciseByIdQuery,
    useUpdateExerciseMutation
} from "../../../../../../redux/api/Books/booksApi"
import {useFormik} from "formik";
import * as Yup from "yup";

export default function Edit({show, onHide, exerciseId, totalCount}) {
    const editData = useGetExerciseByIdQuery(exerciseId)
    const [updateExercise, {isLoading, isSuccess}] = useUpdateExerciseMutation();

    const [orderList, setOrderList] = useState([])

    function handleSubmit(values) {
        const {url, exercise, page, order} = values
        const body = {
            id: exerciseId,
            url,
            exercise,
            page,
            order
        }
        updateExercise(body)
    }

    const initialValues = {
        url: "",
        exercise: "",
        page: "",
        order: ""
    };

    const validationSchema = Yup.object({
        url: Yup.string().required("Please enter an url."),
        exercise: Yup.string().required("Please enter an exercise number."),
        page: Yup.string().required("Please enter a page number."),
        order: Yup.string().required("Please enter an order."),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            handleSubmit(values)
        }
    });

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    useEffect(() => {
        if (totalCount) {
            let arr = [];
            let count = totalCount + 1
            for (let i = 1; i < count; i++) {
                arr.push(i)
            }
            setOrderList(arr)
        }
    }, [totalCount])

    useEffect(() => {
        if (isSuccess) {
            onHide(false)
        }
    });

    useEffect(() => {
        if (editData.isSuccess) {
            const {url, page, exercise, order} = editData.data
            formik.setFieldValue("url", url);
            formik.setFieldValue("exercise", exercise);
            formik.setFieldValue("page", page);
            formik.setFieldValue("order", order)
        }
    }, [editData.isLoading])


    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit the section</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    {editData.isLoading
                        ? <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34A9FC"}}/>
                        </div>
                        : <div className="col-12 pl-4 pr-4">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            name="url"
                                            id="url"
                                            label="Url"
                                            placeholder="Url"
                                            className="w-100"
                                            error={getInputClasses("url")}
                                            {...formik.getFieldProps("url")}
                                        />
                                        {formik.touched.url && formik.errors.url ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.url}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            name="exercise"
                                            id="exercise"
                                            label="Exercise"
                                            placeholder="Exercise"
                                            className="w-100"
                                            error={getInputClasses("exercise")}
                                            {...formik.getFieldProps("exercise")}
                                        />
                                        {formik.touched.exercise && formik.errors.exercise ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.exercise}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            name="page"
                                            id="page"
                                            label="Page"
                                            placeholder="Page"
                                            className="w-100"
                                            error={getInputClasses("page")}
                                            {...formik.getFieldProps("page")}
                                        />
                                        {formik.touched.page && formik.errors.page ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.page}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <FormControl
                                            error={getInputClasses("order")}
                                            className="w-100"
                                            placeholder="order"
                                        >
                                            <InputLabel id="order">Order</InputLabel>
                                            <Select
                                                {...formik.getFieldProps("order")}
                                                labelId="order"
                                                id="order"
                                            >
                                                <MenuItem value="">
                                                    <em>Not selected</em>
                                                </MenuItem>
                                                {orderList.map((order, index) => <MenuItem key={index}
                                                                                           value={order}>{order}</MenuItem>)}
                                            </Select>
                                            {formik.touched.order && formik.errors.order ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.order}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        onHide(false);
                    }}  variant="light">Close</Button>
                    <Button type="submit" variant="info">Update</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}