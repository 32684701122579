import React from "react";
import {Skeleton} from "@material-ui/lab";
import useStyles from "../styles";
import {Divider} from "@material-ui/core";

export default function SkeletonComponent() {
    const classes = useStyles();

    return (
        <div className="bg-white rounded h-100">
            <div className="col-12 d-flex align-items-center py-4 height-65">
                <h1 className="display-4 mb-0">Dashboard</h1>
            </div>
            <Divider className={classes.divider}/>
            <div className={classes.items}>
                <div className={classes.itemsWrapper}>
                    <Skeleton
                        className={classes.itemSkeleton}
                        animation="wave"
                        variant="rect"
                        width={200}
                        height={133}
                    />
                    <Skeleton
                        className={classes.itemSkeleton}
                        animation="wave"
                        variant="rect"
                        width={200}
                        height={133}
                    />
                    <Skeleton
                        className={classes.itemSkeleton}
                        animation="wave"
                        variant="rect"
                        width={200}
                        height={133}
                    />
                    <Skeleton
                        className={classes.itemSkeleton}
                        animation="wave"
                        variant="rect"
                        width={200}
                        height={133}
                    />
                    <Skeleton
                        className={classes.itemSkeleton}
                        animation="wave"
                        variant="rect"
                        width={200}
                        height={133}
                    />
                    <Skeleton
                        className={classes.itemSkeleton}
                        animation="wave"
                        variant="rect"
                        width={200}
                        height={133}
                    />
                </div>
            </div>
        </div>
    )
}