import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {useUpdateTestBundleStatusMutation} from "../../../../../redux/api/TestBundles/testBundlesApi";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    label: {
        width: "100px"
    },
    button: {
        background: "unset"
    }
});



export default function Status({id, status, url}) {

    const classes = useStyles()
    const [value, setValue] = useState(Boolean(status));

    const [changeTestBundleStatus, {isLoading, isSuccess}] = useUpdateTestBundleStatusMutation();

    const changeStatus = () => {
        changeTestBundleStatus({
            id,
            status: !status,
            url
        })
    };

    useEffect(() => {
        if (isSuccess) {
            setValue(prev => !prev)
        }
    }, [isSuccess])

    return (
        <button className={classes.button}>
            <FormControlLabel
                control={
                    <Switch
                        checked={value}
                        onChange={changeStatus}
                        color="secondary"
                        disabled={isLoading}
                    />
                }
                label={value ? "Active" : "Inactive"}
                className={`ml-auto mb-0 ${classes.label}`}
            />
        </button>
    )
}

Status.propTypes = {
    status: PropTypes.bool
}