import React from "react";
import {Modal} from "react-bootstrap";
import {makeStyles} from "@material-ui/core/styles";
import {useGetPracticeTestResultsQuery} from "redux/api/Students/studentsApi";
import Loading from "app/components/Loading";

const useStyles = makeStyles({
    container: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr))",
        gap: "8px"
    },
    resultCard: {
        width: "100%",
        borderRadius: "12px",
        background: "#fff",
        boxShadow: "0 2px 18px 0 rgba(62,74,97,.12)",
        padding: "24px",
        display: "flex",
        flexDirection: "column",
    },
    resultCardTitle: {
        color: "#050a46",
        fontSize: "20px",
        fontWeight: "500",
    },
    resultCardRow: {
        display: "flex",
        justifyContent: "space-between",
        color: "#424460",
        fontSize: "16px",
        fontWeight: "500",
    },
    resultCardButton: {
        background: "unset",
        border: "unset",
        color: "#34a9fc",
        textDecoration: "underline",
        marginTop: "auto",
    },
    noResultText: {
        color: "#050a46",
        fontSize: "20px",
        fontWeight: "500",
    }
});

export default function PracticeTestResults({onHide, show, studentId}) {
    const classes = useStyles();

    const {data, isLoading} = useGetPracticeTestResultsQuery(studentId, {skip: !studentId});

    return (
        <Modal size='lg' show={show} onHide={onHide} aria-labelledby="examResults" centered>
            <Modal.Header closeButton>
                <Modal.Title id="delete">Exam results</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.container}>
                    {isLoading ?
                        <Loading/>
                        : data?.data?.length ? <>
                                {data?.data?.map((practice, index) => (
                                    <div key={index} className={classes.resultCard}>
                                        <div>
                                            <span className={classes.resultCardTitle}>Name: </span>
                                            <span>{practice.title} {practice.contentTitle}</span>
                                        </div>
                                        <div className={classes.resultCardRow}>
                                            <span>Score:</span>
                                            <span>{practice.score}</span>
                                        </div>
                                        <div className={classes.resultCardRow}>
                                            <span>Right count:</span>
                                            <span>{practice.rightCount}</span>
                                        </div>
                                        <div className={classes.resultCardRow}>
                                            <span>False count:</span>
                                            <span>{practice.falseCount}</span>
                                        </div>
                                    </div>
                                ))}
                              </>
                            : <div className="d-flex justify-content-center"><span className={classes.noResultText}>There is no result yet</span></div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );

}
