import React, {useEffect, useRef, useState} from 'react';
import {Modal, Button} from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormControl from "@material-ui/core/FormControl";
import {
    useGetCategoriesQuery,
    useCreateBookMutation
} from "../../../../../../redux/api/Books/booksApi";
import {useGetSpecificationsQuery} from "../../../../../../redux/api/Courses/coursesApi";
import ReactSelect from "../../../../UI/ReactMultiSelect";
import Preview from "../../../../Courses/components/Preview/Preview";
import Button2 from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../../_metronic/_helpers";
import ReactQuill from "../../../../../components/ReactQuill"


export default function Create({show, onHide}) {
    const [specificationsData, setSpecificationsData] = useState([]);
    const [imageSrc, setImageSrc] = useState("");
    const [file, setFile] = useState("");
    const [pdf, setPdf] = useState(null);

    const formRef = useRef(null);

    const {data} = useGetCategoriesQuery();
    const specifications = useGetSpecificationsQuery();
    const [createBook, {isLoading, error, isSuccess}] = useCreateBookMutation();

    const initialValues = {
        title: "",
        slug: "",
        shortDescription: "",
        description: "",
        categoryId: "",
        price: "",
        discount: "",
        itemSpecifications: [],
        coverFile: "",
        bookUrl: "",
        answerFile: null
    };

    const validationSchema = Yup.object({
        title: Yup.string().required("Please enter a title."),
        slug: Yup.string().required("Please enter a slug."),
        shortDescription: Yup.string().required("Please enter a short description."),
        description: Yup.string().required("Please enter a description."),
        categoryId: Yup.string().required("Please enter a category."),
        price: Yup.string().required(),
        coverFile: Yup.string().required("Please enter a cover file."),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (values) => {
        const asset = new FormData(formRef.current);
        formik.values.itemSpecifications.forEach((spec, index) => asset.append(`itemSpecifications[${index}].specificationId`, spec.specificationId))
        asset.append("description", formik.values.description);
        asset.append("itemType", "4");
        createBook(asset);
    }

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    const handleCoverImage = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setFile(e.target.value);

        reader.onloadend = () => {
            setImageSrc(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handlePdf = (e) => {
        setPdf(e.target.files[0]);
    }

    const changeSpecifications = (e) => {
        let tempSpecifications = e.map(item => ({specificationId: item.value}))
        formik.setFieldValue("itemSpecifications", tempSpecifications);
    }

    useEffect(() => {

        if (specifications.data) {
            let specificationsOptions = specifications.data.map(item => ({
                value: item.id,
                label: item.name
            }));
            setSpecificationsData(specificationsOptions)
        }
    }, [specifications.isLoading]);

    useEffect(() => {
        if (isSuccess) {
            onHide(false)
        }
    });

    useEffect(() => {
        if (imageSrc) {
            formik.setFieldValue("coverFile", imageSrc)
        }
    }, [imageSrc])

    return (
        <Modal size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Create a book</Modal.Title>
            </Modal.Header>
            <form ref={formRef} onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="title"
                                        name="title"
                                        label="Title"
                                        placeholder="Title"
                                        className="w-100"
                                        error={getInputClasses("title")}
                                        {...formik.getFieldProps("title")}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="slug"
                                        name="slug"
                                        label="Slug"
                                        placeholder="Slug"
                                        className="w-100"
                                        error={getInputClasses("slug")}
                                        {...formik.getFieldProps("slug")}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="bookUrl"
                                        name="bookUrl"
                                        label="Book Url"
                                        placeholder="Book Url"
                                        className="w-100"
                                        {...formik.getFieldProps("bookUrl")}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="shortDescription"
                                        name="shortDescription"
                                        label="Short description"
                                        placeholder="Short description"
                                        className="w-100"
                                        error={getInputClasses("shortDescription")}
                                        {...formik.getFieldProps("shortDescription")}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <InputLabel className="w-100" error={getInputClasses("description")}
                                                title="Description">Description</InputLabel>
                                    <div className="w-100 mb-2">
                                        <ReactQuill
                                            id="description"
                                            name="description"
                                            value={formik.values.description}
                                            onChange={(e) => formik.setFieldValue("description", e)}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <FormControl className="w-100">
                                        <InputLabel error={getInputClasses("categoryId")}
                                                    htmlFor="categoryId">Category</InputLabel>
                                        <Select
                                            inputProps={{
                                                name: "categoryId",
                                                id: "categoryId",
                                            }}
                                            {...formik.getFieldProps("categoryId")}
                                            error={getInputClasses("categoryId")}
                                        >
                                            <MenuItem value="">All</MenuItem>
                                            {
                                                data?.data?.length && data.data.map((item, index) => (
                                                    <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <ReactSelect suggestions={specificationsData} label="Specification"
                                                 handleChangeSingle={changeSpecifications}/>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="price"
                                        name="price"
                                        label="Price"
                                        placeholder="Price"
                                        className="w-100"
                                        error={getInputClasses("price")}
                                        {...formik.getFieldProps("price")}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="discount"
                                        name="discount"
                                        label="Discount"
                                        placeholder="Discount"
                                        className="w-100"
                                        error={getInputClasses("discount")}
                                        {...formik.getFieldProps("discount")}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="w-100 pt-2 pb-3">
                                    <InputLabel error={getInputClasses("coverFile")} id="coverImage">Cover
                                        image</InputLabel>
                                    <label htmlFor="contained-button-file">
                                        <Input
                                            accept="image/*"
                                            id="contained-button-file"
                                            type="file"
                                            name="coverFile"
                                            label="File {Path}"
                                            placeholder="File Path"
                                            className="w-100"
                                            onChange={handleCoverImage}
                                            value={file}
                                            hidden
                                        />
                                        <Button2 variant="contained" component="span">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Devices/Camera.svg"
                                                )}
                                                className="mr-2"
                                            />
                                            Upload image
                                        </Button2>
                                    </label>
                                    <Preview imageSrc={imageSrc}/>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-6">
                                <div className="w-100 pt-2 pb-3">
                                    <InputLabel id="asnwerPdf">Answer
                                        PDF</InputLabel>
                                    <label htmlFor="contained-button-file-pdf">
                                        <Input
                                            id="contained-button-file-pdf"
                                            type="file"
                                            name="answerFile"
                                            label="File {Path}"
                                            placeholder="File Path"
                                            className="w-100"
                                            onChange={handlePdf}
                                            hidden
                                        />
                                        <Button2 variant="contained" component="span">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Devices/Camera.svg"
                                                )}
                                                className="mr-2"
                                            />
                                            Upload pdf
                                        </Button2>
                                    </label>
                                </div>
                                <div>{pdf?.name}</div>
                            </div>
                        </div>
                    </div>
                    {error ? error?.data?.errors?.map((error, index) => (
                        <div key={index} className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {error.errorMessage}
                            </div>
                        </div>
                    )) : ""}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button disabled={isLoading} variant="info" type="submit" className={`px-9`}>
                        Create
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}