import {Modal, Button} from "react-bootstrap";
import React, {useState, useRef, useEffect} from "react";
import {InputLabel} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import Preview from "../components/Preview";
import {useGetShowCaseByIdQuery, useUpdateShowCaseMutation} from "../../../../redux/api/OurStudents/ourStudentsApi";
import Button2 from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";

export default function Edit({show, onHide, refetch, studentId}) {
    const formRef = useRef(null);
    const [file, setFile] = useState("");
    const [imageSrc, setImageSrc] = useState("");

    const {data, isLoading} = useGetShowCaseByIdQuery(studentId);
    const [updateShowCase, {isSuccess}] = useUpdateShowCaseMutation();

    const handleCoverImage = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setFile(e.target.value);

        reader.onloadend = () => {
            setImageSrc(reader.result)
        };

        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(formRef.current);
        formData.append("id", studentId)
        updateShowCase(formData);

    };

    useEffect(() => {
        if (data) {
            setImageSrc(data.studentImage)
        }
    }, [isLoading])

    useEffect(() => {
        if (isSuccess) {
            refetch();
            onHide(false);
        }
    }, [isSuccess])

    return (
        <Modal size="md" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title id="create">
                    Edit showcase
                </Modal.Title>
            </Modal.Header>
            <form ref={formRef} onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-column align-items-center">
                                    <label htmlFor="contained-button-file">
                                        <Input
                                            accept="image/*"
                                            id="contained-button-file"
                                            type="file"
                                            name="studentFile"
                                            label="File {Path}"
                                            placeholder="File Path"
                                            className="w-100"
                                            onChange={handleCoverImage}
                                            value={file}
                                            hidden
                                        />
                                        <Button2 variant="contained" component="span">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Devices/Camera.svg"
                                                )}
                                                className="mr-2"
                                            />
                                            Upload image
                                        </Button2>
                                    </label>
                                    <Preview imageSrc={imageSrc}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                            // formik.resetForm();
                        }}
                    >
                        Close
                    </Button>
                    <Button variant="info" type="submit" className={`px-9`}>
                        Update
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}