import React, {useEffect, useRef, useState} from 'react';
import {Modal, Button} from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useUpdateContentMutation, useGetContentsByIdQuery} from "../../../../redux/api/Courses/coursesApi";
import {InputLabel} from "@material-ui/core";
import {CircularProgress} from "@material-ui/core";

export default function EditContent({show, onHide, totalCount, itemId, refetch, contentId}) {
    const contentData = useGetContentsByIdQuery(contentId);
    const [updateContent, {isLoading, isSuccess}] = useUpdateContentMutation();
    const [order, setOrder] = useState([])

    const initialValues = {
        title: "",
        order: ""
    };
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Please enter title."),
        order: Yup.string().required("Please select the order."),
    });

    const onSubmitForm = (body) => {
        updateContent(body);
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            const body = {
                id: contentId,
                title: values.title,
                order: values.order
            }
            onSubmitForm(body)
        },
    });

    useEffect(() => {
        if (contentData.isSuccess) {
            const {title, order} = contentData.data;
            formik.setFieldValue("title", title);
            formik.setFieldValue("order", order)

        }
    }, [contentData.isLoading])

    useEffect(() => {
        if (isSuccess) {
            refetch();
            onHide(false);
        }
    }, [isLoading]);

    useEffect(() => {
        if (totalCount) {
            let arr = [];
            let count = totalCount + 1
            for (let i = 1; i < count; i++) {
                arr.push(i)
            }
            setOrder(arr)
        }
    }, [totalCount])

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    return (
        <Modal size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title id="create">Edit the content</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    {contentData?.isLoading
                        ? <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34A9FC"}}/>
                        </div>
                        : <div className="col-12 pl-4 pr-4">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="title"
                                            name="title"
                                            label="Title"
                                            placeholder="Title"
                                            className="w-100"
                                            error={getInputClasses("title")}
                                            {...formik.getFieldProps("title")}
                                        />
                                        {formik.touched.title && formik.errors.title ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{formik.errors.title}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <FormControl className="w-100">
                                        <InputLabel id="order">Order</InputLabel>
                                        <Select
                                            {...formik.getFieldProps("order")}
                                            labelId="order"
                                            id="order"
                                            error={getInputClasses("order")}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {order.map((item, index) => <MenuItem key={index}
                                                                                  value={item}>{item}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    {formik.touched.order && formik.errors.order ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.order}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                            formik.resetForm();
                        }}
                    >
                        Close
                    </Button>
                    <Button type="submit" variant="info" className={`px-9`}>
                        Update
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}