import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress} from "@material-ui/core";
import {Pagination} from '@material-ui/lab';
import {format} from "date-fns";
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";

const useStyles = makeStyles({
    tableContainer: {
        width: "100%",
        height: "calc(100% - 65px)",
        borderRadius: "12px",
        padding: "24px 12px",
        overflow: "auto"
    },
    table: {
        width: "100%",
        boxShadow: "0px 2px 18px rgba(62, 74, 97, 0.12)",
        borderRadius: "12px",
        overflow: "hidden",
    },
    tablePagination: {
        padding: "12px 0"
    },
    tableHeadCell: {
        background: "#34A9FC",
        padding: "16px 12px",

        "&:last-child": {
            borderRadius: "0 12px 0 0"
        }
    },
    tableHeadText: {
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.035em",
        color: "#FFFFFF",
        whiteSpace: 'nowrap'
    },
    tableBody: {},
    tableBodyRow: {
        "&:nth-child(even)": {
            background: "#F6F6F6"
        },

    },
    tableBodyCell: {
        padding: "20px 16px",
        borderRight: "2px solid #42446033",
        position: "relative",
        "&:last-child": {
            border: "unset"
        },
        "&:hover": {
            "& $tableBodyCellButtons": {
                display: "flex"
            }
        }
    },
    tableBodyCellImg: {
        width: "40px",
        height: "40px",
        borderRadius: "8px",
        overflow: "hidden",
        marginRight: "8px",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover"
        }
    },
    tableBodyText: {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#424460"

    },
    tableBodyCellOperations: {
        display: "flex",
        gap: "16px",
        justifyContent: "space-evenly"
    },
    tableCheckButton: {
        width: "28px",
        height: "28px",
        background: "#32B432",
        opacity: "0.8",
        borderRadius: "4px"
    },
    tableCheckButtonDisabled: {
        width: "28px",
        height: "28px",
        background: "#a29f9f",
        opacity: "0.8",
        borderRadius: "4px"
    },
    tableAddRowButton: {
        width: "28px",
        height: "28px",
        background: "#34A9FC",
        opacity: "0.8",
        borderRadius: "4px"
    },
    tableDeleteButton: {
        width: "28px",
        height: "28px",
        background: "#E6F6E6",
        opacity: "0.8",
        borderRadius: "4px"
    },
    tableStatusButton: {
        width: "28px",
        height: "28px",
        background: "#E92939",
        opacity: "0.8",
        borderRadius: "4px"
    },
    addLineButton: {
        padding: "12px 18px",
        borderRadius: "12px",
        background: "#34A9FC",
        "& svg": {
            width: "24px",
            height: "24px"
        }
    },
    remarkButton: {
        display: "flex",
        background: "#424460",
        borderRadius: "4px",
        padding: "9px",
        alignSelf: "center"
    },
    progress: {
        display: "flex",
        justifyContent: "center",
        "& svg": {
            color: "#34a9fc"
        }
    },
    paymentProcess: {
        display: "flex",
        alignItems: "center",
        gap: "8px"
    },
    indicator: {
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        display: "inline-block",
    }
});

const paymentProcessType = {
    OnPayment: {backgroundColor: "#d7dbdd"},
    Approved: {backgroundColor: "#2ecc71"},
    Canceled: {backgroundColor: "#f4d03f"},
    Declined: {backgroundColor: "#e74c3c"}
}

const headRows = [
    {
        id: "paymentProcess",
        label: "Payment process"
    },
    {
        id: "title",
        label: "Title"
    },
    {
        id: "slug",
        label: "Slug"
    },
    {
        id: "itemType",
        label: "Item type"
    },
    {
        id: "price",
        label: "Price"
    },
    {
        id: "discount",
        label: "Discount"
    },
    {
        id: "promoCode",
        label: "Promo code"
    },
    {
        id: "quantity",
        label: "Quantity"
    },
    {
        id: "created",
        label: "Created"
    },
    {
        id: "firstName",
        label: "First name"
    },
    {
        id: "lastName",
        label: "Last name"
    },
    {
        id: "email",
        label: "Email"
    },
    {
        id: "phone",
        label: "Phone"
    },
    {
        id: "isFree",
        label: "Is free"
    },
    {
        id: "expirable",
        label: "Expirable"
    }
]

export default function Table({data, isLoading, state, setState}) {
    const [page, setPage] = useState(1);

    const count = data?.count && Math.ceil(data.count / state.take);

    const classes = useStyles();

    function handleChangePage(e, page) {
        const tempPage = page > 1 ? page - 1 : 0;
        const skip = tempPage * state.take;
        setState(prevState => ({
            ...prevState, skip
        }))
        setPage(page);
    }

    return (
        <div className={classes.tableContainer}>
            {!isLoading && data?.data?.length > 0
                ? <>
                    <table className={classes.table}>
                        <thead className={classes.tableHead}>
                        <tr>
                            {headRows.map(item => (
                                <td key={item.id} className={classes.tableHeadCell}>
                                    <span className={classes.tableHeadText}>{item.label}</span>
                                </td>
                            ))}
                        </tr>
                        </thead>
                        <tbody className={classes.tableBody}>
                        {!isLoading && data?.data?.map(row => (
                            <tr key={row.id} className={classes.tableBodyRow}>
                                <td className={classes.tableBodyCell}>
                                    <div className={classes.paymentProcess}>
                                        <span>{row.paymentProcess}</span> <span
                                        style={paymentProcessType[row.paymentProcess]}
                                        className={classes.indicator}></span>
                                    </div>
                                </td>
                                <td className={classes.tableBodyCell}>
                                    {row.title}
                                </td>
                                <td className={classes.tableBodyCell}>
                                    {row.slug}
                                </td>
                                <td className={classes.tableBodyCell}>
                                    {row.itemType}
                                </td>
                                <td className={classes.tableBodyCell}>
                                    {`${row.price ?? 0} $`}
                                </td>
                                <td className={classes.tableBodyCell}>
                                    {`${row.discount ?? 0} $`}
                                </td>
                                <td className={classes.tableBodyCell}>
                                    {row.promoCode}
                                </td>
                                <td className={classes.tableBodyCell}>
                                    {row.quantity}
                                </td>
                                <td className={classes.tableBodyCell}>
                                    {row.created ? format(new Date(row.created), "dd.MM.yyyy") : null}
                                </td>
                                <td className={classes.tableBodyCell}>
                                    {row.firstName}
                                </td>
                                <td className={classes.tableBodyCell}>
                                    {row.lastName}
                                </td>
                                <td className={classes.tableBodyCell}>
                                    {row.email}
                                </td>
                                <td className={classes.tableBodyCell}>
                                    {row.phone}
                                </td>
                                <td className={classes.tableBodyCell}>
                                    {row.isFree ? "Yes" : "No"}
                                </td>
                                <td className={classes.tableBodyCell}>
                                    {row.expirable ? "Yes" : "No"}
                                </td>
                            </tr>
                        ))
                        }
                        </tbody>
                    </table>
                    <Pagination page={page} count={count} onChange={handleChangePage}
                                className={classes.tablePagination}/>
                </>
                : isLoading ? <div className={classes.progress}><CircularProgress/></div>
                    : !isLoading && !data?.data?.length &&
                    <div className="d-flex justify-content-center"><AlertComponent message="There is no order yet."
                                                                                   variant="info"/></div>
            }
        </div>
    )
}