import React, {useEffect, useState} from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {makeStyles} from "@material-ui/core/styles";
import {Divider} from "@material-ui/core";
import {useGetQuestionsQuery} from "../../../../../redux/api/Courses/coursesApi";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from '@material-ui/icons/Visibility';
// import EditIcon from "@material-ui/icons/Edit";
// import DeleteIcon from "@material-ui/icons/Delete";
import Create from "../../modals/CreateQuestion";
import Edit from "../../modals/EditQuestion";
import Remove from "../../modals/RemoveQuestion";
import View from "../../modals/ViewQuestion";
import ExplanationVideoView from "../../modals/ViewExplanationVideo";
import Pagination from "@material-ui/lab/Pagination";
import StatusButton from "../../../../../_metronic/_helpers/StatusButton";
import {useChangeQuestionStatusMutation} from "../../../../../redux/api/Courses/coursesApi";
import ViewIcon from "../../../../components/icons/ViewIcon";
import EditIcon from "../../../../components/icons/EditIcon";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import Status from "../Status"

const useStyles = makeStyles({
    wrapper: {
        height: " calc(100% - 65px)",
        paddingTop: "12px"
    },
    divider: {
        height: "1px",
        width: "100%"
    },
    card: {
        background: "#FFFFFF",
        boxShadow: "0px 2px 18px rgba(62, 74, 97, 0.12)",
        borderRadius: "12px",
        padding: "12px",
        marginBottom: "8px",
        display: "flex",
        flexDirection: "column"
    },
    cardOperations: {
        display: "flex",
        gap: "32px"
    },
    cardButton: {
        background: "unset",
        border: "unset"
    },
    label: {
        width: "100px"
    }
});

export default function Questions({setRender, renderMethods, videoTitle, videoId, questionPage, setQuestionPage}) {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [questionId, setQuestionId] = useState("");
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [showView, setShowView] = useState(false);
    const [showExplanationVideo, setShowExplanationVideo] = useState(false);
    const [url, setUrl] = useState("");

    const {data, refetch} = useGetQuestionsQuery({
        skip, take, videoId
    });

    const [changeQuestionStatus, {isSuccess}] = useChangeQuestionStatusMutation();

    const handleChangePage = (e, page) => {
        setQuestionPage(page)
        const tempSkip = (page - 1) * take
        setSkip(tempSkip);
        window.scrollTo({top: 0, behavior: "smooth"});
        window.MathJax.typesetPromise([document.getElementsByClassName("mathjax-container")]);
    }

    const addScript = (src) => {
        const script = document.createElement("script");
        script.setAttribute("src", src);
        script.setAttribute("defer", "");
        script.setAttribute("async", "");
        script.setAttribute("data-body", "true");

        document.head.appendChild(script);
        return script
    };

    const changeStatus = (status, id) => {
        changeQuestionStatus({
            id: id,
            status: !status
        })
    };

    useEffect(() => {
        if (isSuccess) {
            refetch()
        }
    }, [isSuccess])

    useEffect(() => {
        const tempScript = document.createElement("script");
        tempScript.textContent = `{
            window.MathJax = {
                tex: {
                    inlineMath: [['$', '$'], ['\\\\(', '\\\\)']],
                    processEscapes: true
                     }
                    };
                }`
        document.head.appendChild(tempScript);
        const script = addScript("https://polyfill.io/v3/polyfill.min.js?features=es6");
        const script2 = addScript("https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js");
        script2.id = "MathJax-script"
        // const script3 = addScript("https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js");
        // const script4 = addScript("https://cdn.jsdelivr.net/npm/mathjax@3/es5/input/asciimath.js");

        return () => {
            document.head.removeChild(script);
            document.head.removeChild(script2);
            document.head.removeChild(tempScript);
            // document.head.removeChild(script4);
            // document.head.removeChild(script3);
        }
    }, []);

    useEffect(() => {
        if (data?.data?.length && window.MathJax.typesetPromise) {
            window.MathJax.typesetPromise([document.getElementsByClassName("mathjax-container")]);
        }
    }, [data?.data, window.MathJax])

    const classes = useStyles();

    return (
        <>
            <div className="col-12 d-flex align-items-center py-4 height-65">
                <button onClick={() => {
                    setRender(renderMethods.videos)
                }} className="button-primary mr-2">
                    <ArrowBackIcon/>
                </button>
                <h1 className="display-6 mb-0">Questions of "{videoTitle}"</h1>
                <div className="ml-auto">
                    <button className="button-primary" onClick={() => setShowCreate(true)}>Add a new question
                    </button>
                </div>
            </div>
            <Divider className={classes.divider}/>
            <div className={`col-12 ${classes.wrapper}`}>
                {data?.data?.map((question, index) => (
                    <div key={index} className={classes.card}>
                        <span>{question.order}.<span className="mathjax-container"
                                                     dangerouslySetInnerHTML={{__html: question.title}}></span></span>
                        <div className={classes.cardOperations}>
                            <Status status={question.status} id={question.id} url={"questions"} />
                            <Tooltip title="Show the question">
                                <button onClick={() => {
                                    setQuestionId(question.id);
                                    setShowView(true);
                                }}
                                        className={classes.cardButton}>
                                    <VisibilityIcon/> Show the question
                                </button>
                            </Tooltip>
                            <Tooltip title="Edit the question">
                                <button onClick={() => {
                                    setQuestionId(question.id);
                                    setShowEdit(true);
                                }}
                                        className={classes.cardButton}>
                                    <EditIcon/> Edit
                                </button>
                            </Tooltip>
                            <Tooltip title="Delete the question">
                                <button onClick={() => {
                                    setQuestionId(question.id);
                                    setShowRemove(true);
                                }}
                                        className={classes.cardButton}>
                                    <DeleteIcon/> Delete
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                ))}
                {data?.count > take && (
                  <div className="py-2">
                      <Pagination onChange={(e, page) => handleChangePage(e, page)}
                                  count={Math.ceil(data?.count / take)} page={questionPage}/>
                  </div>
                )}
                {showCreate && (
                    <Create show={showCreate} onHide={setShowCreate} refetch={refetch} totalCount={data?.count}
                            videoId={videoId}/>
                )}
                {showEdit && (
                    <Edit show={showEdit} onHide={setShowEdit} questionId={questionId} refetch={refetch}
                          totalCount={data.count}/>
                )}
                {showRemove && (
                    <Remove show={showRemove} onHide={setShowRemove} refetch={refetch} questionId={questionId}/>
                )}
                {showView && (
                    <View show={showView} onHide={setShowView} questionId={questionId}
                          setShowExplanationVideo={setShowExplanationVideo}/>
                )}
                {showExplanationVideo && (
                    <ExplanationVideoView show={showExplanationVideo} onHide={setShowExplanationVideo}
                                          questionId={questionId}/>
                )}
            </div>
        </>
    )
}