import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import {useRemoveVideoMutation} from "../../../../redux/api/Courses/coursesApi";

const useStyles = makeStyles((theme) => ({
    textarea: {
        width: "100% !important",
        height: "100px !important",
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    warning: {
        color: "red",
        fontSize: "16px",
        marginTop: "10px"
    },
    title: {
        fontSize: "17px",
    }
}));

export default function Remove(props) {
    const classes = useStyles();
    const { onHide, show, videoTitle, videoId, refetch, skip, take, sortField, orderBy, onFilter, filterInputs } = props;

    const [removeVideo, {isLoading, error, isSuccess}] = useRemoveVideoMutation();


    useEffect(() => {
        if (isSuccess) {
            refetch();
            onHide();
        }

    }, [isLoading]);

    const onDeleteUser = (e) => {
        e.preventDefault();
        removeVideo(videoId)
    };




    return (
        <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
            <form onSubmit={onDeleteUser}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className={`row ${classes.title}`}>Are you sure to delete "{videoTitle}" ?</div>
                        <p className={classes.warning}>Removing Video is irreversible. By removing it you will also remove related
                            Video from stream server, Questions, its videos. Also any student who bought the course
                            can not watch it!</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" type="submit">Delete</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );

}
