import React, {useEffect, useRef, useMemo, useState} from 'react';
import {Modal, Button} from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import {useFormik} from "formik";
import * as Yup from "yup";
import ReactQuill, {Quill} from 'react-quill';
import BlotFormatter from "quill-blot-formatter"
import 'react-quill/dist/quill.snow.css';
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PlusIcon from "@material-ui/icons/Add"
import {Tooltip, Checkbox, CircularProgress} from "@material-ui/core";
import Reader from "../../../../Courses/components/Reader"
import axios from "axios";
import {v4 as uuid} from "uuid";
import {useSelector} from "react-redux";
import {
    useGetQuestionByIdQuery,
    useUpdateQuestionMutation
} from "../../../../../../redux/api/TestBundles/testBundlesApi";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default function EditQuestion({show, onHide, totalCount, questionId}) {
    // TODO: find the best practise for registering BlotFormatter without getting an error on console.

    Quill.register('modules/blotFormatter', BlotFormatter);
    Quill.debug('error')

    const editData = useGetQuestionByIdQuery(questionId)
    const [updateQuestion, {isSuccess, isLoading}] = useUpdateQuestionMutation();

    const formRef = useRef();
    const {token} = useSelector(({user}) => ({token: user.token}));


    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [orderList, setOrderList] = useState([]);
    const [currentOrder, setCurrentOrder] = useState("");
    const [questionVariants, setQuestionVariants] = useState([
        {
            variant: "",
            order: 1,
            isAnswer: true
        }
    ]);
    const [openQuestionVariants, setOpenQuestionVariants] = useState([
        {
            variant: "",
            order: 1,
            isAnswer: true
        }
    ])

    const addVariant = () => {
        if (isOpen) {
            let index = openQuestionVariants.length - 1;
            let order = index === -1 ? 1 : openQuestionVariants[index].order + 1
            let tempState = [...openQuestionVariants, {
                variant: "",
                order,
                isAnswer: true
            }];
            setOpenQuestionVariants(tempState)
        } else {
            let index = questionVariants.length - 1;
            let order = index === -1 ? 1 : questionVariants[index].order + 1
            let tempState = [...questionVariants, {
                variant: "",
                order,
                isAnswer: false
            }];
            setQuestionVariants(tempState)
        }
    };

    const removeVariant = (index) => {
        if (isOpen) {
            let tempState = openQuestionVariants.filter(item => item.order !== index)
            setOpenQuestionVariants(tempState);
        } else {
            let tempState = questionVariants.filter(item => item.order !== index)
            setQuestionVariants(tempState);
        }
    };

    const chooseAnswer = (ObjIndex) => {
        let correctAnswerIndex = questionVariants.findIndex(item => item.isAnswer === true);

        if (ObjIndex !== correctAnswerIndex) {
            let tempState = questionVariants.map((variant, idx) => {
                if (idx === ObjIndex) {
                    return {
                        ...variant, isAnswer: true
                    }
                } else {
                    return {
                        ...variant, isAnswer: false
                    }
                }
            });

            setQuestionVariants(tempState)
        }
    }

    const handleChangeVariant = (content, delta, source, editor, index) => {
        let tempState = questionVariants.map((variant, idx) => {
            if (idx === index) {
                return {
                    ...variant, variant: content
                }
            } else {
                return variant
            }
        });
        setQuestionVariants(tempState);
        window.MathJax.typesetPromise([document.getElementsByClassName("mathjax-container")]);
    };

    const handleChangeOpenVariant = (e, index) => {
        e.preventDefault();
        e.persist();

        setOpenQuestionVariants(prevState => prevState.map((item, idx) => {
            if(index === idx) {
                return {
                    ...item, variant: e.target.value
                }
            } else {
                return item
            }
        }))
    }


    const handleTitle = (content, delta, source, editor) => {
        setTitle(content)
        window.MathJax.typesetPromise([document.getElementsByClassName("mathjax-container")]);

    };


    const handleChange = (e) => {
        setIsOpen(val => !val)
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const body = {
            id: questionId,
            title,
            isOpen: isOpen,
            order: currentOrder,
            testBundleQuestionVariants: isOpen ? openQuestionVariants : questionVariants,
        };

        updateQuestion(body)
    };


    const modules = {
        toolbar: {
            container: [
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                ['bold', 'italic', 'underline'],
                [{'list': []}],
                [{'align': []}],
                ['clean'],
                [{'color': []}],
                ['image'],
            ],
        },
        blotFormatter: {}
    };

    useEffect(() => {
        if (isSuccess) {
            onHide(false);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (totalCount) {
            let tempOrder = []
            for (let i = 1; i <= totalCount; i++) {
                tempOrder.push(i)
            }
            setOrderList(tempOrder)
        }
    }, []);

    useEffect(() => {
        if (editData.isSuccess) {
            const {title, isOpen, testBundleQuestionVariants, order} = editData.data
            setTitle(title);
            setIsOpen(isOpen);
            setCurrentOrder(order);
            if (isOpen) {
                setOpenQuestionVariants([...testBundleQuestionVariants]);
            } else {
                setQuestionVariants([...testBundleQuestionVariants])
            }
        }
    }, [editData.isSuccess]);

    useEffect(() => {
        if (title) {
            window.MathJax.typesetPromise([document.getElementsByClassName("mathjax-container")]);
        }
    }, [title]);

    return (
        <Modal size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title id="create">Edit the question</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit} ref={formRef}>
                <Modal.Body>
                    {editData.isLoading
                        ? <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34A9FC"}}/>
                        </div>
                        : <div className="col-12 pl-4 pr-4">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <InputLabel htmlFor="title">Title input</InputLabel>
                                        <ReactQuill
                                            modules={modules}
                                            style={{width: "100%", height: "100%"}}
                                            theme="snow"
                                            name="title"
                                            value={title}
                                            onChange={(content, delta, source, editor) => handleTitle(content, delta, source, editor)}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <InputLabel htmlFor="title">Title output</InputLabel>

                                        <Reader value={title}/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={isOpen}
                                                onChange={handleChange}
                                                color="secondary"
                                            />
                                        }
                                        label={isOpen ? "Question is open" : "Question is not open"}
                                        className="ml-auto mb-0"
                                    />
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    {isOpen
                                        ? openQuestionVariants?.map((variant, index) => (
                                            <div key={index} className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                                <InputLabel htmlFor="variant">Variant {variant.order} input</InputLabel>
                                                <div className="w-100">
                                                    <TextField
                                                    value={variant.variant}
                                                    onChange={(e) => handleChangeOpenVariant(e, index)}/>
                                                </div>
                                                <Button onClick={() => removeVariant(variant.order)} variant="danger"
                                                        className="mt-2">Remove</Button>
                                            </div>
                                        ))
                                        : questionVariants?.map((variant, index) => (
                                            <div key={index} className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                                <InputLabel htmlFor="title">Variant {variant.order} input</InputLabel>
                                                <ReactQuill
                                                    modules={modules}
                                                    style={{width: "100%", height: "100%"}}
                                                    value={questionVariants[index].variant}
                                                    onChange={(content, delta, source, editor) => handleChangeVariant(content, delta, source, editor, index)}
                                                />
                                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                                    <InputLabel
                                                        htmlFor="title">Variant {variant.order} output</InputLabel>
                                                    <div className="mathjax-container w-100"
                                                         dangerouslySetInnerHTML={{__html: questionVariants[index].variant}}></div>
                                                </div>
                                                <div
                                                    className="w-100 d-flex justify-content-between align-items-center">
                                                    <Button
                                                        onClick={() => removeVariant(variant.order)}
                                                        className="mt-2"
                                                        variant="danger">
                                                        Remove
                                                    </Button>
                                                    <div><FormControlLabel
                                                        control={<Checkbox checked={questionVariants[index].isAnswer}
                                                                           onChange={() => chooseAnswer(index)}/>}
                                                        label="Is correct"/></div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <Tooltip title="Add a variant">
                                            <Button onClick={addVariant}><PlusIcon/></Button>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <FormControl className="w-100">
                                        <InputLabel id="order">Order</InputLabel>
                                        <Select
                                            labelId="order"
                                            id="order"
                                            onChange={(e) => setCurrentOrder(e.target.value)}
                                            value={currentOrder}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {orderList.map((item, index) => <MenuItem key={index}
                                                                                      value={item}>{item}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button disabled={isLoading} type="submit" variant="info" className={`px-9`}>
                        Update
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}