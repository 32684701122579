import React, {useEffect} from "react";
import {Button, Modal} from "react-bootstrap";
import {useDeleteLessonGroupQuizMutation} from "../../../../../../redux/api/Groups/groupsApi";

export default function DeleteExam({show, onHide, quizId, toggleEdit}) {
    const [deleteLessonGroupQuiz, {isLoading, isSuccess}] = useDeleteLessonGroupQuizMutation();


    function handleDelete(e) {
        e.preventDefault();
        deleteLessonGroupQuiz(quizId);
    }

    useEffect(() => {
        if(isSuccess) {
            onHide(false);
            toggleEdit()
        }
    }, [isSuccess])

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
            <form onSubmit={handleDelete}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="classes.title">Are you sure to delete?
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)} disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button variant="danger" type="submit" disabled={isLoading}>Delete</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}