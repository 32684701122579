import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    studentsState: [],
}

export const studentsSlice = createSlice({
    initialState,
    name: "students",
    reducers: {
        setStudents: (state, action) => {
            state.studentsState = action.payload
        }
    }
})

export default studentsSlice.reducer;
export const {setStudents} = studentsSlice.actions