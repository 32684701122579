import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M5.336 8h5.333M8 10.667V5.334M6.003 14.667h4c3.333 0 4.666-1.333 4.666-4.666V6c0-3.334-1.333-4.667-4.666-4.667h-4c-3.334 0-4.667 1.333-4.667 4.667v4c0 3.333 1.333 4.666 4.667 4.666z"
            ></path>
        </svg>
    );
}

export default Icon;
