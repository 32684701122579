import {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import WalletIcon from "../../../../../../components/icons/WalletIcon";
import {useUpdateLessonLineMutation} from "../../../../../../../redux/api/Groups/groupsApi";


export default function WalletButton(props) {
    const [isPaid, setIsPaid] = useState(props.isPaid);

    const [updateLessonLine, {isSuccess, isLoading}] = useUpdateLessonLineMutation();

    const onUpdateLessonLine = () => {
        const body = {
            id: props.id,
            paid: !isPaid
        };
        updateLessonLine(body)
    }

    const useStyles = makeStyles({
        button: props => ({
            width: "28px",
            height: "28px",
            background: isPaid ? "#E6F6E6" : "#E92939",
            opacity: "0.8",
            borderRadius: "4px"
        }),
        icon: props => ({
            "& svg": {
                color: isPaid ? "#5BC35B" : "#FFFFFF"
            }
        })
    })

    const classes = useStyles(props);

    useEffect(() => {
        if(isSuccess) {
            setIsPaid(prevState => !prevState)
        }
    }, [isSuccess])

    useEffect(() => {
        setIsPaid(props.isPaid)
    }, [props.isPaid])

    return (
        <Tooltip title={isPaid ? "Set as not paid" : "Set as paid"}>
            <button onClick={onUpdateLessonLine} disabled={isLoading} className={classes.button}><div className={classes.icon}><WalletIcon/></div></button>
        </Tooltip>
    )
}