import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {useGetGroupsQuery, useMoveStudentMutation} from "redux/api/Groups/groupsApi";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import Loading from "app/components/Loading";

export default function ChangeGroup({onHide, show, groupId, studentId}) {
    const [selectedGroup, setSelectedGroup] = useState(groupId);

    const {data, isLoading} = useGetGroupsQuery();

    const [moveStudent, {isSuccess, isLoading: isMutating, error}] = useMoveStudentMutation();

    function handleChange(e) {
        setSelectedGroup(e.target.value)
    }

    function handleMoveStudent(e) {
        e.preventDefault();
        const body = {
            lessonGroupId: groupId,
            newLessonGroupId: selectedGroup,
            studentId
        }
        moveStudent(body)
    }

    useEffect(() => {
        if (isSuccess) {
            onHide(false);
        }
    }, [isSuccess])

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="changeGroup" centered>
            <form onSubmit={handleMoveStudent}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Change group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                            {isLoading ? (
                                <Loading/>
                            ) : (
                                <FormControl className="w-100">
                                    <InputLabel id="roleId">Groups</InputLabel>
                                    <Select
                                        labelId="group"
                                        name="group"
                                        id="group"
                                        value={selectedGroup}
                                        onChange={handleChange}
                                        className="w-100"
                                    >
                                        {data?.lessonGroups?.data?.map(item => (
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            {error?.data?.message && (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {error.data.message}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={isMutating} variant="light" onClick={() => onHide(false)}>
                        Cancel
                    </Button>
                    <Button disabled={isMutating} variant="primary" type="submit">Update</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );

}
