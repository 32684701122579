import { createApi } from '@reduxjs/toolkit/query/react';
import { setUser } from '../../features/User/userSlice';
import FetchBase from '../FetchBase';
import {booksApi} from "../Books/booksApi";


export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: FetchBase,
    tagTypes: ['User'],
    endpoints: (builder) => ({
        getMe: builder.query({
            query(token) {
                return {
                    url: 'users/profile',
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
            transformResponse: (result) => result,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    const isAdmin =  data?.userRoles?.find(role => role.roleId === 1);
                    if(isAdmin) {
                        await dispatch(booksApi.endpoints.getUndeliveredOrders.initiate())
                    }
                    dispatch(setUser(data));
                   
                } catch (error) {}
            },
        }),
    }),
});


