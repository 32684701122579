import React, {useState} from "react";
import {useGetPracticeTestSubContentQuery} from "../../../../../redux/api/PracticeTests/practiceTestsApi";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {Divider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import AlertComponent from "../../../../../_metronic/_helpers/AlertComponent";
import Tooltip from "@material-ui/core/Tooltip";
import ViewIcon from "../../../../components/icons/ViewIcon";
import EditIcon from "../../../../components/icons/EditIcon";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import Pagination from "@material-ui/lab/Pagination";
import Create from "./modals/Create";
import Edit from "./modals/Edit";
import Remove from "./modals/Remove";
import Status from "../Status"
import {useUpdatePracticeTestSubContentStatusMutation} from "../../../../../redux/api/PracticeTests/practiceTestsApi";

const useStyles = makeStyles({
    wrapper: {
        height:" calc(100% - 65px)",
        paddingTop: "12px"
    },
    divider: {
        height: "1px",
        width: "100%"
    },
    label: {
        width: "100px"
    }
});

export default function SubContents({setRender, renderMethods,contentId, contentName, subContentId, setSubContentId, subContentName, setSubContentName}) {

    const [updatePracticeTestSubContentStatus] = useUpdatePracticeTestSubContentStatusMutation();

    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [contentPage, setContentPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);

    const {data, isLoading} = useGetPracticeTestSubContentQuery({
        contentId, skip, take
    });

    const classes = useStyles();

    const handleChangePage = (e, page) => {
        setContentPage(page)
        const tempSkip = (page - 1) * take
        setSkip(tempSkip);
        window.scrollTo({top: 0, behavior: "smooth"})
    };

    const changeStatus = (status, id) => {
        updatePracticeTestSubContentStatus({
            id: id,
            status: !status
        })
    };

    return (
       <>
           <div className="col-12 d-flex align-items-center py-4 height-65">
               <button onClick={() => {
                   setRender(renderMethods.content)
               }} className="button-primary mr-2">
                   <ArrowBackIcon/>
               </button>
               <h1 className="display-6 mb-0">Sub contents of "{contentName}"</h1>
               <div className="ml-auto">
                   <button onClick={() => setShowCreate(true)} className="button-primary">Add a new sub content
                   </button>
               </div>
           </div>
           <Divider className={classes.divider}/>
           <div className={`col-12 ${classes.wrapper}`}>
               {isLoading
                   ?  <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34A9FC"}}/>
                   </div>
                   : !isLoading && !data?.data?.length ? <AlertComponent message="There is no content." variant="info"/>
                       : data?.data.map((item, index) => (
                           <div key={index} className="content-card">
                               <h1 className="content-card__title">{item.order}. {item.title}</h1>
                               <div className="content-card__operations">
                                  <Status status={item.status} id={item.id} url={"practice-test-subcontents"}/>
                                   <Tooltip title="Show questions">
                                       <button onClick={() => {
                                           setRender(renderMethods.questions);
                                           setSubContentName(item.title);
                                           setSubContentId(item.id)
                                       }}
                                               className="content-card__button">
                                           <ViewIcon/> Show questions
                                       </button>
                                   </Tooltip>
                                   <Tooltip title="Edit sub content">
                                       <button onClick={() => {
                                           setSubContentName(item.title);
                                           setSubContentId(item.id)
                                           setShowEdit(true)
                                       }}
                                               className="content-card__button" >
                                           <EditIcon/> Edit
                                       </button>
                                   </Tooltip>
                                   <Tooltip title="Delete sub content">
                                       <button onClick={() => {
                                           setSubContentName(item.title);
                                           setSubContentId(item.id)
                                           setShowRemove(true)
                                       }}
                                               className="content-card__button">
                                           <DeleteIcon/> Delete
                                       </button>
                                   </Tooltip>
                               </div>
                           </div>
                       ))
               }
               {data?.count > take && (
                   <div className="py-2">
                       <Pagination onChange={(e, page) => handleChangePage(e, page)}
                                   count={Math.ceil(data?.count / take)} page={contentPage}/>
                   </div>
               )}

               {showCreate && (
                   <Create  show={showCreate} onHide={setShowCreate} contentId={contentId} order={data?.data?.length + 1} />
               )}
               {showEdit && (
                   <Edit totalCount={data?.count} show={showEdit} onHide={setShowEdit}
                         contentId={contentId} subContentId={subContentId}/>
               )}
               {showRemove && (
                   <Remove show={showRemove} onHide={setShowRemove} subContentId={subContentId} subContentName={subContentName} />
               )}
           </div>
       </>
    )
}