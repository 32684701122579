import React, {useState, useEffect} from "react";
import {useGetContentsQuery} from "../../../../../redux/api/Courses/coursesApi";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ViewIcon from "../../../../components/icons/ViewIcon";
import EditIcon from "../../../../components/icons/EditIcon";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import Create from "../../modals/CreateContent";
import Edit from "../../modals/EditContent";
import Remove from "../../modals/RemoveContent";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import AlertComponent from "../../../../../_metronic/_helpers/AlertComponent";
import Tooltip from "@material-ui/core/Tooltip";
import {Divider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import "./Contents.css";
import Pagination from "@material-ui/lab/Pagination";
import Status from "../Status"

export default function Contents({id, renderMethods, setRender, courseName, contentName, setContentName, contentId, setContentId, contentPage, setContentPage}) {

    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);

    const {data, isLoading, refetch} = useGetContentsQuery({
        id, skip, take
    });

    const useStyles = makeStyles({
        wrapper: {
            height:" calc(100% - 65px)",
            paddingTop: "12px"
        },
        divider: {
            height: "1px",
            width: "100%"
        },
        label: {
            width: "100px"
        }
    });

    const handleChangePage = (e, page) => {
        setContentPage(page)
        const tempSkip = (page - 1) * take
        setSkip(tempSkip);
        window.scrollTo({top: 0, behavior: "smooth"})
    };


    const classes = useStyles();

    return (
        <>
            <div className="col-12 d-flex align-items-center py-4 height-65">
                <button onClick={() => {
                    setRender(renderMethods.course)
                }} className="button-primary mr-2">
                    <ArrowBackIcon/>
                </button>
                <h1 className="display-6 mb-0">Contents of "{courseName}"</h1>
                <div className="ml-auto">
                    <button onClick={() => setShowCreate(true)} className="button-primary">Add a new content
                    </button>
                </div>
            </div>
            <Divider className={classes.divider}/>
            <div className={`col-12 ${classes.wrapper}`}>
                {isLoading
                    ?  <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34A9FC"}}/>
                    </div>
                    : !isLoading && !data?.data?.length ? <AlertComponent message="There is no content." variant="info"/>
                        : data?.data.map((item, index) => (
                            <div key={index} className="content-card">
                                <h1 className="content-card__title">{item.order}. {item.title}</h1>
                                <div className="content-card__operations">

                                       <Status status={item.status} id={item.id} url={"contents"} />

                                  <Tooltip title="Show videos">
                                      <button onClick={() => {
                                          setRender(renderMethods.videos);
                                          setContentName(item.title);
                                          setContentId(item.id)
                                      }}
                                              className="content-card__button">
                                          <ViewIcon/> Show videos
                                      </button>
                                  </Tooltip>
                                   <Tooltip title="Edit content">
                                       <button onClick={() => {
                                           setContentId(item.id);
                                           setShowEdit(true)
                                       }}
                                              className="content-card__button" >
                                           <EditIcon/> Edit
                                       </button>
                                   </Tooltip>
                                   <Tooltip title="Delete content">
                                       <button onClick={() => {
                                           setContentId(item.id);
                                           setContentName(item.title);
                                           setShowRemove(true)
                                       }}
                                               className="content-card__button">
                                           <DeleteIcon/> Delete
                                       </button>
                                   </Tooltip>
                                </div>
                            </div>
                        ))
                }
                {data?.count > take && (
                    <div className="py-2">
                        <Pagination onChange={(e, page) => handleChangePage(e, page)}
                                    count={Math.ceil(data?.count / take)} page={contentPage}/>
                    </div>
                )}
                {showCreate && (
                    <Create totalCount={data?.count} show={showCreate} onHide={setShowCreate} itemId={id}
                            refetch={refetch}/>
                )}
                {showEdit && (
                    <Edit totalCount={data?.count} show={showEdit} onHide={setShowEdit} itemId={id}
                          contentId={contentId} refetch={refetch}/>
                )}
                {showRemove && (
                    <Remove show={showRemove} onHide={setShowRemove} contentName={contentName}  contentId={contentId} refetch={refetch}/>
                )}
            </div>
        </>
    )
}