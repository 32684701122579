import { format } from "date-fns";

export const dateConvert = (date, time) => {
  if (date) {
    const datetime = new Date(date);

    if (time) {
      return format(datetime, "dd-MM-yyyy HH:mm:ss");
    } else {
      return format(datetime, "dd-MM-yyyy");
    }
  }
};

export const dateConvertGeneral = (date, time) => {
  if (date) {
    const datetime = new Date(date);

    if (time) {
      return format(datetime, "yyyy-MM-dd HH:mm:ss");
    } else {
      return format(datetime, "yyyy-MM-dd");
    }
  }
};

export const dateCalculation = (date1, date2) => {
  var time2 = date2.getTime();
  var time1 = date1.getTime();

  return Math.floor((time2 - time1) / (24 * 3600 * 1000));
};

export const timeCalculation = (date1, date2) => {
  var time2 = date2.getTime();
  var time1 = date1.getTime();

  return Math.floor(time2 - time1);
};
