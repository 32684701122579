import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, Modal} from "react-bootstrap";
import {TextField, CircularProgress, Checkbox, FormControlLabel} from "@material-ui/core";
import {
    useEditSpecificationMutation,
    useCreateSpecificationMutation
} from "../../../../redux/api/Specifications/specificationsApi";

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
})

export default function Edit({
                                 show,
                                 onHide,
                                 editData = {id: undefined},
                                 isLoading = false,
                                 setSpecificationId
                             }) {
    const [initialValues] = useState({
        name: editData.name || "",
        isFree: editData.isFree ?? false
    })

    const [createSpecification] = useCreateSpecificationMutation();
    const [editSpecification] = useEditSpecificationMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const formData = new FormData();
            editData?.id && formData.append("id", editData.id);
            formData.append("name", values.name);
            formData.append("isFree", `${values.isFree}`);
            try {
                if (editData.id) {
                    await editSpecification(formData)
                } else {
                    await createSpecification(formData);
                }
            } catch (err) {

            } finally {
                setSpecificationId && setSpecificationId(undefined);
                onHide(false);
            }
        }
    })

    return (
        <Modal centered size="md" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {editData.id ? "Edit the specification" : "Create a specification"}
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            {
                                isLoading ? <div className="d-flex justify-content-center"><CircularProgress/></div>
                                    : <>
                                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                            <TextField
                                                id="name"
                                                name="name"
                                                label="name"
                                                className="w-100"
                                                {...formik.getFieldProps("name")}
                                                error={formik.touched.name && formik.errors.name}
                                                disabled={isLoading || formik.isSubmitting}
                                            />
                                            {formik.touched.name && formik.errors.name ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.name}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                            <FormControlLabel
                                                label="Is free"
                                                control={<Checkbox checked={formik.values.isFree}/>}
                                                {...formik.getFieldProps("isFree")}
                                            />
                                            {formik.touched.isFree && formik.errors.isFree ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.isFree}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button disabled={isLoading || formik.isSubmitting} variant="info" type="submit" className={`px-9`}>
                        {editData.id ? "Update" : "Create"}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}