import React, {useState, useEffect} from "react";
import TableCell from "../TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import GroupIcon from '@material-ui/icons/Group';
import AssessmentIcon from "@material-ui/icons/Assessment";
import TickCircle from "app/components/icons/TickCircle";
import AddRowIcon from "app/components/icons/AddRowIcon";
import DeleteIcon from "app/components/icons/DeleteIcon";
import RemarkIcon from "app/components/icons/RemarkIcon";
import WalletButton from "../WalletButton";
import {makeStyles} from "@material-ui/core/styles";
import {
    useCreateLessonLineMutation,
    useCreateLessonMutation,
    useRemoveLessonLineMutation, useUpdateRemarkMutation
} from "redux/api/Groups/groupsApi";

export default function Table({groupItem, id, studentId, showSnackbar, handleRemoveStudent, handleChangeGroup, handleShowPracticeResults}) {
    const [remark, setRemark] = useState(groupItem.remark ? groupItem.remark : "");

    const hasLessonLine = groupItem.student.lessonLines.length > 0;

    const [createLesson, {isLoading: isCreatingLesson}] = useCreateLessonMutation();

    const [createLessonLine, {isLoading: isCreatingLessonLine}] = useCreateLessonLineMutation();

    const [removeLessonLine, {isLoading: isRemovingLessonLine}] = useRemoveLessonLineMutation();

    const [updateRemark, {isLoading: isUpdatingRemark, isSuccess}] = useUpdateRemarkMutation();


    const onCreateLesson = (id, studentId) => {
        createLesson({lessonLineId: id, studentId})
    };

    const onCreateLessonLine = (studentId) => {
        const body = {
            lessonGroupId: id,
            studentId
        }
        createLessonLine(body)
    }

    const onRemoveLessonLine = (studentId, lessonLineId) => {
        removeLessonLine({studentId, lessonLineId})
    };

    const onChangeRemark = (e) => {
        setRemark(e.target.value)
    }

    const onSubmitRemark = (e) => {
        e.preventDefault();
        updateRemark({
            id: groupItem.id,
            remark
        })
    }

    const onRemoveStudent = () => {
        handleRemoveStudent(studentId);
    }

    const onChangeGroup = () => {
        handleChangeGroup(studentId);
    }

    const useStyles = makeStyles({
        tableContainer: {
        },
        tableContainerHeader: {
            display: "flex",
            gap: "16px"
        },
        tableBadgeContainer: {
            display: "flex",
            background: "#34a9fc",
            borderRadius: "12px"
        },
        tableBadge: {
            width: "fit-content",
            padding: "10px 28px",
            background: "#FFC800",
            borderRadius: hasLessonLine ? "12px 12px 0 0" : "12px",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#050A46",

        },
        tableForm: {
            display: "flex",
            gap: "8px",
            flexGrow: "1"
        },
        tableFormInput: {
            padding: "16px",
            border: "unset",
            background: "#F6F6F6",
            borderRadius: "16px",
            flexGrow: "1"
        },
        table: {
            width: "100%",
            boxShadow: "0px 2px 18px rgba(62, 74, 97, 0.12)",
            borderRadius: "0 12px 12px 12px"
        },
        tableHead: {},

        tableHeadCell: {
            background: "#34A9FC",
            padding: "16px 12px",

            "&:last-child": {
                borderRadius: "0 12px 0 0"
            }
        },
        tableHeadText: {
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.035em",
            color: "#FFFFFF",
        },
        tableBody: {},
        tableBodyRow: {
            "&:nth-child(even)": {
                background: "#F6F6F6"
            },

        },
        tableBodyCell: {
            padding: "20px 16px",
            borderRight: "2px solid #42446033",
            position: "relative",
            "&:last-child": {
                border: "unset"
            },
            "&:hover": {
                "& $tableBodyCellButtons": {
                    display: "flex"
                }
            }
        },
        tableBodyCellButtons: {
            height: "100%",
            display: "none",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "absolute",
            top: "0",
            right: "0"
        },
        tableBodyCellButton: {
            background: "unset",
            border: "unset",
        },
        tableBodyText: {
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#424460"

        },
        tableBodyCellOperations: {
            display: "flex",
            gap: "16px",
            justifyContent: "space-evenly"
        },
        tableCheckButton: {
            width: "28px",
            height: "28px",
            background: "#32B432",
            opacity: "0.8",
            borderRadius: "4px"
        },
        tableCheckButtonDisabled: {
            width: "28px",
            height: "28px",
            background: "#a29f9f",
            opacity: "0.8",
            borderRadius: "4px"
        },
        tableAddRowButton: {
            width: "28px",
            height: "28px",
            background: "#34A9FC",
            opacity: "0.8",
            borderRadius: "4px"
        },
        tableDeleteButton: {
            width: "28px",
            height: "28px",
            background: "#E6F6E6",
            opacity: "0.8",
            borderRadius: "4px"
        },
        tableStatusButton: {
            width: "28px",
            height: "28px",
            background: "#E92939",
            opacity: "0.8",
            borderRadius: "4px"
        },
        addLineButton: {
            padding: "12px 18px",
            borderRadius: "12px",
            background: "#34A9FC",
            "& svg": {
                width: "24px",
                height: "24px"
            }
        },
        remarkButton: {
            display: "flex",
            background: "#424460",
            borderRadius: "4px",
            padding: "9px",
            alignSelf: "center"
        },
        deleteButton: {
            display: "flex",
            background: "#E6F6E6",
            borderRadius: "4px",
            padding: "9px",
            alignSelf: "center",
        }
    });

    const classes = useStyles();

    useEffect(() => {
        if (isSuccess) {
            showSnackbar('Remark updated.')
        }
    }, [isSuccess])

    return (
        <div className={classes.tableContainer}>
            <div className={classes.tableContainerHeader}>
                <div className={classes.tableBadgeContainer}>
                    <div className={classes.tableBadge}>
                        <span>{groupItem.student.firstName} {groupItem.student.lastName}</span>
                    </div>
                    {!hasLessonLine && (
                        <Tooltip title="Add row">
                            <button onClick={() => onCreateLessonLine(groupItem.studentId)}
                                    className={classes.addLineButton}>
                                <AddRowIcon/>
                            </button>
                        </Tooltip>
                    )}
                </div>
                <form onSubmit={onSubmitRemark} className={classes.tableForm}>
                    <input type="text" value={remark} onChange={onChangeRemark} className={classes.tableFormInput}/>
                    <Tooltip title="Remark">
                        <button disabled={isUpdatingRemark} className={classes.remarkButton}>
                            <RemarkIcon/>
                        </button>
                    </Tooltip>
                </form>
                <Tooltip title="Show practice results">
                    <button onClick={() => handleShowPracticeResults(studentId)} className={classes.deleteButton}>
                        <AssessmentIcon/>
                    </button>
                </Tooltip>
                <Tooltip title="Change group">
                    <button onClick={onChangeGroup} className={classes.deleteButton}>
                        <GroupIcon/>
                    </button>
                </Tooltip>
                <Tooltip title="Remove student">
                    <button onClick={onRemoveStudent} className={classes.deleteButton}>
                        <DeleteIcon/>
                    </button>
                </Tooltip>
            </div>
            {groupItem.student.lessonLines.length > 0 && (
                <table className={classes.table}>
                    <thead className={classes.tableHead}>
                    <tr>
                        <td className={classes.tableHeadCell}><span
                            className={classes.tableHeadText}>Lesson 1</span></td>
                        <td className={classes.tableHeadCell}><span
                            className={classes.tableHeadText}>Lesson 2</span></td>
                        <td className={classes.tableHeadCell}><span
                            className={classes.tableHeadText}>Lesson 3</span></td>
                        <td className={classes.tableHeadCell}><span
                            className={classes.tableHeadText}>Lesson 4</span></td>
                        <td className={classes.tableHeadCell}><span
                            className={classes.tableHeadText}>Lesson 5</span></td>
                        <td className={classes.tableHeadCell}><span
                            className={classes.tableHeadText}>Lesson 6</span></td>
                        <td className={classes.tableHeadCell}><span
                            className={classes.tableHeadText}>Lesson 7</span></td>
                        <td className={classes.tableHeadCell}><span
                            className={classes.tableHeadText}>Lesson 8</span></td>
                        <td className={classes.tableHeadCell}><span
                            className={classes.tableHeadText}></span>
                        </td>
                    </tr>
                    </thead>
                    <tbody className={classes.tableBody}>
                    {groupItem?.student?.lessonLines.map(line => (
                        <tr key={line.id} className={classes.tableBodyRow}>
                            <TableCell studentId={groupItem.studentId} lessonLineId={line.id} data={line.lessons[0]}/>
                            <TableCell studentId={groupItem.studentId} lessonLineId={line.id} data={line.lessons[1]}/>
                            <TableCell studentId={groupItem.studentId} lessonLineId={line.id} data={line.lessons[2]}/>
                            <TableCell studentId={groupItem.studentId} lessonLineId={line.id} data={line.lessons[3]}/>
                            <TableCell studentId={groupItem.studentId} lessonLineId={line.id} data={line.lessons[4]}/>
                            <TableCell studentId={groupItem.studentId} lessonLineId={line.id} data={line.lessons[5]}/>
                            <TableCell studentId={groupItem.studentId} lessonLineId={line.id} data={line.lessons[6]}/>
                            <TableCell studentId={groupItem.studentId} lessonLineId={line.id} data={line.lessons[7]}/>
                            <td className={classes.tableBodyCell}>
                                <div className={classes.tableBodyCellOperations}>
                                    <Tooltip title="Check">
                                                    <span>
                                                        <button disabled={line.lessons.length === 8 || isCreatingLesson}
                                                                onClick={() => onCreateLesson(line.id, groupItem.studentId)}
                                                                className={line.lessons.length === 8 ? classes.tableCheckButtonDisabled : classes.tableCheckButton}>
                                                        <TickCircle/>
                                                    </button>
                                                    </span>
                                    </Tooltip>
                                    <Tooltip title="Add row">
                                        <button onClick={() => onCreateLessonLine(groupItem.studentId)}
                                                disabled={isCreatingLessonLine} className={classes.tableAddRowButton}>
                                            <AddRowIcon/>
                                        </button>
                                    </Tooltip>

                                    <Tooltip title="Remove row">
                                        <button onClick={() => onRemoveLessonLine(groupItem.studentId, line.id)}
                                                disabled={isRemovingLessonLine} className={classes.tableDeleteButton}>
                                            <DeleteIcon/>
                                        </button>
                                    </Tooltip>
                                    <WalletButton isPaid={line.paid} id={line.id}/>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}

        </div>
    )
}