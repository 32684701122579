import React from "react";
import {Modal} from "react-bootstrap";
import {makeStyles} from "@material-ui/core/styles";
import {useGetACTExamQuery, useGetSATExamQuery} from "redux/api/Students/studentsApi";
import Loading from "app/components/Loading";
import {dateConvert} from "Helpers/dateConvert";

const useStyles = makeStyles({
    container: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr))",
        gap: "8px"
    },
    resultCard: {
        width: "100%",
        borderRadius: "12px",
        background: "#fff",
        boxShadow: "0 2px 18px 0 rgba(62,74,97,.12)",
        padding: "24px",
        display: "flex",
        flexDirection: "column",
    },
    resultCardTitle: {
        color: "#050a46",
        fontSize: "20px",
        fontWeight: "500",
        display: "flex",
        justifyContent: "space-between",
    },
    resultCardRow: {
        display: "flex",
        justifyContent: "space-between",
        color: "#424460",
        fontSize: "16px",
        fontWeight: "500",
    },
    resultCardButton: {
        background: "unset",
        border: "unset",
        color: "#34a9fc",
        textDecoration: "underline",
        marginTop: "auto",
    },
    noResultText: {
        color: "#050a46",
        fontSize: "20px",
        fontWeight: "500",
    }
});

export default function TrialExamResult({onHide, show, setShowMistakes, studentId, setMistakes}) {
    const classes = useStyles();

    const {data: ACT, isLoading: isACTLoading} = useGetACTExamQuery({studentId});
    const {data: SAT, isLoading: isSATLoading} = useGetSATExamQuery({studentId});

    const handleShowMistakes = (mistakes) => () => {
        setShowMistakes(true);
        setMistakes(mistakes)
    }

    return (
        <Modal size='lg' show={show} onHide={onHide} aria-labelledby="examResults" centered>
            <Modal.Header closeButton>
                <Modal.Title id="delete">Exam results</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.container}>
                    {isACTLoading && isSATLoading ?
                        <Loading/>
                        : ACT?.length || SAT?.length ? <>
                                {ACT?.map(act => (
                                    <div key={act.id} className={classes.resultCard}>
                                        <div className={classes.resultCardTitle}>
                                            <span>ACT:</span>
                                            <span>{act.examName}</span>
                                        </div>
                                        <div className={classes.resultCardRow}>
                                            <span>Date:</span>
                                            <span>{act.examDate && dateConvert(act.examDate)}</span>
                                        </div>
                                        <div className={classes.resultCardRow}>
                                            <span>Math result:</span>
                                            <span>{act.mathScore}</span>
                                        </div>
                                        <div className={classes.resultCardRow}>
                                            <span>Reading result:</span>
                                            <span>{act.readingScore}</span>
                                        </div>
                                        <div className={classes.resultCardRow}>
                                            <span>Science result:</span>
                                            <span>{act.scienceScore}</span>
                                        </div>
                                        <div className={classes.resultCardRow}>
                                            <span>English result:</span>
                                            <span>{act.englishScore}</span>
                                        </div>
                                        <div className={classes.resultCardRow}>
                                            <span>Total result:</span>
                                            <span>{act.totalScore}</span>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <button onClick={handleShowMistakes(act.incorrectQuestions)}
                                                    className={classes.resultCardButton}>Show
                                                mistakes
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                {SAT?.map(sat => (
                                    <div key={sat.id} className={classes.resultCard}>
                                        <div className={classes.resultCardTitle}>
                                            <span>SAT:</span>
                                            <span>{sat.examName}</span>
                                        </div>
                                        <div className={classes.resultCardRow}>
                                            <span>Date:</span>
                                            <span>{sat.examDate && dateConvert(sat.examDate)}</span>
                                        </div>
                                        <div className={classes.resultCardRow}>
                                            <span>Math result:</span>
                                            <span>{sat.mathResult}</span>
                                        </div>
                                        <div className={classes.resultCardRow}>
                                            <span>Reading & writing result:</span>
                                            <span>{sat.readingAndWritingResult}</span>
                                        </div>
                                        <div className={classes.resultCardRow}>
                                            <span>Total result:</span>
                                            <span>{sat.totalScore}</span>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <button onClick={handleShowMistakes(sat.incorrectQuestions)}
                                                    className={classes.resultCardButton}>Show
                                                mistakes
                                            </button>
                                        </div>
                                    </div>
                                ))}</>
                            : <div className="d-flex justify-content-center"><span className={classes.noResultText}>There is no result yet</span></div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );

}
