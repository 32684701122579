import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {TextField, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {
    useGetContentScoreQuery,
    useCreateContentScoreMutation,
    useUpdateContentScoreMutation,
    useRemoveContentScoreMutation
} from "../../../../../../redux/api/PracticeTests/practiceTestsApi";

const useStyles = makeStyles({
    wrapper: {
        maxHeight: 500,
        overflowY: "scroll"
    },
    error: {
        color: "#F64E60",
        fontSize: "0.9rem",
        fontWeight: "400"
    }
})


export default function EditScore({show, onHide, contentId}) {
    const classes = useStyles()
    const {data, isLoading, isSuccess, isFetching} = useGetContentScoreQuery(contentId);
    const [createScore, createState] = useCreateContentScoreMutation();
    const [updateScore, updateState] = useUpdateContentScoreMutation();
    const [removeScore, removeState] = useRemoveContentScoreMutation();
    const [newScore, setNewScore] = useState(
        {
            correctAnswerCount: "",
            score: ""
        }
    )
    const [inputData, setInputData] = useState([
        {
            correctAnswerCount: "",
            score: "",
            id: ""
        }
    ]);

    const onChangeNewScore = (e, field) => {
        let tempState = {...newScore};
        if (field === "correctAnswerCount") {
            tempState.correctAnswerCount = e.target.value
        } else if (field === "score") {
            tempState.score = e.target.value
        }
        setNewScore(tempState)
    }

    const onChangeInput = (e, field, index, id) => {
        const isNumber = /^\d+$/.test(e.target.value);
        let obj = {...inputData[index]};
        if (isNumber && field === "correctAnswerCount") {
            obj.correctAnswerCount = Number(e.target.value)
            let tempData = inputData.map((input) => {
                if (input.id === id) {
                    return {
                        ...obj
                    }
                } else {
                    return input
                }
            });
            setInputData(tempData)
        } else if (isNumber && field === "score") {
            obj.score = Number(e.target.value)
            let tempData = inputData.map((input, inputIdx) => {
                if (input.id === id) {
                    return {
                        ...obj
                    }
                } else {
                    return input
                }
            });
            setInputData(tempData)
        }

    }

    const onCreateScore = () => {
        if (newScore.correctAnswerCount && newScore.score) {
            let body = {
                contentId,
                correctAnswerCount: newScore.correctAnswerCount,
                score: newScore.score
            }
            createScore(body);
            setNewScore({
                correctAnswerCount: "",
                score: ""
            })
        }
    }

    const onUpdateScore = (id) => {
        let obj = inputData.find(item => item.id === id);
        let body = {
            id: obj.id,
            correctAnswerCount: obj.correctAnswerCount,
            score: obj.score
        };
        updateScore(body)
    }

    const ondRemoveScore = (id) => {
        removeScore(id)
    }

    useEffect(() => {
        if (isSuccess) {
            setInputData(data.data)
        }
    }, [isLoading]);

    useEffect(() => {
        if (createState.isSuccess && !isFetching) {
            setInputData(data?.data)
        }
    }, [isFetching])

    useEffect(() => {
        if (removeState.isSuccess && !isFetching) {
            setInputData(data?.data)
        }
    }, [isFetching]);

    useEffect(() => {
        if (updateState.isSuccess && !isFetching) {
            setInputData(data?.data)
        }
    }, [isFetching])


    return (
        <Modal size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit score</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading
                    ?
                    <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34A9FC"}}/></div>
                    : <div className={classes.wrapper}>
                        <div className="d-flex flex-wrap">
                            <div className="mr-2">
                                <TextField
                                    placeholder="Correct answer count"
                                    label="Correct answer count"
                                    value={newScore.correctAnswerCount}
                                    onChange={(e) => onChangeNewScore(e, "correctAnswerCount")}
                                />
                            </div>
                            <div className="mr-2">
                                <TextField
                                    placeholder="Score"
                                    label="Score"
                                    value={newScore.score}
                                    onChange={(e) => onChangeNewScore(e, "score")}
                                />
                            </div>
                            <div className="d-flex align-items-center">
                                <Button disabled={createState.isLoading} onClick={onCreateScore}>Create</Button>
                            </div>
                            {/*<Button variant="danger">Delete</Button>*/}
                        </div>
                        {createState.isError && (
                            <div className={classes.error}>
                                {createState.error?.data?.message}
                            </div>
                        )}
                        {inputData.map((input, index) => (
                            <div key={index} className="d-flex flex-wrap mt-2">
                                <div className="mr-2">
                                    <TextField
                                        id={input.id}
                                        placeholder="Correct answer count"
                                        label="Correct answer count"
                                        value={input.correctAnswerCount}
                                        onChange={(e) => onChangeInput(e, "correctAnswerCount", index, input.id)}
                                    />
                                </div>
                                <div className="mr-2">
                                    <TextField
                                        id={input.id}
                                        placeholder="Score"
                                        label="Score"
                                        value={input.score}
                                        onChange={(e) => onChangeInput(e, "score", index, input.id)}
                                    />
                                </div>
                                <div className="d-flex align-items-center">
                                    <Button disabled={updateState.isLoading} onClick={() => onUpdateScore(input.id)}
                                            className="mr-2">Update</Button>
                                    <Button disabled={removeState.isLoading} onClick={() => ondRemoveScore(input.id)}
                                            variant="danger">Delete</Button>
                                </div>
                            </div>
                        ))}
                    </div>
                }

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    onHide(false);
                }} variant="light">Close</Button>
            </Modal.Footer>
        </Modal>
    )
}