import React, {useEffect, useState} from "react";
import {useGetPracticeTestSubContentQuery} from "../../../../../redux/api/PracticeTests/practiceTestsApi";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {Divider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import AlertComponent from "../../../../../_metronic/_helpers/AlertComponent";
import Tooltip from "@material-ui/core/Tooltip";
import ViewIcon from "../../../../components/icons/ViewIcon";
import EditIcon from "../../../../components/icons/EditIcon";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import Pagination from "@material-ui/lab/Pagination";
import Create from "./modals/Create";
import Edit from "./modals/Edit";
import Remove from "./modals/Remove";
import View from "./modals/View";
import {
    useGetQuestionsQuery,
} from "../../../../../redux/api/TestBundles/testBundlesApi";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Status from "../Status"
const useStyles = makeStyles({
    wrapper: {
        height: " calc(100% - 65px)",
        paddingTop: "12px"
    },
    divider: {
        height: "1px",
        width: "100%"
    },
    card: {
        background: "#FFFFFF",
        boxShadow: "0px 2px 18px rgba(62, 74, 97, 0.12)",
        borderRadius: "12px",
        padding: "12px",
        marginBottom: "8px",
        display: "flex",
        flexDirection: "column"
    },
    cardOperations: {
        display: "flex",
        gap: "32px"
    },
    cardButton: {
        background: "unset",
        border: "unset"
    },
    label: {
        width: "100px"
    }
});

export default function Questions({
                                      setRender,
                                      renderMethods,
                                      contentId,
                                      contentName,
                                      setQuestionId,
                                      questionId
                                  }) {


    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showView, setShowView] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [contentPage, setContentPage] = useState(1)
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);

    const {data, isLoading} = useGetQuestionsQuery({
        skip, take, contentId
    });

    const classes = useStyles();

    const handleChangePage = (e, page) => {
        setContentPage(page)
        const tempSkip = (page - 1) * take
        setSkip(tempSkip);
        window.scrollTo({top: 0, behavior: "smooth"})
    };

    const addScript = (src) => {
        const script = document.createElement("script");
        script.setAttribute("src", src);
        script.setAttribute("defer", "");
        script.setAttribute("async", "");
        script.setAttribute("data-body", "true");

        document.head.appendChild(script);
        return script
    };

    useEffect(() => {
        const tempScript = document.createElement("script");
        tempScript.textContent = `{
            window.MathJax = {
                tex: {
                    inlineMath: [['$', '$'], ['\\\\(', '\\\\)']],
                    processEscapes: true
                     }
                    };
                }`
        document.head.appendChild(tempScript);
        const script = addScript("https://polyfill.io/v3/polyfill.min.js?features=es6");
        const script2 = addScript("https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js");
        script2.id = "MathJax-script"

        return () => {
            document.head.removeChild(script);
            document.head.removeChild(script2);
            document.head.removeChild(tempScript);
        }
    }, []);

    useEffect(() => {
        if (data?.data?.length && window.MathJax.typesetPromise) {
            window.MathJax.typesetPromise([document.getElementsByClassName("mathjax-container")]);
        }
    }, [data?.data, window.MathJax])

    return (
        <>
            <div className="col-12 d-flex align-items-center py-4 height-65">
                <button onClick={() => {
                    setRender(renderMethods.content)
                }} className="button-primary mr-2">
                    <ArrowBackIcon/>
                </button>
                <h1 className="display-6 mb-0">Questions of "{contentName}"</h1>
                <div className="ml-auto">
                    <button onClick={() => setShowCreate(true)} className="button-primary">Add a new question
                    </button>
                </div>
            </div>
            <Divider className={classes.divider}/>
            <div className={`col-12 ${classes.wrapper}`}>
                {isLoading
                    ? <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34A9FC"}}/>
                    </div>
                    : !isLoading && !data?.data?.length ?
                        <AlertComponent message="There is no content." variant="info"/>
                        : data?.data.map((question, index) => (
                            <div key={index} className={classes.card}>
                        <span>{question.order}.<span className="mathjax-container"
                                                     dangerouslySetInnerHTML={{__html: question.title}}></span></span>
                                <div className={classes.cardOperations}>
                                    <Status status={question.status} id={question.id} url={"test-bundle-questions"}/>
                                    <Tooltip title="Show the question">
                                        <button onClick={() => {
                                            setQuestionId(question.id);
                                            setShowView(true);
                                        }}
                                                className={classes.cardButton}>
                                            <VisibilityIcon/> Show the question
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Edit the question">
                                        <button onClick={() => {
                                            setQuestionId(question.id);
                                            setShowEdit(true);
                                        }}
                                                className={classes.cardButton}>
                                            <EditIcon/> Edit
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Delete the question">
                                        <button onClick={() => {
                                            setQuestionId(question.id);
                                            setShowRemove(true);
                                        }}
                                                className={classes.cardButton}>
                                            <DeleteIcon/> Delete
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        ))
                }
                {data?.count > take && (
                    <div className="py-2">
                        <Pagination onChange={(e, page) => handleChangePage(e, page)}
                                    count={Math.ceil(data?.count / take)} page={contentPage}/>
                    </div>
                )}

                {showCreate && (
                    <Create show={showCreate} onHide={setShowCreate} contentId={contentId}
                            totalCount={data?.count}/>
                )}
                {showEdit && (
                    <Edit totalCount={data?.count} show={showEdit} onHide={setShowEdit}
                          questionId={questionId}/>
                )}
                {showRemove && (
                    <Remove show={showRemove} onHide={setShowRemove} questionId={questionId}/>
                )}
                {showView && (
                    <View show={showView} onHide={setShowView} questionId={questionId} />
                )}
            </div>
        </>
    )
}