import React, {useState} from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
import UsersTable from "./components/UsersTable";
import Create from "./modals/Create";
import Edit from "./modals/Edit";
import ChangePassword from "./modals/ChangePassword";
import Filter from "./modals/Filter";
import Remove from "./modals/Remove";
import {useGetUsersQuery} from "../../../redux/api/Users/usersApi";

export default function Users() {

    const [showFilter, setShowFilter] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [skip] = useState(0);
    const [take] = useState(10);
    const [order, setOrder] = useState(true);
    const [field, setField] = useState("firstName");
    const [userId, setUserId] = useState("");
    const [filterInputs, setFilterInputs] = useState({
        firstName: "",
        lastName: "",
        email: ""
    })

    const {data, isLoading, refetch} = useGetUsersQuery({
        skip, take, order, field, ...filterInputs
    })

    const handleChange = () => {
        setShowFilter(val => !val)
    };

    const filterInputsUpdate = (e) => {
        let tempState = filterInputs;
        tempState = {...tempState, [e.target.name]: e.target.value}
        setFilterInputs(tempState)
    }

    return (
        <div className="row bg-white rounded h-100">
            <div className="col-12 d-flex align-items-center py-4 height-65">
                <h1 className="display-4 mb-0">Admins & Teachers</h1>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showFilter}
                            onChange={handleChange}
                            color="secondary"
                        />
                    }
                    label={showFilter ? "Hide filter" : "Show filter"}
                    className="ml-auto mb-0"
                />
            </div>
            <Divider className="w-100"/>
            <UsersTable
                setShowCreate={setShowCreate}
                setShowEdit={setShowEdit}
                setShowChangePassword={setShowChangePassword}
                setShowRemove={setShowRemove}
                data={data}
                isLoading={isLoading}
                count={data}
                field={field}
                order={order}
                setUserId={setUserId}
                setField={setField}
                setOrder={setOrder}
            />

            {showCreate && (
                <Create show={showCreate} onHide={setShowCreate} refetch={refetch}/>
            )}
            {showEdit && (
                <Edit show={showEdit} onHide={setShowEdit} refetch={refetch} userId={userId}/>
            )}
            {showChangePassword && (
                <ChangePassword show={showChangePassword} onHide={setShowChangePassword} refetch={refetch} userId={userId}/>
            )}
            {showFilter && (
                <Filter show={showFilter} onHide={setShowFilter} filterInputs={filterInputs}
                        setFilterInputs={setFilterInputs} filterInputsUpdate={filterInputsUpdate}/>
            )}
            {showRemove && (
                    <Remove show={showRemove} onHide={setShowRemove} userId={userId} refetch={refetch}/>
                )
            }
        </div>
    )
}