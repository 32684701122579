import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import {  useSelector, shallowEqual } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { AuthPage } from "./modules/Auth";


export function Routes() {
  const { user } = useSelector((state) => state?.user, shallowEqual)

  return (
    <Switch>
      {!user ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <Redirect from="/auth" to="/dashboard" />
      )}

      <Route path="/error" component={ErrorsPage} />

      {!user ? (
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
