import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField/index";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {useResetUserPasswordMutation} from "../../../../redux/api/Users/usersApi"
import { useFormik } from "formik";
import * as Yup from "yup";

const initialValues = {
    newPassword: "",
    newPasswordConfirm: "",
};

export default function ChangePassword(props) {
    const { onHide, userId, show } = props;

    const [values, setValues] = useState({
        newPassword: "",
        newPasswordConfirm: "",
        showOldPassword: false,
        showNewPassword: false,
        showRePassword: false,
    });

    const PasswordSchema = Yup.object().shape({
        newPassword: Yup.string()
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                "Password must consist of an uppercase letter, a lowercase letter, a number and a character."
            )
            .required("Password is required"),
        newPasswordConfirm: Yup.string()
            .when("newPassword", {
                is: (val) => (val && val.length > 0),
                then: Yup.string().oneOf(
                    [Yup.ref("newPassword")],
                    "Both password need to be the same"
                ),
            })
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                "Password must consist of an uppercase letter, a lowercase letter, a number and a character."
            )
            .required("Confirm Password is required"),
    });


    const [resetUserPassword, { isLoading, error }] =
        useResetUserPasswordMutation();


    const handleClickShowPassword = (key) => {
        setValues({ ...values, [key]: !values[key] });
    };

    const getInputClasses = (filedName) => {
        if (formik.touched[filedName] && formik.errors[filedName]) {
            return true;
        }
        if (formik.touched[filedName] && !formik.errors[filedName]) {
            return false;
        }

        return false;
    };

    const formik = useFormik({
        initialValues,
        validationSchema: PasswordSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                const changeUser = {
                    id: userId,
                    password: values.newPassword,
                    confirmPassword: values.newPasswordConfirm,

                };
                await resetUserPassword(changeUser);
            } catch (err) {

            } finally {
                onHide(false);
            }
        },
    });

    return (
        <Modal
            onHide={onHide}
            show={show}
            size="sm"
            aria-labelledby="edit"
            centered
            className="modal-center pr-0"
        >
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="changePassword">Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="newPassword"
                                        className="w-100"
                                        type={values.showNewPassword ? "text" : "password"}
                                        label="New password"
                                        value={values.newPassword}
                                        error={getInputClasses("newPassword")}
                                        {...formik.getFieldProps("newPassword")}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Toggle password visibility"
                                                        onClick={() =>
                                                            handleClickShowPassword("showNewPassword")
                                                        }
                                                    >
                                                        {values.showNewPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                {formik.touched.newPassword &&
                                formik.errors.newPassword ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.newPassword}
                                        </div>
                                    </div>
                                ) : null}
                            </div>

                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="newPasswordConfirm"
                                        className="w-100"
                                        type={values.showRePassword ? "text" : "password"}
                                        label="New Password Confirm"
                                        // value={values.newPassword}
                                        error={getInputClasses("newPasswordConfirm")}
                                        {...formik.getFieldProps("newPasswordConfirm")}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Toggle password visibility"
                                                        onClick={() =>
                                                            handleClickShowPassword("showRePassword")
                                                        }
                                                    >
                                                        {values.showRePassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                {formik.touched.newPasswordConfirm && formik.errors.newPasswordConfirm ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.newPasswordConfirm}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                            formik.resetForm();
                        }}
                        disabled={isLoading}
                    >
                        Close
                    </Button>
                    <Button type="submit" disabled={isLoading} className={`px-9`}>
                        Update
                        {isLoading && <span className="ml-3 spinner spinner-white"></span>}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
