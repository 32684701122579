import React from "react";
import {FormControlLabel, Switch} from "@material-ui/core";
import {useUpdateBookOrderMutation} from "../../../../../redux/api/Books/booksApi";

export default function StatusSwitch({delivered, id}) {
    const [updateOrder, {isLoading}] = useUpdateBookOrderMutation();

    function onChangeDeliveryStatus() {
        updateOrder({delivered: !delivered, id})
    }


    return (
        <FormControlLabel
            control={
                <Switch
                    color="secondary"
                    checked={delivered}
                    onChange={onChangeDeliveryStatus}
                    disabled={isLoading}
                />
            }
            label={delivered ? "Delivered" : "Undelivered"}
        />
    )
}