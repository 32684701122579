import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.2"
                d="M12.024 9.033c-.28.273-.44.666-.4 1.086.06.72.72 1.247 1.44 1.247h1.267v.793a2.512 2.512 0 01-2.507 2.507h-6.74c.207-.173.387-.387.527-.627.246-.4.386-.873.386-1.373a2.666 2.666 0 00-4.333-2.08V7.673a2.512 2.512 0 012.507-2.507h7.653a2.512 2.512 0 012.507 2.507v.96h-1.347c-.373 0-.713.146-.96.4z"
            ></path>
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.2"
                d="M1.664 8.273V5.226c0-.793.487-1.5 1.227-1.78l5.293-2c.827-.313 1.713.3 1.713 1.187v2.533M15.037 9.313v1.373a.684.684 0 01-.667.68h-1.307c-.72 0-1.38-.527-1.44-1.246-.04-.42.12-.814.4-1.087.247-.254.587-.4.96-.4h1.387a.684.684 0 01.667.68zM4.664 8h4.667"
            ></path>
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.2"
                d="M5.997 12.667c0 .5-.14.973-.386 1.373-.14.24-.32.453-.527.627-.467.42-1.08.666-1.753.666-.974 0-1.82-.52-2.28-1.293a2.614 2.614 0 01-.387-1.373c0-.84.387-1.594 1-2.08a2.666 2.666 0 014.333 2.08z"
            ></path>
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.2"
                d="M2.29 12.667l.66.66 1.42-1.313"
            ></path>
        </svg>
    );
}

export default Icon;
