import React, {useEffect, useRef, useState} from "react";
import {Modal, Button} from "react-bootstrap";
import {
    useGetPracticeTestByIdQuery,
    useUpdatePracticeTestMutation,
    useGetCategoriesQuery
} from "../../../../redux/api/PracticeTests/practiceTestsApi";
import {useGetSpecificationsQuery} from "../../../../redux/api/Courses/coursesApi";
import {useFormik} from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ReactSelect from "../../UI/ReactMultiSelect";
import Input from "@material-ui/core/Input";
import Preview from "../../Courses/components/Preview/Preview";
import {CircularProgress} from "@material-ui/core";
import Button2 from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ReactQuill from "../../../components/ReactQuill";

export default function Edit({show, onHide, practiceTestId}) {
    const [imageSrc, setImageSrc] = useState("");
    const [file, setFile] = useState("");
    const [blob, setBlob] = useState();
    const [specificationsData, setSpecificationsData] = useState([]);
    const [multiSelectValue, setMultiSelectValue] = useState([]);

    const practiceTest = useGetPracticeTestByIdQuery(practiceTestId);
    const categories = useGetCategoriesQuery();
    const specifications = useGetSpecificationsQuery();
    const [updatePracticeTest, {isSuccess, error, isLoading}] = useUpdatePracticeTestMutation();
    const formRef = useRef(null);

    const initialValues = {
        title: "",
        slug: "",
        shortDescription: "",
        description: "",
        categoryId: "",
        price: "",
        discount: "",
        itemSpecifications: [],
        expireYear: "",
        expireMonth: "",
        expireDay: "",
        applyExpireToAll: false,
        isFree: false
    };

    const validationSchema = Yup.object({
        title: Yup.string().required("Please enter a title."),
        slug: Yup.string().required("Please enter a slug."),
        shortDescription: Yup.string().required("Please enter a short description."),
        description: Yup.string().required("Please enter a description."),
        categoryId: Yup.string().required("Please enter a category."),
        price: Yup.string().required(),
        expireYear: Yup.number().min(0).max(255).nullable(true),
        expireMonth: Yup.number().min(0).max(255).nullable(true),
        expireDay: Yup.number().min(0).max(255).nullable(true),
    });

    const handleSubmit = (values) => {
        const asset = new FormData();
        const {
            title,
            slug,
            shortDescription,
            description,
            categoryId,
            price,
            discount,
            expireDay,
            expireMonth,
            expireYear,
            applyExpireToAll,
            isFree
        } = values
        asset.append("id", practiceTestId);
        asset.append("title", title);
        asset.append("slug", slug);
        asset.append("shortDescription", shortDescription);
        asset.append("description", description);
        asset.append("categoryId", categoryId);
        asset.append("price", price);
        asset.append("isFree", isFree);
        !isFree && asset.append("discount", discount);
        !isFree && expireDay && asset.append("expireDay", expireDay);
        !isFree && expireMonth && asset.append("expireMonth", expireMonth);
        !isFree && expireYear && asset.append("expireYear", expireYear);
        asset.append("applyExpireToAll", applyExpireToAll);
        blob && asset.append("coverFile", blob)
        formik.values.itemSpecifications.forEach((spec, index) => asset.append(`itemSpecifications[${index}].specificationId`, spec.specificationId))
        updatePracticeTest(asset);
    };
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, {setErrors}) => {
            if(!values.isFree && values.price <= 0 && !(values.expireDay || values.expireYear || values.expireMonth)) {
                setErrors({
                    price: 'Price has to be greater than 0',
                    expireDay: "At least one of date field has to be filled",
                    expireMonth: "At least one of date field has to be filled",
                    expireYear: "At least one of date field has to be filled",
                })
                return
            }
            handleSubmit(values);
        }
    });

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    const handleCoverImage = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setBlob(file)
        setFile(e.target.value);

        reader.onloadend = () => {
            setImageSrc(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const changeSpecifications = (e) => {
        setMultiSelectValue(e)
        let tempSpecifications = e.map(item => ({specificationId: item.value}))
        formik.setFieldValue("itemSpecifications", tempSpecifications);
    }

    const handleChangeDate = (e) => {
        e.preventDefault();
        const field = e.target.name
        const {value} = e.target;
        const regex = /^[0-9]*$/;
        if (regex.test(value.toString())) {
            formik.setFieldValue(field, value)
        }

    }

    useEffect(() => {

        if (specifications.isSuccess) {
            let specificationsOptions = specifications.data.map(item => ({
                value: item.id,
                label: item.name
            }));
            setSpecificationsData(specificationsOptions)
        }
    }, [specifications.isLoading]);

    useEffect(() => {
        if (practiceTest?.isSuccess) {
            const {
                title,
                slug,
                shortDescription,
                description,
                categoryId,
                price,
                discount,
                itemSpecifications,
                coverImage,
                expireYear,
                expireMonth,
                expireDay,
                isFree
            } = practiceTest.data
            formik.setFieldValue("title", title);
            formik.setFieldValue("slug", slug);
            formik.setFieldValue("shortDescription", shortDescription);
            formik.setFieldValue("description", description);
            formik.setFieldValue("categoryId", categoryId);
            formik.setFieldValue("price", price);
            formik.setFieldValue("discount", discount);
            formik.setFieldValue("itemSpecifications", itemSpecifications);
            formik.setFieldValue("expireYear", expireYear);
            formik.setFieldValue("expireMonth", expireMonth);
            formik.setFieldValue("expireDay", expireDay);
            formik.setFieldValue("isFree", isFree);

            setImageSrc(coverImage);
        }
    }, [practiceTest.isLoading]);

    useEffect(() => {
        if (specificationsData.length) {
            let indexArr = formik.values.itemSpecifications.map(item => item.specificationId)
            let tempData = specificationsData.filter(specification => indexArr.includes(specification.value))
            setMultiSelectValue(tempData);
        }
    }, [specificationsData.length]);


    useEffect(() => {
        if (isSuccess) {
            onHide(false)
        }
    }, [isSuccess]);

    useEffect(() => {
        if (formik.values.price === '0') {
            formik.setFieldValue("discount", '0');
        }
    }, [formik.values.price])

    useEffect(() => {
        if (formik.values.isFree) {
            formik.setFieldValue("expireYear", "");
            formik.setFieldValue("expireMonth", "");
            formik.setFieldValue("expireDay", "");
            formik.setFieldValue("price", "0");
            formik.setFieldValue("discount", "");
        }
    }, [formik.values.isFree])

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Edit the practice test</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit} ref={formRef}>
                <Modal.Body>
                    {practiceTest.isLoading
                        ? <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34A9FC"}}/>
                        </div>
                        : <div className="col-12 pl-4 pr-4">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="title"
                                            name="title"
                                            label="Title"
                                            placeholder="Title"
                                            className="w-100"
                                            error={getInputClasses("title")}
                                            {...formik.getFieldProps("title")}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="slug"
                                            name="slug"
                                            label="Slug"
                                            placeholder="Slug"
                                            className="w-100"
                                            error={getInputClasses("slug")}
                                            {...formik.getFieldProps("slug")}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="shortDescription"
                                            name="shortDescription"
                                            label="Short description"
                                            placeholder="Short description"
                                            className="w-100"
                                            error={getInputClasses("shortDescription")}
                                            {...formik.getFieldProps("shortDescription")}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <InputLabel className="w-100" error={getInputClasses("description")}
                                                    title="Description">Description</InputLabel>
                                        <div className="w-100 mb-2">
                                            <ReactQuill
                                                id="description"
                                                name="description"
                                                value={formik.values.description}
                                                onChange={(e) => formik.setFieldValue("description", e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <FormControl className="w-100">
                                            <InputLabel error={getInputClasses("categoryId")}
                                                        htmlFor="categoryId">Category</InputLabel>
                                            <Select
                                                inputProps={{
                                                    name: "categoryId",
                                                    id: "categoryId",
                                                }}
                                                {...formik.getFieldProps("categoryId")}
                                                error={getInputClasses("categoryId")}
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                {
                                                    categories.data?.data?.length && categories.data.data.map((item, index) => (
                                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <ReactSelect single={multiSelectValue} suggestions={specificationsData}
                                                     label="Course specification"
                                                     handleChangeSingle={changeSpecifications}/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <FormControlLabel
                                        label="Practice test is free"
                                        control={<Checkbox checked={formik.values.isFree}/>}
                                        {...formik.getFieldProps("isFree")}
                                    />
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="price"
                                            name="price"
                                            label="Price"
                                            placeholder="Price"
                                            className="w-100"
                                            error={getInputClasses("price")}
                                            {...formik.getFieldProps("price")}
                                            disabled={formik.values.isFree}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="discount"
                                            name="discount"
                                            label="Discount"
                                            placeholder="Discount"
                                            className="w-100"
                                            error={getInputClasses("discount")}
                                            {...formik.getFieldProps("discount")}
                                            disabled={formik.values.isFree}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <InputLabel id="categoryId">Expiration date</InputLabel>

                                </div>
                                <div className="col-12 col-md-12 col-lg-4">
                                    <div className="w-100 pt-2 pb-3">
                                        <TextField
                                            type="number"
                                            id="expireYear"
                                            name="expireYear"
                                            label="Year"
                                            placeholder="Year"
                                            className="w-100"
                                            error={getInputClasses("expireYear")}
                                            value={formik.values.expireYear}
                                            onChange={handleChangeDate}
                                            disabled={formik.values.isFree}
                                        />
                                    </div>
                                    {formik.touched.expireYear && formik.errors.expireYear ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.expireYear}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-12 col-md-12 col-lg-4">
                                    <div className="w-100 pt-2 pb-3">
                                        <TextField
                                            type="number"
                                            id="expireMonth"
                                            name="expireMonth"
                                            label="Month"
                                            placeholder="Month"
                                            className="w-100"
                                            error={getInputClasses("expireMonth")}
                                            value={formik.values.expireMonth}
                                            onChange={handleChangeDate}
                                            disabled={formik.values.isFree}
                                        />
                                    </div>
                                    {formik.touched.expireMonth && formik.errors.expireMonth ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.expireMonth}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-12 col-md-12 col-lg-4">
                                    <div className="w-100 pt-2 pb-3">
                                        <TextField
                                            type="number"
                                            id="expireDay"
                                            name="expireDay"
                                            label="Day"
                                            placeholder="Day"
                                            className="w-100"
                                            error={getInputClasses("expireDay")}
                                            value={formik.values.expireDay}
                                            onChange={handleChangeDate}
                                            disabled={formik.values.isFree}
                                        />
                                    </div>
                                    {formik.touched.expireDay && formik.errors.expireDay ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.expireDay}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <FormControlLabel control={<Checkbox name="applyExpireToAll"
                                                                         value={formik.values.applyExpireToAll}
                                                                         onChange={(e) => formik.setFieldValue("applyExpireToAll", e.target.checked)}/>}
                                                      label="Check if you want to apply expiration date to all users."/>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="w-100 pt-2 pb-3">
                                        <InputLabel error={getInputClasses("coverFile")} id="coverImage">Cover
                                            image</InputLabel>
                                        <label htmlFor="contained-button-file">
                                            <Input
                                                accept="image/*"
                                                id="contained-button-file"
                                                type="file"
                                                name="coverFile"
                                                label="File {Path}"
                                                placeholder="File Path"
                                                className="w-100"
                                                onChange={handleCoverImage}
                                                value={file}
                                                hidden
                                            />
                                            <Button2 variant="contained" component="span">
                                                <SVG
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Devices/Camera.svg"
                                                    )}
                                                    className="mr-2"
                                                />
                                                Upload image
                                            </Button2>
                                        </label>
                                        <Preview imageSrc={imageSrc}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {error ? error?.data?.errors?.map((error, index) => (
                        <div key={index} className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {error.errorMessage}
                            </div>
                        </div>
                    )) : ""}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        onHide(false);
                    }} variant="light">Close</Button>
                    <Button disabled={isLoading} type="submit" variant="info">Update</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}