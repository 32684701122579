import {createApi} from "@reduxjs/toolkit/query/react";
import {APIBaseQuery} from "../AxiosBase";
import {v4 as uuid} from "uuid";


export const practiceTestsApi = createApi({
    reducerPath: "practiceTestsApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    tagTypes: ["PracticeTests", "Content", "SubContent", "Questions", "ContentScore"],
    endpoints: (builder) => ({
        getPracticeTests: builder.query({
            query(filter = {}) {
                const {skip, take} = filter
                return {
                    url: `items?itemTypes=2&skip=${skip}&take=${take}&sortField=updated&orderBy=false`
                }
            },
            providesTags: ["PracticeTests"]
        }),
        getPracticeTestById: builder.query({
            query(id) {
                return {
                    url: `items/${id}`
                }
            }
        }),
        createPracticeTest: builder.mutation({
            query(body) {
                return {
                    url: `items`,
                    method: "POST",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["PracticeTests"]
        }),
        updatePracticeTestStatus: builder.mutation({
            query(filter = {}) {
                const {id, status, url} = filter
                return {
                    url: `${url}/${id}/status`,
                    method: "PUT",
                    data: {status}
                }
            }
        }),
        updatePracticeTest: builder.mutation({
            query(body) {
                return {
                    url: `items`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["PracticeTests"]
        }),
        removePracticeTest: builder.mutation({
            query(id) {
                return {
                    url: `items/${id}`,
                    method: "DELETE",
                    headers: {
                        "x-requestid": uuid()
                    },
                }
            },
            invalidatesTags: ["PracticeTests"]
        }),
        getCategories: builder.query({
            query() {
                return {
                    url: `categories`
                }
            }

        }),
        getPracticeTestContent: builder.query({
            query(filter = {}) {
                const {skip, take, practiceTestId} = filter
                return {
                    url: `item-contents?itemId=${practiceTestId}&skip=${skip}&take=${take}&sortField=order&orderBy=true`
                }
            },
            providesTags: ["Content"]
        }),
        getPracticeTestContentById: builder.query({
            query(id) {
                return {
                    url: `item-contents/${id}`
                }
            }
        }),
        createPracticeTestContent: builder.mutation({
            query(body) {
                return {
                    url: `item-contents`,
                    method: "POST",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Content"]
        }),
        updatePracticeTestContent: builder.mutation({
            query(body) {
                return {
                    url: `item-contents`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Content"]
        }),
        updatePracticeTestContentStatus: builder.mutation({
            query(body) {
                const {id, status} = body;
                return {
                    url: `item-contents/${id}/status`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: {status}
                }
            },
            invalidatesTags: ["Content"]
        }),
        removePracticeTestContent: builder.mutation({
            query(id) {
                return {
                    url: `item-contents/${id}`,
                    method: "DELETE",
                    headers: {
                        "x-requestid": uuid()
                    }
                }
            },
            invalidatesTags: ["Content"]
        }),
        getPracticeTestSubContent: builder.query({
            query(filter = {}) {
                const {contentId, skip, take} = filter
                return {
                    url: `practice-test-subcontents?contentId=${contentId}&skip=${skip}&take=${take}`
                }
            },
            providesTags: ["SubContent"]
        }),
        getPracticeTestSubContentById: builder.query({
            query(id) {
                return {
                    url: `practice-test-subcontents/${id}`
                }
            }
        }),
        createPracticeTestSubContent: builder.mutation({
            query(body) {
                return {
                    url: `practice-test-subcontents`,
                    method: "POST",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["SubContent"]
        }),
        updatePracticeTestSubContent: builder.mutation({
            query(body) {
                return {
                    url: `practice-test-subcontents`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["SubContent"]
        }),
        updatePracticeTestSubContentStatus: builder.mutation({
            query(body) {
                const {id, status} = body;
                return {
                    url: `practice-test-subcontents/${id}/status`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: {status}
                }
            },
            invalidatesTags: ["SubContent"]
        }),
        removePracticeTestSubContent: builder.mutation({
            query(id) {
                return {
                    url: `practice-test-subcontents/${id}`,
                    method: "DELETE",
                    headers: {
                        "x-requestid": uuid()
                    },
                }
            },
            invalidatesTags: ["SubContent"]
        }),
        getPracticeTestQuestions: builder.query({
            query(filter) {
                const {skip, take, subContentId} = filter
                return {
                    url: `practice-test-questions?skip=${skip}&take=${take}&practiceTestSubContentId=${subContentId}&sortField=order&orderBy=true`
                }
            },
            providesTags: ["Questions"]
        }),
        getPracticeTestQuestionById: builder.query({
            query(id) {
                return {
                    url: `practice-test-questions/${id}`
                }
            }
        }),
        createPracticeTestQuestion: builder.mutation({
            query(body) {
                return {
                    url: `practice-test-questions`,
                    method: "POST",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Questions"]
        }),
        updatePracticeTestQuestion: builder.mutation({
            query(body) {
                return {
                    url: `practice-test-questions`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Questions"]
        }),
        updatePracticeTestQuestionStatus: builder.mutation({
            query(body) {
                const {id, status} = body
                return {
                    url: `practice-test-questions/${id}/status`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: {status}
                }
            },
            invalidatesTags: ["Questions"]
        }),
        removePracticeTestQuestion: builder.mutation({
            query(id) {
                return {
                    url: `practice-test-questions/${id}`,
                    method: "DELETE",
                    headers: {
                        "x-requestid": uuid()
                    },
                }
            },
            invalidatesTags: ["Questions"]
        }),
        getContentScore: builder.query({
            query(id) {
                return {
                    url: `practice-test-content-scores?contentId=${id}&orderBy=false&sortField=correctAnswerCount`
                }
            },
            providesTags: ["ContentScore"]
        }),
        createContentScore: builder.mutation({
            query(body) {
                return {
                    url: `practice-test-content-scores`,
                    method: "POST",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["ContentScore"]
        }),
        updateContentScore: builder.mutation({
            query(body) {
                return {
                    url: `practice-test-content-scores`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["ContentScore"]
        }),
        removeContentScore: builder.mutation({
            query(id) {
                return {
                    url: `practice-test-content-scores/${id}`,
                    method: "DELETE",
                    headers: {
                        "x-requestid": uuid()
                    },
                }
            },
            invalidatesTags: ["ContentScore"]
        })

    })
})

export const {
    useGetPracticeTestsQuery,
    useGetPracticeTestByIdQuery,
    useCreatePracticeTestMutation,
    useUpdatePracticeTestStatusMutation,
    useUpdatePracticeTestMutation,
    useRemovePracticeTestMutation,
    useGetCategoriesQuery,
    useGetPracticeTestContentQuery,
    useGetPracticeTestContentByIdQuery,
    useCreatePracticeTestContentMutation,
    useUpdatePracticeTestContentMutation,
    useUpdatePracticeTestContentStatusMutation,
    useRemovePracticeTestContentMutation,
    useGetPracticeTestSubContentQuery,
    useGetPracticeTestSubContentByIdQuery,
    useCreatePracticeTestSubContentMutation,
    useUpdatePracticeTestSubContentMutation,
    useUpdatePracticeTestSubContentStatusMutation,
    useRemovePracticeTestSubContentMutation,
    useGetPracticeTestQuestionsQuery,
    useGetPracticeTestQuestionByIdQuery,
    useCreatePracticeTestQuestionMutation,
    useUpdatePracticeTestQuestionStatusMutation,
    useUpdatePracticeTestQuestionMutation,
    useRemovePracticeTestQuestionMutation,
    useGetContentScoreQuery,
    useCreateContentScoreMutation,
    useUpdateContentScoreMutation,
    useRemoveContentScoreMutation,
} = practiceTestsApi