export const items = {
    1: "Courses",
    2: "Practice tests",
    3: "Test bundles",
    4: "Books"
}

export const COURSE_ID = 1;
export const PRACTICE_TEST_ID = 2;
export const TEST_BUNDLE_ID = 3;
export const BOOK_ID = 4;