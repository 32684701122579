import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    coursesState: [],
}

export const coursesSlice = createSlice({
    initialState,
    name: "coursesSlice",

    reducers: {
        setCourses: (state, action) => {
            state.coursesState = action.payload
        },
    }
});

export default coursesSlice.reducer;
export const {setCourses} = coursesSlice.actions