import React, { useState, useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, CircularProgress } from "@material-ui/core";
import ViewIcon from "../../../../components/icons/ViewIcon";
import EditIcon from "../../../../components/icons/EditIcon";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import Create from "./modals/Create";
import Edit from "./modals/Edit";
import Remove from "./modals/Remove";
import Filter from "./modals/Filter";
import { useGetGroupsQuery } from "../../../../../redux/api/Groups/groupsApi";
import Pagination from "@material-ui/lab/Pagination";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import RenderIf from "app/components/RenderIf";

const useStyles = makeStyles({
  wrapper: {
    height: " calc(100% - 65px)",
    paddingTop: "12px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  divider: {
    height: "1px",
    width: "100%",
  },
  label: {
    width: "100px",
  },
  card: {
    padding: "24px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 18px rgba(62, 74, 97, 0.12)",
    borderRadius: "12px",
    display: "flex",
    // gap: "16px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardTitle: {
    fontWeight: "500",
    fontSize: "22px",
    lineHeight: "33px",
    color: "#050A46",
    margin: "0",
  },
  cardTextContainer: {
    display: "flex",
    gap: "8px",
  },
  cardText: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#424460",
  },
  cardButtonsContainer: {
    display: "flex",
    // gap: "32px",
  },
  cardButton: {
    background: "unset",
    border: "unset",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#424460",
    "& svg": {
      marginRight: "8px",
    },
  },
  studentCount: {
    fontSize: "16px",
    color: "#34a9fc",
    marginRight: "8px",
  },
  notPaidCount: {
    fontSize: "16px",
    color: "red",
  },
});

export default function Groups({
  id,
  setId,
  renderMethods,
  setRender,
  groupName,
  setGroupName,
}) {
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [contentPage, setContentPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [showFilter, setShowFilter] = useState(false);
  const [teacher, setTeacher] = useState({ value: "" });

  const userRoles = useSelector(
    (state) => state.user?.user?.userRoles,
    shallowEqual
  );

  const isAdmin = useMemo(() => userRoles.some((role) => role.roleId === 1), [
    userRoles,
  ]);

  const classes = useStyles();

  const { data, isLoading } = useGetGroupsQuery(teacher?.value);

  const handleChangePage = (e, page) => {
    setContentPage(page);
    const tempSkip = (page - 1) * take;
    setSkip(tempSkip);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const count = Math.ceil(data?.count / take);

  return (
    <>
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <h1 className="display-4 mb-0">
          Groups
          <div className="d-flex">
            <span
              className={classes.studentCount}
            >{`Student count: ${data?.studentCount ?? ""}`}</span>
            <span
              className={classes.notPaidCount}
            >{`Not paid: ${data?.notPaidCount ?? ""}`}</span>
          </div>
        </h1>
        <div className="ml-auto">
          <button
            onClick={() => setShowCreate(true)}
            className="button-primary"
          >
            Add a new group
          </button>
          <RenderIf condition={isAdmin}>
            <FormControlLabel
              control={
                <Switch
                  checked={showFilter}
                  onChange={() => setShowFilter((prevState) => !prevState)}
                  color="secondary"
                />
              }
              label={showFilter ? "Hide filter" : "Show filter"}
              className="ml-auto mb-0"
            />
          </RenderIf>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={`col-12 ${classes.wrapper}`}>
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <CircularProgress style={{ color: "#34a9fc" }} />
          </div>
        ) : (
          data?.lessonGroups?.data?.map((group) => (
            <div
              key={group.id}
              className={classes.card}
              style={{ backgroundColor: group.color ?? "#fff" }}
            >
              <div className="d-flex align-items-center gap-4">
                <h2 className={classes.cardTitle}>{group.name}</h2>
                <RenderIf condition={group?.createdByName}>
                  <div className={classes.cardTextContainer}>
                    <span className={classes.cardText}>
                      {group?.createdByName}
                    </span>
                  </div>
                </RenderIf>
              </div>

              <div className="d-flex align-items-center gap-4">
                <div className={`${classes.cardTextContainer} gap-4`}>
                  <span className={classes.cardText}>Enrolled students:</span>
                  <span className={classes.cardText}>{group.studentCount}</span>
                </div>
                <div className={`${classes.cardButtonsContainer} gap-4`}>
                  <button
                    onClick={() => {
                      setId(group.id);
                      setGroupName(group.name);
                      setRender(renderMethods.group);
                    }}
                    className={classes.cardButton}
                  >
                    <ViewIcon />
                    Show group
                  </button>
                  <button
                    onClick={() => {
                      setId(group.id);
                      setGroupName(group.name);
                      setShowEdit(true);
                    }}
                    className={classes.cardButton}
                  >
                    <EditIcon />
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      setId(group.id);
                      setGroupName(group.name);
                      setShowRemove(true);
                    }}
                    className={classes.cardButton}
                  >
                    <DeleteIcon />
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
        {data?.count > take && (
          <div className="py-2">
            <Pagination
              onChange={(e, page) => handleChangePage(e, page)}
              count={count}
              page={contentPage}
            />
          </div>
        )}
      </div>

      {showCreate && <Create show={showCreate} onHide={setShowCreate} />}
      {showEdit && (
        <Edit
          show={showEdit}
          onHide={setShowEdit}
          id={id}
          groupName={groupName}
        />
      )}
      {showRemove && (
        <Remove
          show={showRemove}
          onHide={setShowRemove}
          id={id}
          groupName={groupName}
        />
      )}
      {showFilter && (
        <Filter
          show={showFilter}
          onHide={setShowFilter}
          teacher={teacher}
          setTeacher={setTeacher}
        />
      )}
    </>
  );
}
