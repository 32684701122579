import React, {useEffect, useRef, useState} from 'react';
import {Modal, Button} from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useCreateContentMutation} from "../../../../redux/api/Courses/coursesApi";
import Button2 from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import LinearProgress from "../components/LinearProgress";
import axios from "axios";
import {v4 as uuid} from "uuid";
import {useSelector} from "react-redux";

export default function CreateVideo({show, onHide, totalCount, itemId, refetch, order, contentId}) {
    const formRef = useRef();
    const {token} = useSelector(({user}) => ({token: user.token}));
    const [file, setFile] = useState("");
    const [videoSrc, setVideoSrc] = useState("");
    const [fileName, setFileName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [percent, setPercent] = useState(0)

    const handleVideo = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setFile(file);
        setFileName(file.name);
        reader.onloadend = () => {
            setVideoSrc(reader.result)
        };

        reader.readAsDataURL(file);
    };


    const initialValues = {
        title: "",
        description: "",
        youtubeUrl: ""
    };
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Please enter a title."),
        description: Yup.string().required("Please enter a description."),
    });

    const onSubmitForm = (values) => {
        const {title, description, youtubeUrl} = values;
        const asset = new FormData();
        asset.append("contentId", contentId);
        asset.append("order", order);
        asset.append("title", title);
        asset.append("description", description);
        youtubeUrl && asset.append("youtubeUrl", youtubeUrl);
        !youtubeUrl && asset.append("urlFile", file);
        setIsLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}videos`, asset, {
            headers: {
                "x-requestid": uuid(),
                "Authorization": `Bearer ${token}`
            },
            onUploadProgress: ({loaded, total}) => {
                const tempPercent = Math.round((loaded / total) * 100)
                if (percent < tempPercent) {
                    setPercent(tempPercent)
                }
            }
        })
            .then(() => {
                setIsLoading(false);
                setPercent(0);
                refetch();
                onHide(false);
            })
            .catch(() => {
                setIsLoading(false);
                setPercent(0)
            })

    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            onSubmitForm(values)
        },
    });


    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    return (
        <Modal size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title id="create">Upload a video</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit} ref={formRef}>
                <Modal.Body>

                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="title"
                                        name="title"
                                        label="Title"
                                        placeholder="Title"
                                        className="w-100"
                                        error={getInputClasses("title")}
                                        {...formik.getFieldProps("title")}
                                    />
                                    {formik.touched.title && formik.errors.title ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.title}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="description"
                                        name="description"
                                        label="Description"
                                        placeholder="Description"
                                        className="w-100"
                                        error={getInputClasses("description")}
                                        {...formik.getFieldProps("description")}
                                    />
                                    {formik.touched.description && formik.errors.description ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{formik.errors.description}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="youtubeUrl"
                                        name="youtubeUrl"
                                        label="Youtube url"
                                        placeholder="Youtube url"
                                        className="w-100"
                                        error={getInputClasses("youtubeUrl")}
                                        {...formik.getFieldProps("youtubeUrl")}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-column align-items-center flex-wrap">
                                    <label htmlFor="contained-button-file">
                                        <Input
                                            accept="video/*"
                                            id="contained-button-file"
                                            type="file"
                                            name="UrlFile"
                                            label="File {Path}"
                                            placeholder="File Path"
                                            className="w-100"
                                            onChange={handleVideo}
                                            hidden
                                        />
                                        <Button2 disabled={isLoading || formik.values.youtubeUrl} variant="contained" component="span">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Devices/Video-camera.svg"
                                                )}
                                                className="mr-2"
                                            />
                                            {file ? "File has been chosen" : "Choose the video"}
                                        </Button2>
                                    </label>
                                    {fileName}
                                    {isLoading && <LinearProgress progress={percent}/>}
                                    {isLoading && <span>Please wait until the video loads. This may take a while</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                            formik.resetForm();
                        }}
                    >
                        Close
                    </Button>
                    <Button disabled={isLoading} type="submit" variant="info" className={`px-9`}>
                        Upload
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}