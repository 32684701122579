import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    ourStudents: []
}

export const ourStudentsSlice = createSlice({
    initialState,
    name: "ourStudentsSlice",
    reducers: {
        setOurStudents: (state, action) => {
            state.ourStudents = action.payload
        }
    }
});

export default ourStudentsSlice.reducer;
export const {setOurStudents} = ourStudentsSlice.actions