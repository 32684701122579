import React, {useState,useEffect} from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {Divider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import ViewIcon from "../../../../components/icons/ViewIcon";
import EditIcon from "../../../../components/icons/EditIcon";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import {CircularProgress} from "@material-ui/core";
import Create from "../../modals/CreateVideo";
import Edit from "../../modals/EditVideo"
import View from "../../modals/View";
import Remove from "../../modals/removeVideo";
import {useGetVideosQuery} from "../../../../../redux/api/Courses/coursesApi";
import Pagination from "@material-ui/lab/Pagination";
import {useChangeVideoStatusMutation} from "../../../../../redux/api/Courses/coursesApi";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Status from "../Status"

const useStyles = makeStyles({
    wrapper: {
        height: " calc(100% - 65px)",
        paddingTop: "12px"
    },
    divider: {
        height: "1px",
        width: "100%"
    },
    card: {
        background: "#FFFFFF",
        boxShadow: "0px 2px 18px rgba(62, 74, 97, 0.12)",
        borderRadius: "12px",
        padding: "12px",
        marginBottom: "8px",
        display: "flex",
        flexDirection: "column",
    },
    cardTitle: {
        marginBottom: "24px",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "27px",
        color: "#050a46"
    },
    cardOperations: {
        display: "flex",
        gap: "32px"
    },
    cardButton: {
        background: "unset",
        border: "unset"
    },
    label: {
        width: "100px"
    }
});

export default function Videos({
                                   setRender,
                                   renderMethods,
                                   contentName,
                                   contentId,
                                   setVideoTitle,
                                   videoTitle,
                                   videoId,
                                   setVideoId,
                                   setVideoPage,
                                   videoPage
                               }) {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [showCreate, setShowCreate] = useState(false);
    const [showView, setShowView] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [url, setUrl] = useState("");

    const {data, isLoading, refetch} = useGetVideosQuery({
        skip, take, contentId
    });

    const [changeVideoStatus, {isSuccess}] = useChangeVideoStatusMutation();

    const classes = useStyles();

    const handleChangePage = (e, page) => {
        setVideoPage(page)
        const tempSkip = (page - 1) * take
        setSkip(tempSkip);
        window.scrollTo({top: 0, behavior: "smooth"})
    };

    const changeStatus = (status, id) => {
        changeVideoStatus({
            id: id,
            status: !status
        })
    };

    useEffect(() => {
        if (isSuccess) {
            refetch()
        }
    }, [isSuccess])

    return (
        <>
            <div className="col-12 d-flex align-items-center py-4 height-65">
                <button onClick={() => {
                    setRender(renderMethods.content)
                }} className="button-primary mr-2">
                    <ArrowBackIcon/>
                </button>
                <h1 className="display-6 mb-0">Videos of "{contentName}"</h1>
                <div className="ml-auto">
                    <button className="button-primary" onClick={() => setShowCreate(true)}>Add a new video
                    </button>
                </div>
            </div>
            <Divider className={classes.divider}/>
            <div className={`col-12 ${classes.wrapper}`}>
                {isLoading
                    ?
                    <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34A9FC"}}/></div>
                    : data?.data.map((item, index) => (
                        <div className={classes.card} key={index}>
                            <span className={classes.cardTitle}>{item.order}. {item.title}</span>
                            <div className={classes.cardOperations}>
                                <Status status={item.status} id={item.id} url={"videos"} />
                                <Tooltip title="Show the questions">
                                    <button onClick={() => {
                                        setVideoTitle(item.title);
                                        setRender(renderMethods.questions);
                                        setVideoId(item.id);
                                    }}
                                            className={classes.cardButton}>
                                        <ViewIcon/> Show the questions
                                    </button>
                                </Tooltip>
                                <Tooltip title="Show the video">
                                    <button onClick={() => {
                                        setShowView(true);
                                        setUrl(item?.youtubeUrl ?? item.url);
                                        setVideoTitle(item.title);
                                    }}
                                            className={classes.cardButton}>
                                        <SlideshowIcon/> Show the video
                                    </button>
                                </Tooltip>
                                <Tooltip title="Edit content">
                                    <button onClick={() => {
                                        setShowEdit(true);
                                        setVideoTitle(item.title)
                                        setVideoId(item.id)
                                    }}
                                            className={classes.cardButton}>
                                        <EditIcon/> Edit
                                    </button>
                                </Tooltip>
                                <Tooltip title="Delete content">
                                    <button onClick={() => {
                                        setVideoTitle(item.title);
                                        setVideoId(item.id);
                                        setShowRemove(true);
                                    }}
                                            className={classes.cardButton}>
                                        <DeleteIcon/> Delete
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    ))
                }
                {data?.count > take && (
                  <div className="py-2">
                      <Pagination onChange={(e, page) => handleChangePage(e, page)}
                                  count={Math.ceil(data?.count / take)} page={videoPage}/>
                  </div>
                )}
                {showCreate && (
                    <Create show={showCreate} onHide={setShowCreate} order={data?.count + 1} contentId={contentId}
                            refetch={refetch}/>
                )}
                {showView && (
                    <View show={showView} onHide={setShowView} url={url} videoTitle={videoTitle}/>
                )}
                {showEdit && (
                    <Edit show={showEdit} onHide={setShowEdit} refetch={refetch} videoId={videoId}
                          videoTitle={videoTitle} totalCount={data?.count}/>
                )}
                {showRemove && (
                    <Remove show={showRemove} onHide={setShowRemove} refetch={refetch} videoId={videoId}
                            videoTitle={videoTitle}/>
                )}
            </div>
        </>
    )
}