import {createApi} from "@reduxjs/toolkit/query/react";
import {APIBaseQuery} from "../AxiosBase";
import {v4 as uuid} from "uuid";

export const categoriesApi = createApi({
    reducerPath: "categoriesApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    tagTypes: ["Categories"],
    endpoints: (build) => ({
        getCategories: build.query({
            query(params) {
                return {
                    url: `categories`,
                    params
                }
            },
                providesTags: ["Categories"]
        }),
        getCategoryById: build.query({
            query(id) {
                return {
                    url: `categories/${id}`
                }
            }
        }),
        createCategory: build.mutation({
            query(body) {
                return {
                    url: 'categories',
                    method: "POST",
                    data: body,
                    headers: {
                        "x-requestid": uuid()
                    }
                }
            },
            invalidatesTags: ["Categories"]
        }),
        updateCategory: build.mutation({
            query(body) {
                return {
                    url: 'categories',
                    method: "PUT",
                    data: body,
                    headers: {
                        "x-requestid": uuid()
                    }
                }
            },
            invalidatesTags: ["Categories"]
        }),
        removeCategory: build.mutation({
            query(id) {
                return {
                    url: `categories/${id}`,
                    method: "DELETE",
                    headers: {
                        "x-requestid": uuid()
                    }
                }
            },
            invalidatesTags: ["Categories"]
        })
    })
})

export const {
    useGetCategoriesQuery,
    useGetCategoryByIdQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useRemoveCategoryMutation
} = categoriesApi