import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";


const useStyles = makeStyles((theme) => ({
    container: {
        height: '400px'
    },
}));

export default function View(props) {
    const classes = useStyles();
    const { onHide, show, videoTitle, url} = props;

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="view" centered size="lg">

                <Modal.Header closeButton>
                    <Modal.Title id="view">{videoTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={classes.container}>
                        <ReactPlayer controls={true} width="100%" url={url} height="100%" />
                    </div>
                </Modal.Body>
        </Modal>
    );

}
