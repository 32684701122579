import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {makeStyles} from "@material-ui/core/styles";
import {useRemoveContentMutation} from "../../../../../../redux/api/TestBundles/testBundlesApi";

const useStyles = makeStyles((theme) => ({
    textarea: {
        width: "100% !important",
        height: "100px !important",
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    warning: {
        color: "red",
        fontSize: "16px",
        marginTop: "10px"
    },
    title: {
        fontSize: "17px",
    }
}));

export default function Remove({show, onHide, contentId}) {
    const classes = useStyles();

    const [removeContent, {isLoading, error, isSuccess}] = useRemoveContentMutation();


    useEffect(() => {
        if (isSuccess) {
            onHide();
        }

    }, [isSuccess]);

    const onDeleteUser = (e) => {
        e.preventDefault();
        removeContent(contentId)
    };


    return (
        <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
            <form onSubmit={onDeleteUser}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">Are you sure to delete  ?</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" type="submit">Delete</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );

}
